/* Built In Imports */
/* External Imports */
// import { Image } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/* Services */

/**
 * Renders the Testimonials component.
 *
 * @param testimonialsObj.testimonialsObj
 * @param {object} testimonialsObj component.
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @param testimonialsObj.myRef
 * @param testimonialsObj.region
 * @param testimonialsObj.lang
 * @param testimonialsObj.width
 * @param testimonialsObj.height
 * @param testimonialsObj.background
 * @returns {ReactElement} Testimonials component.
 */
const IshangaTestimonials = ({
  testimonialsObj,
  myRef,
  region,
  lang,
  width,
  height,
  background,
}) => {
  const NextArrow = props => {
    // consoleLog('testimonial', testimonialsObj);
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="25%"
        zIndex="2"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        // bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="-10px"
        onClick={onClick}
      >
        <svg
          width="26"
          height="45"
          viewBox="0 0 26 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 42.417L22 22.417L2 2.41699"
            stroke="#CF4520"
            stroke-width="5"
          />
        </svg>
      </Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="25%"
        zIndex="2"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        // bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left="-10px"
        onClick={onClick}
      >
        <svg
          width="26"
          height="45"
          viewBox="0 0 26 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 2.41699L4 22.417L24 42.417"
            stroke="#CF4520"
            stroke-width="5"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          className="dots"
          bgColor="#D3D3D3"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <>
      <Box
        // ref={myRef}
        float="left"
        w="100%"
        mt="30px"
        // p={{
        //   base: '65px 0px 50px 0px',
        //   sm: '120px 0px 50px 0px',
        //   md: '80px 20px',
        //   lg: '80px 65px',
        // }}
        // bgColor="#ffffff"
        // clipPath="polygon(100% 100%, 0% 100%, 0% 10%, 100% 0%)"
      >
        {testimonialsObj?.title[0] && (
          <SectionTitle titleObj={testimonialsObj?.title[0]} />
        )}
        <Box
          maxW="100%"
          m="0 auto"
          w="100%"
          overflow="hidden"
          p="0px 15px 30px 15px"
          style={{ backgroundColor: background }}
        >
          <Slider {...settings} m="0">
            {testimonialsObj?.testimonials?.map((data, i) => {
              return (
                <Box
                  overflow="hidden"
                  p="0 5%"
                  key={nanoid()}
                  textAlign="center"
                  fontFamily="FedraSansStd-book"
                >
                  {data?.photo?.url && (
                    <LazyLoadImageComponent
                      src={data?.photo?.url}
                      alt={data?.photo?.alt}
                      title={data?.photo?.title}
                      w={width ? width : '196px'}
                      h={height ? height : '196px'}
                      borderRadius="50%"
                      verticalAlign="middle"
                      pb="1vw"
                    />
                  )}

                  <ContentArea
                    tAlign="center"
                    font={{ base: '16px', md: '18px' }}
                    mainContent={data?.testimonialText}
                    region={region}
                    lang={lang}
                  />
                  {data?.userName && (
                    <Box marginTop="15px" textAlign="center" fontWeight="600">
                      - {data?.userName}
                    </Box>
                  )}
                  {/* {StructuredTextContent(data?.testimonialText)} */}
                </Box>
              );
            })}
          </Slider>
          <style>
            {`
              .slick-active .dots{
                background-color:#808080;
              }
              `}
          </style>
        </Box>
      </Box>
    </>
  );
};

export default IshangaTestimonials;
