/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports*/
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { useRouter } from 'next/router';

/* Services */

/**
 * Renders the Card Info With Multiple Image Detail
 * @param {Object} data - data for section
 * @param {string} region - Region for section
 * @param {string} lang - Language for section
 * @param {Object} index - index for cards
 * @returns {ReactElement} - CardInfoWithMultipleImageDetail card component
 */
const CardInfoWithMultipleImageDetail = ({
  sectionContent,
  section,
  data,
  region,
  lang,
  index,
}) => {
  // const outreactZigZagSection has been created just to add specific styling for the in/en/outreach page
  const outreactZigZagSection = section.sectionId === 'zigzag-section';
  // console.log({outreactZigZagSection})

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,

    customPaging: function () {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          bgColor="#fff"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  const isEvenIndex = index % 2 == 0;
  const flexDirection = isEvenIndex ? 'row-reverse' : 'row';
  const textAlign = isEvenIndex ? 'left' : 'right';
  const float = isEvenIndex ? 'right' : 'left';
  const router = useRouter();
  const volunteerUrl = router.asPath.includes('/volunteer');

  return (
    <Box
      display="flex"
      justifyContent="center"
      key={nanoid()}
      mb={{ base: 12, md: '4.5em' }}
      flexDirection={{ base: 'column-reverse', md: flexDirection }}
    >
      <Box
        className="slider description"
        w={{ base: '100%', md: '50%' }}
        color={'black'}
        textAlign="right"
        px={{ base: '8px', sm: '16px' }}
        height="auto"
        margin={volunteerUrl ? 'auto' : '0'}
      >
        <Box
          as="h3"
          fontFamily={'FedraSansStd-A-medium'}
          fontSize={{ base: '26px', md: '36px' }}
          fontWeight="350"
          // textAlign={textAlign}
          textAlign={{ base: 'left', md: textAlign }}
          color="#28231e"
          lineHeight={{ base: '1.61', md: '52px' }}
          mb={{ base: '0', md: '5%' }}
          mt={{ base: volunteerUrl ? '30px' : '0', md: '0' }}
        >
          {' '}
          {data.title}
        </Box>
        <Box
          as="h4"
          fontSize={'16px'}
          pb={2}
          fontFamily={'FedraSansStd-book'}
          // textAlign="left"
          textAlign={{ base: 'left', md: textAlign }}
          className="structuredText"
        >
          <StructuredTextParser
            str={render(data.description)}
            region={region}
            lang={lang}
            // textAlign={textAlign}
            textAlign={{ base: 'left', md: textAlign }}
          />

          {data.buttonLink && (
            <Box>
              <NextLink
                onClick={e => refreshPage(e?.target?.href, e)}
                href={refineUrlDomain(data.buttonLink, region, lang)}
                passHref
                legacyBehavior
              >
                <Link
                  bgColor="transparent"
                  color="#cf4520"
                  mr={{ md: '20px' }}
                  padding="5px 30px"
                  border="1px solid #cf4520"
                  borderRadius="5px"
                  display="inline-block"
                  my={19}
                  fontFamily="FedraSansStd-medium"
                  fontSize="13px"
                  _hover={{
                    color: '#fff',
                    bgColor: '#cf4520',
                    textDecoration: 'none',
                  }}
                  _focus={{
                    color: '#fff',
                    bgColor: '#cf4520',
                    textDecoration: 'none',
                  }}
                  _active={{
                    color: '#fff',
                    bgColor: '#cf4520',
                    textDecoration: 'none',
                  }}
                  target={
                    data?.buttonLink.indexOf('http') > -1 &&
                    data?.buttonLink.indexOf(config.cdnPath) < 0
                      ? '_blank'
                      : ''
                  }
                >
                  {data.buttonText}
                </Link>
              </NextLink>
            </Box>
          )}
        </Box>
        <style jsx global>
          {`
            .structuredText a {
              color: #cf4824;
            }
            .structuredText a:hover {
              border-bottom: 1px solid #cf4824;
            }
          `}
        </style>
      </Box>

      <Box
        w={{ base: '100%', md: '50%' }}
        px={{ base: '8px', sm: '16px' }}
        textAlign={{
          base: 'center',
          sm: volunteerUrl ? 'center' : 'left',
        }}
      >
        {data.thumbnails.length > 1 ? (
          <Slider
            key={index}
            float="left"
            w={{ base: '100%', sm: '100%', md: '100%' }}
            mr="70px"
            ml={{ base: '0', sm: '0', md: '30px' }}
            {...settings}
          >
            {data.thumbnails.map(img => (
              <Box key={nanoid()} {...settings}>
                <LazyLoadImageComponent
                  alt={img?.alt}
                  w="full"
                  src={img?.url}
                  minH="270px"
                />
              </Box>
            ))}
          </Slider>
        ) : (
          <LazyLoadImageComponent
            alt={data.thumbnails[0]?.alt}
            title={data.thumbnails[0]?.alt}
            // w="full"
            textAlign="center"
            float={{ base: 'none', md: float }}
            src={data.thumbnails[0]?.url}
            height={{
              base: volunteerUrl ? '160px' : 'auto',
              md: volunteerUrl
                ? '300px'
                : outreactZigZagSection
                ? 'auto'
                : '100%',
            }}
          />
        )}

        <style jsx global>
          {`
            .slick-dots {
              bottom: 5px;
            }
            .slick-dots li.slick-active p {
              background-color: #f5ac3e;
            }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default CardInfoWithMultipleImageDetail;
