/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders the QuoteSymbolImageSignature Component
 *
 * @param {object} sectionContent - data
 * @returns {ReactElement} Quote component
 */

const QuoteSymbolImageSignature = ({ sectionContent, txtcolor, pname, ...props }) => {
  return (
    <Box maxW={1330} mx="auto" width="100%" mb={{ base: '10px', md: '15px' }}>
      <Box
        mb="40px"
        maxW={{ base: 'calc(100% - 70px)', md: '560px' }}
        height="auto"
        mx="auto"
        w="100%"
        p={props.padding || ''}
        pos="relative"
        _before={{
          content: "''",
          backgroundImage: `url(${config.imgPath}/d/46272/1710874057-left-quote-brown.png)`,
          backgroundPosition: 'center',
          backgroundSize: '100%',
          w: '14px',
          height: '14px',
          border: 'none',
          top: '5px',
          left: { base: "-20px", md: '-30px' },
          pos: 'absolute',
        }}
        _after={{
          content: "''",
          backgroundImage: `url(${config.imgPath}/d/46272/1710874051-right-quote-left-brown.png)`,
          backgroundPosition: 'center',
          backgroundSize: '100%',
          w: '14px',
          height: '14px',
          border: 'none',
          bottom: '5px',
          right: { base: "-20px", md: '-30px' },
          pos: 'absolute',
        }}
      >
        <Text
          color={!txtcolor ? "#28231E" : txtcolor}
          textAlign="center"
          fontFamily="FedraSansStd-book, sans-serif"
          fontSize={{ base: '18px', md: '20px' }}
          fontStyle={props.fontStyle || "italic"}
          fontWeight="400"
          lineHeight={{ base: '170%', md: '180%' }}
        >
          {sectionContent?.quote}
        </Text>
      </Box>
    </Box>
  );
}

export default QuoteSymbolImageSignature;
