/* Built In Imports */
/* External Imports */
import axios from 'axios';
import cache from 'memory-cache';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls, getLatestBuild } from 'services/commonService';
import { tryGetPreviewData } from 'utils';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText, section) => {
  const data = config.BUILD_URLS?.OTHERS?.enabled
    ? config.BUILD_URLS.OTHERS
    : await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID ||
      process.env.env_var_others_build_id ||
      process.env.env_var_build_id
    );

  let urlArr = filterBuildUrls(
    section || 'others_section',
    level,
    url,
    notMatchText,
    data
  );
  return urlArr || [];
};

export const fetchDetails = async (region, lang, slug = ``, previewData, siteSection) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  }
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: siteSection ? `${siteSection}` : `others`,
        slug: `${slug}`,
        region,
        lang,
      },
    }
  );

  return pageData;
};

export const fetchReportsList = async () => {
  const pageData = await new IsoBackendHttpService().get(
    `/content/isha-foundation/reports-list/read`,
    !isCache,
    {}
  );

  return pageData;
};

export const fetchReportPdf = async values => {
  const response = await axios({
    method: 'POST',
    url: `${config.FACADE_BASE_PATH}/content/isha-foundation/reports/data/write`,
    data: values,
  });

  return response?.data;
};

export const fetchPrograms = async url => {
  const response = await axios.get(url, { timeout: 3000 });
  return response?.data;
};

export const fetchApiAndCache = async (path, time = 1000 * 60 * 60) => {
  const cachedRes = cache.get(path);
  if (cachedRes) return cachedRes;

  const res = await fetchPrograms(path);

  cache.put(path, res, time);
  return res;
};

export const getLatLong = async data => {
  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: config.LAT_LONG_API_PATH,
      data,
    });
    return resp?.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchPubCategoryBooks = async (
  uuid,
  isThisABook,
  start,
  region,
  lang,
  limit = 12,
  sortby = 'newest'
) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'publication',
        categoryUuid: uuid,
        region,
        lang,
        isThisABook,
        limit,
        sortby,
        start,
      },
    }
  );
  return pageData;
};

export const fetchPBKOnlineContentData = async (region, language) => {
  const resp = await axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // url: config.PBK_PROGRAM_API
    url: `${config.PBK_PROGRAM_API}&lang=${language}`,
  });
  return resp?.data;
};

export const pushIdyInitiatives = async values => {
  // console.log('pushIdyInitiatives', values);
  try {
    const res = await axios.post(
      `${config.IDY_PUSH}`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};
