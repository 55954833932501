/* Built In Imports */
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param {Array} faqSection - Main FAQ data.
 * @param {string} region
 * @param {string} lang
 * @param {string} bgColor
 * @param {string} color
 * @returns {ReactElement} FaqSection component.
 */

const YogaSandhyaFaq = ({ faqSection, region, lang, color }) => {
  return (
    <>
      <Box
        maxW={1330}
        h="auto"
        w="100%"
        mb={{ md: '40px', base: '40px' }}
        mx="auto"
      >
        <Box h="auto" m="0 auto" id={faqSection?.id} className="faqSection">
          <Accordion
            key={nanoid()}
            allowMultiple
            m={{ base: '0 auto' }}
            width={{ base: '100%' }}
          >
            <AccordionItem
              border="none"
              bgImage="/assets/images/FaqYS.png"
              bgRepeat="no-repeat"
              backgroundSize={{ base: '100% 75px', md: '100% 140px' }}
              backgroundPosition="top"
              _hover={{ bgImage: '/assets/images/FaqYS.png' }}
              _active={{ bgImage: '/assets/images/FaqYS.png' }}
            >
              <AccordionButton
                borderRadius="0"
                // mb="2px"
                fontFamily="FedraSansStd-medium"
                fontSize="20px"
                padding="10px 10px 10px 13px"
                color={color || 'white'}
                _focus={{ shadow: 'transparent' }}
                lineHeight="1.5"
                maxW={{ md: '663px' }}
                mx="auto"
                h={{ md: '140px', base: '75px' }}
              >
                <chakra.div
                  flex="1"
                  textAlign="left"
                  fontFamily="FedraSansStd-A-medium"
                  lineHeight={{ md: '37.8px', base: '22.68px' }}
                  color="#FFFFFF"
                  fontSize={{ md: '30px', base: '18px' }}
                  ml={{ md: '15px', base: '20px' }}
                  fontWeight="500"
                >
                  {faqSection.title}
                </chakra.div>
                <AccordionIcon
                  color="#28231E"
                  mr={{ base: '15px', md: '15px', lg: "0" }}
                  background="#FFFFFF"
                  rounded="full"
                  h={{ md: '42px', base: '26px' }}
                  w={{ md: '42px', base: '26px' }}
                />
              </AccordionButton>
              <AccordionPanel
                pt={{ md: '48.12px', base: '18px' }}
                background="linear-gradient(180deg, #FFF7E2 0%, rgba(255, 255, 255, 0.00) 100%)"
              >
                {faqSection?.faqs?.length
                  ? faqSection.faqs.map((faq, index) => {
                    return (
                      <Box maxW={{ md: '663px' }} key={nanoid()} mx="auto">
                        <FaqChildren
                          faq={faq}
                          region={region}
                          lang={lang}
                          faqs={faqSection.faqs}
                          ind={index}
                          borderBottom={
                            index !== faqSection?.faqs?.length - 1
                          }
                        />
                      </Box>
                    );
                  })
                  : null}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

const FaqChildren = ({ faq, region, lang, faqs, ind, borderBottom }) => {
  return (
    <AccordionItem
      borderBottomWidth={0}
      borderBottomColor={borderBottom ? '#996E31' : 'transparent'}
      borderTop="none"
      lineHeight="normal"
      marginLeft={{ md: '0px' }}
      color="#28231E"
      fontFamily="FedraSansStd-Book,sans-serif"
      fontSize={{ md: '16px' }}
      fontStyle="normal"
      fontWeight="500"
      w="100%"
      minW={266}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            maxW={700}
            ml="0px"
            // pb="0.8rem"
            p={{
              base: "15px 5px 25px 0",
              md: isExpanded ? "29px 15px 5px 15px" : "29px 5px 25px 0"
            }}
            fontFamily="FedraSansStd-medium"
            fontSize="1rem"
            borderTop="none"
            color="#fff"
            _hover={{ background: 'none' }}
            _focus={{ shadow: 'transparent' }}
            w={{ base: '100%', md: '100%' }}
          >
            <chakra.div
              w={{ base: '95%', md: '95%' }}
              marginLeft={{ base: '0px', md: '0px' }}
              flex="1"
              textAlign="left"
              color="#121212"
            >
              {faq.question}
            </chakra.div>
            {/* <AccordionIcon
          color="#721B0F"
          mt={{ base: '0px', md: '3px' }}
          mr={{ md: '5px', sm: '7px', base: '10px' }}
          w="28px"
          h="28px"
        /> */}
            <Box fontSize="25px" color="#721B0F"
              mr={{ md: isExpanded ? '5px' : "10px", sm: '0px', base: '10px' }}
            >
              {isExpanded ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                    <mask id="mask0_926_6198" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="10">
                      <path d="M0.433555 8.76859C0.968305 9.27062 1.81975 9.31498 2.4089 8.8703L7.21634 5.24139C8.23808 4.28092 9.89673 4.28092 10.9185 5.24139L15.7259 8.86992C16.3151 9.31498 17.1665 9.27062 17.7008 8.7686C18.3019 8.20463 18.2749 7.28124 17.6424 6.74824L10.056 0.355301C9.49423 -0.118434 8.64058 -0.118434 8.0784 0.355301L0.492824 6.74824C-0.140118 7.28162 -0.167097 8.20501 0.433998 8.76859" fill="white" />
                    </mask>
                    <g mask="url(#mask0_926_6198)">
                      <path d="M0.433555 8.76859C0.968305 9.27062 1.81975 9.31498 2.4089 8.8703L7.21634 5.24139C8.23808 4.28092 9.89673 4.28092 10.9185 5.24139L15.7259 8.86992C16.3151 9.31498 17.1665 9.27062 17.7008 8.7686C18.3019 8.20463 18.2749 7.28124 17.6424 6.74824L10.056 0.355301C9.49423 -0.118434 8.64058 -0.118434 8.0784 0.355301L0.492824 6.74824C-0.140118 7.28162 -0.167097 8.20501 0.433998 8.76859" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M-2.6543 11.4693L-2.6543 -1.91309L20.3457 -1.91309L20.3457 11.4693L-2.6543 11.4693Z" fill="#721B0F" />
                    </g>
                  </svg>
                </>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                    <mask id="mask0_928_7692" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="10">
                      <path d="M18.3575 1.02144C17.8227 0.519415 16.9713 0.475063 16.3821 0.919739L11.5747 4.54865C10.5529 5.50912 8.89429 5.50912 7.87256 4.54865L3.06511 0.92012C2.47596 0.475061 1.62452 0.519415 1.09021 1.02144C0.489114 1.58541 0.516095 2.5088 1.14859 3.0418L8.73505 9.43474C9.29679 9.90847 10.1504 9.90847 10.7126 9.43474L18.2982 3.0418C18.9311 2.50841 18.9581 1.58503 18.357 1.02144" fill="white" />
                    </mask>
                    <g mask="url(#mask0_928_7692)">
                      <path d="M18.3575 1.02144C17.8227 0.519415 16.9713 0.475063 16.3821 0.919739L11.5747 4.54865C10.5529 5.50912 8.89429 5.50912 7.87256 4.54865L3.06511 0.92012C2.47596 0.475061 1.62452 0.519415 1.09021 1.02144C0.489114 1.58541 0.516095 2.5088 1.14859 3.0418L8.73505 9.43474C9.29679 9.90847 10.1504 9.90847 10.7126 9.43474L18.2982 3.0418C18.9311 2.50841 18.9581 1.58503 18.357 1.02144" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4453 -1.67947L21.4453 11.7029L-1.55469 11.7029L-1.55469 -1.67947L21.4453 -1.67947Z" fill="#721B0F" />
                    </g>
                  </svg>
                </>
              )}
            </Box>
          </AccordionButton>
          <AccordionPanel
            p="30px 0 0"
            fontSize="1.1rem"
            lineHeight="1.61"
          // marginLeft='5px'
          >
            <Box
              //  display="block"
              width="100%"
              fontFamily="FedraSansStd-Book,sans-serif"
              fontWeight={{ md: '400', base: '500' }}
              lineHeight={{ md: '22px', base: '26px' }}
              fontSize={{ base: '14px', md: '16px' }}
              color="#28231E"
              //pt={{md:'5px'}}
              mt={{ md: '-20px' }}
              ml={{ md: '0px' }}
              px={{ base: "0", md: "15px" }}
              maxW={653}
              className="ys-faq"
            >
              {StructuredTextContent(faq.answer)}
            </Box>
            <style>
              {`
              .ys-faq p {
                margin-bottom: 30px;
              }
              .ys-faq p a {
                color: #7E323B;
                text-decoration: underline;
              }
          `}
            </style>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default YogaSandhyaFaq;
