/* Built In Imports */
/* External Imports */
import { Flex } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import Card from '@components/UI/Publications/Card';

/*styles*/
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const CardSlider = ({ sectionContent, region, lang, pl }) => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "PubSlider",
    speed: 500,
    slidesToShow: sectionContent.length > 2 ? 3 : sectionContent.length,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: sectionContent.length > 1 ? 2 : sectionContent.length,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: 60,
        }
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: 60,
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: 30,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Flex
      // overflowX={{ base: 'scroll', md: 'hidden' }}
      w="100%"
      maxW={1000}
      overflow="visible"
      mx="auto"
      flexDir="column"
      mb={{ base: '0', md: '0' }}
      // maxW={{ base: '100%' }}
      pl={{ base: pl || "0" }}
      pb="0"
    >
      <Slider {...settings}>
        {sectionContent?.map(content => {
          return (
            <Card content={content} region={region} lang={lang} key={'card:' + content?.title} />
          )
        })}
      </Slider>
      <style>
        {`
        .PubSlider .slick-list .slick-track {
              padding: 0 0 50px 0; 
            }
        `}
      </style>
    </Flex>
  );
};

export default CardSlider;
