/* Built In Imports */
import NextLink from "next/link";
/* External Imports */
import { Box, Image, Link, List } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Components */

/* Styles */
import { useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { refineUrlDomain } from "@components/Utility/utils";
import config from "@config";

/* Services */

/**
 * @param sectionContent
 */

const AdBannerGroup = ({ sectionContent,region, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [slide, setSlide] = useState(0)
  const settings = {
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 300,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: false,
    beforeChange: (current, next) => setSlide(next),
    className: 'AdBannerSlider',
    appendDots: dots => (
      <Box h="1px" position="absolute" bottom={{ base: "38px", md: "25px" }}>
        <List
          display='inline' width="8px" height="8px"> {dots} </List>
      </Box>
    ),
    customPaging: (i) => (
      <Box
        background={i === slide ? "#CF4520" : '#D9D9D9'}
        width="8px"
        rounded="full"
        height="8px"
      >
      </Box>),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        },
      },
    ],
  };

  return (
    <>
      <Box pos="relative">
        <Box
          width="100%"
          height="100%"
          maxW="1300px"
          h={{ md: '487px', base: '635.5px' }}
          // py="40px"
          // pl={{ base: '16px', md: '70px', lg: '50px' }}
          mx="auto"
          mb="60px"
          px={{ base: "0px", md: "30px", lg: "40px", xl: "0" }}
          display="block"
          boxSizing="border-box"
        //maxH="487px"
        >
          <Slider {...settings}>
            {sectionContent?.adBannerImages?.map((banner) => {
              return (
                <Box
                  key={nanoid()}
                  position={"relative"}
                  // overscroll='auto'
                  h={{ md: '487px', base: '635.5px' }}

                >
                  <NextLink
                    href={
                      banner?.linkUrl
                        ? refineUrlDomain(banner?.linkUrl, region, lang)
                        : 'javascript:;'
                    }
                    passHref
                    legacyBehavior>
                    <Link
                      target={
                        banner?.linkUrl?.indexOf('http') > -1 &&
                          banner?.linkUrl.indexOf(config.cdnPath) < 0
                          ? '_blank'
                          : ''
                      }
                    >
                      <Image
                        src={isMobile ? (banner?.mobileImage?.url || banner?.image?.url) : banner?.image?.url}
                        alt={banner?.image?.alt || ""}
                        width="100%"
                        height="100%"
                        objectFit={{ base: "fill", md: "fill" }}
                      />
                    </Link>
                  </NextLink>
                </Box>
              );
            })}
          </Slider>
        </Box>
        <style>
          {`   
          .AdBannerSlider .slick-dots {
                        display: inline;
                        padding-bottom: 25px;
                      }
                      .slick-dots li{
                        width: 8px;
                        height :8px;
                      }
                      @media (max-width:600px){
                        .AdBannerSlider .slick-dots {
                        padding-bottom: 30px;
                      }
                      }
          `}
        </style>
      </Box>
    </>
  );
};

export default AdBannerGroup;
