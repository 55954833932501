/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Quote Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - data
 * @returns {ReactElement} Quote component
 */
const Quote = ({ sectionContent }) => {
  return (
    <Box
      maxW="660%"
      p={{ base: '15px', lg: '0% 11%', xl: '0% 15%' }}
      fontFamily="FedraSansStd-book-i"
      fontSize={{ base: 16, md: '20px' }}
      fontWeight="400"
      fontStyle="italic"
      lineHeight="2"
      textAlign="center"
      color="rgb(207 69 32)"
      marginBottom="40px"
    >
      {sectionContent?.quote}
    </Box>
  );
};

export default Quote;
