/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
// import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders the CardCustomCardGroup Group Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.section
 * @returns {ReactElement} CardCustomCardGroup component
 */
const CardCustomCardGroup = ({ sectionContent, region, lang, section }) => {
  // consoleLog(sectionContent.id);
  return <>
    {sectionContent?.cards?.map((secCon, index) => {
      return (
        <Box
          background={`${config.imgPath}/d/46272/1652112424-yogacontentbg.jpg`}
          backgroundSize="cover"
          objectFit="contain"
          width={'100%'}

          // m="15px 0"
        >
          <Box
            display="flex"
            flexDir={{
              base: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
            }}
            mb="10px"
            width={{ base: '100%', lg: '720px', md: '450px' }}
            height={{ base: 'auto', lg: '369px', md: '574px' }}
            bgColor="white"
            borderRadius="5px"
            boxShadow="0px 48px 60px rgba(0, 0, 0, 0.07), 0px 31.1111px 35.1389px rgba(0, 0, 0, 0.0531481), 0px 18.4889px 19.1111px rgba(0, 0, 0, 0.0425185), 0px 9.6px 9.75px rgba(0, 0, 0, 0.035), 0px 3.91111px 4.88889px rgba(0, 0, 0, 0.0274815), 0px 0.888889px 2.36111px rgba(0, 0, 0, 0.0168519);"
            // maxW="720px"
          >
            <Box px="0">
              <Box position="relative" mx="auto">
                <NextLink
                  href={refineUrlDomain(secCon.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link cursor={secCon.buttonLink ? 'cursor' : 'auto'}>
                    <LazyLoadImageComponent
                      src={secCon.thumbnail?.url}
                      width="100%"
                      height={{ base: '100%', lg: '369px', md: '197px' }}
                      objectFit={{ base: 'cover', lg: 'cover', md: 'unset' }}
                    />
                  </Link>
                </NextLink>
              </Box>
            </Box>
            <Box
              bgColor="white"
              px={{ base: '20px', md: '30px' }}
              height="100%"
              pt={{ base: '20px', md: '20px' }}
              maxW={'400px'}
            >
              <Box
                as="span"
                fontFamily="FedraSansStd-A-medium"
                fontSize={{ base: '22px', md: '29px' }}
                lineHeight={{base: '26px', md: section?.sectionId === "ssb-volunteer-section" ? '30px' : '52px'}}
                // lineHeight={
                //   sectionContent.id === '143294154' || '143294172'
                //     ? '52px'
                //     : '52px'
                // }
                color="#28231E"
                fontWeight="500"
              >
                {secCon.title}
              </Box>
              <Box
                fontFamily="FedraSansStd-book"
                fontSize={{ sm: '16px', base: '16px' }}
                fontWeight="400px"
                lineHeight={'24px'}
                fontStyle="normal"
                color={section?.sectionId === "other-volunteer-section" ||  section?.sectionId === "ssb-volunteer-section" ? "#28231E" : "#4E4E4E"}
                opacity="0.75"
                pt={'12px'}
                w={{ base: '100%', md: '331px' }}
              >
                {StructuredTextContent(secCon.description)}
              </Box>

              <Text
                borderRadius="50px"
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="400"
                lineHeight="20px"
                align="center"
                bg="#F5EDDF"
                w={{ base: "223.74px", md: "157px" }}
                h="35px"
                m={{ base: '30px auto 0 auto', md: "30px 0 0 0" }}
                textAlign="center"
                fontStyle={'normal'}
                fontFamily="FedraSansStd-book"
                p="2"                  
              >
                {sectionContent.cards[0].tags}
              </Text>

              <Box display="flex" justifyContent={{ base: "center", md: "space-between" }}>
                {secCon.buttonLink && (
                  <NextLink
                    href={refineUrlDomain(secCon.buttonLink, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link
                      bgColor="#cf4520"
                      color="#cf4520"
                      minW={{ base: '240px', md: '160px' }}
                      h="46px"
                      align="center"
                      mr={{ base: '0', md: '20px' }}
                      padding="5px 30px"
                      border="1px solid #cf4520"
                      borderRadius="5px"
                      display="inline-block"
                      mb="30px"
                      mt={['12px', '12px', '12px', '14px']}
                      fontFamily="FedraSansStd-medium"
                      fontSize="16px"
                      fontWeight="500"
                      textColor="#FFFFFF"
                      p="2"
                      _hover={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _focus={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _active={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                    >
                      {secCon.buttonText}
                    </Link>
                  </NextLink>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    })}
  </>;
};

export default CardCustomCardGroup;
