/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Styles */
import styles from "@components/Text/brownSpiral.module.css";

const BrownSpiralBullets = ({ sectionContent, pt }) => {
  return (
    <Box>
      <MidContainer w="568px"
        pt={{lg: pt!= undefined ? pt : '20px', base: pt!= undefined ? pt : '20px' }}
        pl={{ base: '35px' }}
        pr={{ base: '15px' }}
      >
        <StructuredTextParser
          className={styles.BrownSpiralBullets}
          color="#28231E"
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="28px"
          str={render(sectionContent?.body)}
        />
      </MidContainer>
    </Box>
  );
};

export default BrownSpiralBullets;
