/* Built In Imports */
/* Built In Imports */
import { useState } from 'react';
import Slider from 'react-slick';

/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Components */
import { NextArrow, PrevArrow } from '@YogaSandhya/components/icons/index';

/* Styles */
import classes from '@components/Sliders/YogaSandhyaBrownGallery.module.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
/* Services */

/**
 * @param sectionContent
 */

const YogaSandhyaBrownGallery = ({ sectionContent }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    dots: true,
    slidesToShow: 1,
    slidesPerRow: 1,
    centerMode: true,
    centerPadding: '200px 60px',
    useTransform: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <Box>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Box>
    ),
    customPaging: (i) => (
      <Box
        background={imgIndex === i ? "#711B0F" : "#D2C8B5"}
        width="12px"
        rounded="full"
        height="12px"
      >
      </Box>
    ),
    beforeChange: (current, next) => setImgIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerPadding: '100px 60px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerPadding: '100px 50px',
        },
      },
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px 35px',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px 30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px 30px',
        },
      },
    ],
  };

  return (
    <>
      <Box pos="relative">
        <Box
          width="100%"
          height="auto"
          maxW="1052px"
          // py="40px"
          // pl={{ base: '16px', md: '70px', lg: '50px' }}
          mx="auto"
          px={{ base: "0px", md: "30px", lg: "40px", xl: "0" }}
          display="block"
          boxSizing="border-box"
          maxH="464px"
        >
          <Slider {...settings}>
            {sectionContent?.images?.map((image, index) => {
              return (
                <Box
                  key={nanoid()}
                  position={"relative"}
                  zIndex={imgIndex !== index ? 5 : 10}
                  _before={{
                    display: { base: "none", md: "block" },
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: { base: "calc(100% - 60px)", md: "calc(100% - 90px)" },
                    top: { base: "30px", md: "45px" },
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: { base: "30px 0", md: "45px 0" },
                    background: "rgba(0, 0, 0, 0.9)",
                    // opacity: '0.3',
                  }}
                  className={
                    imgIndex === index
                      ? classes.slickCurrent
                      : classes.slickDeactive
                  }
                >
                  <Box
                    w="100%"
                    height={{
                      base: '190px',
                      sm: '192px',
                      md: '300px',
                      lg: '404px',
                    }}
                    position="relative"
                  >
                    <Image
                      src={image.url}
                      alt={image.alt || ""}
                      width="100%"
                      height="100%"
                      objectFit="cover"
                      opacity={imgIndex === index ? "1" : "0.3"}
                    />
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default YogaSandhyaBrownGallery;
