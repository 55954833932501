/* BuiltIn Imports */
import { useRouter } from 'next/router';
import React, { Suspense, useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Center, Flex, Icon, Show, Text } from '@chakra-ui/react';
import { orderByDistance } from 'geolib';
import { nanoid } from 'nanoid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';

/* Internal Imports */
/* Components */
import Loader from '@components/UI/ProgramFinder/Loader';
import ProgramCard from '@components/UI/ProgramFinder/ProgramCard';
import { useProgramContext } from './ProgramFinderStore';

/* Styles */
import 'swiper/css';
import "swiper/css/bundle";

/**
 *
 * @returns
 */

const ListPrograms = ({ region, lang, setSearch, data, userLocation }) => {
  const {
    fetchFilteredData,
    filteredPrograms,
    isLoading,
    filters,
    finalCards,
    clearFilters,
    isFiltersApplied,
    filterObjCopy,
    filtersCount,
    storedPrograms,
  } = useProgramContext();

  const [loadNext, setLoadNext] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: '480px' });
  const [count, setCount] = useState(10);
  const [nearCount, setNearCount] = useState(10);
  const nearCountHandler = () => {
    if (count < 50) {
      setNearCount(nearCount + 10);
    }
  };
  const router = useRouter();
  // console.log({
  //   store: {
  //     filteredPrfograms,
  //     filterObjCopy,
  //     finalCards,
  //     filters,
  //     isLoading,
  //   },
  // });

  useEffect(() => {
    //fetching on loadNext toggle
    fetchFilteredData();
  }, [loadNext]);
  const [ind, setIndex] = useState(4); // Featured cards count
  const featureInc = 4; // number to increment on load more click
  function loadMore() {
    setIndex(ind + featureInc);
  }

  // const [sortedPrograms, setSortedPrograms] = useState(null);
  // useEffect(() => {
  //   filteredPrograms.forEach(pro => {
  //     const [latitude, longitude] = pro?.latlong?.split(',');
  //     pro.latitude = latitude;
  //     pro.longitude = longitude;
  //   });
  //   if (!userLocation?.browser && userLocation?.vpn) {
  //     setSortedPrograms(
  //       orderByDistance({ ...userLocation?.vpn }, filteredPrograms)
  //     );
  //     // console.log(filteredPrograms, 'cards', sortedPrograms);
  //   } else if (
  //     userLocation?.browser ||
  //     sortedPrograms?.length !== filteredProg
  //   ) {
  //     setSortedPrograms(
  //       orderByDistance({ ...userLocation?.browser }, filteredPrograms)
  //     );
  //   } else {
  //     setSortedPrograms(filteredPrograms);
  //   }
  // }, [filterObjCopy, filteredPrograms, filteredPrograms.length]); // we can add filter also

  // const filteredProg = sortedPrograms;
  let sorted = [...storedPrograms];
  sorted.forEach(el => {
    const [latitude, longitude] = el.latlong.split(',');
    el.latitude = latitude || '';
    el.longitude = longitude || '';
  });

  const countHandler = () => {
    if (count < sorted.length) {
      setCount(count + 10);
    }
  };
  return (
    <>
      {filteredPrograms?.length > 0 && (
        <InfiniteScroll
          dataLength={filteredPrograms?.length}
          style={{ overflow: 'inherit' }}
          next={() => {
            setLoadNext(!loadNext);
          }}
          hasMore={
            finalCards ? filteredPrograms?.length < finalCards?.length : false
          }
        >
          <>
            {/* {isFiltersApplied && isMobile ? (
              <Box
                m="0"
                fontWeight="bold"
                fontSize="14px"
                color="#DE5A5A"
                fontFamily="FedraSansStd-book"
                cursor="pointer"
                px={{ base: '1.5rem', md: '0px' }}
                mb="15px"
                onClick={() => {
                  clearFilters();
                  setSearch('');
                }}
              >
                {data?.json?.clearAll}
              </Box>
            ) : ( */}
            <Box
              m="0"
              fontSize="22px"
              fontFamily="FedraSansStd-medium"
              px={{ base: '15px', md: '0px' }}
              color="#28231E"
              // mb="15px"
            >
              {isFiltersApplied ? (
                <Text mb={{ base: '17px', md: '13px' }}>
                  {data?.json?.searchResultsTitle}{' '}
                  <Text
                    as="span"
                    color="#D04723"
                    fontFamily="FedraSansStd-medium"
                  >
                    ({finalCards?.length})
                  </Text>
                </Text>
              ) : !userLocation?.browser?.latitude ? (
                data?.json?.featuredProgramsTitle
              ) : (
                data?.json?.pgmNearYou
              )}
            </Box>
            {/* )} */}
            {/* filtered cards */}
            <Flex
              //  mt="1rem"
              // mb="1rem"
              justify={{ base: 'center', md: 'initial' }}
              gridColumnGap="18px"
              gridRowGap="20px"
              flexWrap="wrap"
              px={{ base: '15px', md: '0px' }}
            >
              <Suspense fallback="Loading...">
                {isFiltersApplied &&
                  filteredPrograms?.length > 0 &&
                  filteredPrograms.map((program, k) => {
                    return (
                      <Suspense fallback="Loading..." key={k}>
                        <ProgramCard
                          data={data}
                          key={k}
                          card={program}
                          region={region}
                          lang={lang}
                        />
                      </Suspense>
                    );
                  })}
              </Suspense>
            </Flex>
          </>
        </InfiniteScroll>
      )}
      {/* filtered mobile cards */}
      {!userLocation?.browser?.latitude &&
      !isFiltersApplied &&
      filteredPrograms?.length > 0 ? (
        <Show breakpoint="(max-width: 767px)">
          <Flex
            mt={{ base: '11px', md: '8px' }}
            mb="30px"
            // mb="1rem"
            // pl={{ base: '0', md: '4px' }}
            justify="center"
            gridColumnGap="18px"
            gridRowGap="20px"
            flexWrap={{ base: 'no-wrap', md: 'wrap' }}
            pl={{ base: '5px', md: '0px' }}
            // overflowX={{ base: 'auto', md: 'hidden' }}
          >
            <Swiper
              centeredSlides={false}
              // modules={[Virtual]}
              slidesPerView={'auto'}
              spaceBetween={'16px'}
              // slidesPerView={useBreakpointValue({
              //   base: '1.1',
              //   sm: '1.7',
              //   md: '2',
              // })}
              // // slidesPerView={2}
              // spaceBetween="16px"
            >
              {!userLocation?.browser?.latitude &&
                !isFiltersApplied &&
                filteredPrograms?.length > 0 &&
                filteredPrograms.map((program, k) => {
                  return (
                    <SwiperSlide
                      key={k}
                      // style={{ width: '302px !important' }}
                      // width="302px"
                    >
                      <Box height="100%" px="10px">
                        <ProgramCard
                          // width="80vw !important"
                          my="10px"
                          w="80%"
                          // minW="280px"
                          data={data}
                          key={k}
                          card={program}
                          region={region}
                          lang={lang}
                          className="sliderCard"
                        />
                      </Box>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <style>
              {`
            .swiper {
              width: 100%;
              height: 100%;
              margin:0;
            }
            
            .swiper-slide {
              /* Center slide text vertically */
              width: 90%;
              max-width:300px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
            }            
              `}
            </style>
          </Flex>
        </Show>
      ) : (
        ''
      )}
      {/* Program near */}
      {userLocation?.browser?.latitude && !isFiltersApplied && (
        <InfiniteScroll
          dataLength={nearCount}
          next={nearCountHandler}
          hasMore={sorted ? count < 50 : false}
          // loader={<h4>Loading...</h4>}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
          style={{ overflow: 'inherit' }}
        >
          <Flex
            mt={{ base: '17px', md: '13px' }}
            mb="1rem"
            // pl={{ base: '0', md: '4px' }}
            justify={{ base: 'center', md: 'initial' }}
            gridColumnGap="18px"
            px={{ base: '15px', md: '0px' }}
            gridRowGap="20px"
            flexWrap="wrap"
            // overflowX={{ base: 'auto', md: 'hidden' }}
          >
            {/* Programs Near You section */}
            {!isFiltersApplied &&
            filteredPrograms?.length > 0 &&
            userLocation?.browser?.latitude
              ? orderByDistance(userLocation?.browser, sorted)
                  .slice(0, 50)
                  .slice(0, nearCount)
                  ?.map((program, k) => {
                    return (
                      <ProgramCard
                        data={data}
                        key={k}
                        card={program}
                        region={region}
                        lang={lang}
                      />
                    );
                  })
              : ''}
          </Flex>
        </InfiniteScroll>
      )}
      {/* Featured Cards desktop */}
      {!isFiltersApplied && (
        <Show breakpoint="(min-width: 768px)">
          <Flex
            mt="1rem"
            mb="1rem"
            // pl={{ base: '0', md: '4px' }}
            justify="flex-start"
            gridColumnGap="18px"
            gridRowGap="20px"
            flexWrap="wrap"
            px={{ base: '15px', md: '0px' }}
            // overflowX={{ base: 'auto', md: 'hidden' }}
          >
            {!userLocation?.browser?.latitude &&
              !isFiltersApplied &&
              filteredPrograms?.length > 0 &&
              filteredPrograms.slice(0, ind).map((program, k) => {
                return (
                  <ProgramCard
                    data={data}
                    key={k}
                    card={program}
                    region={region}
                    lang={lang}
                  />
                );
              })}
          </Flex>
          {ind < filteredPrograms?.length &&
            !userLocation?.browser?.latitude && (
              <Center>
                <Button
                  onClick={loadMore}
                  // style={{
                  //   buttonText: 'Show more',
                  //   buttonStyle: 'Orange',
                  // }}
                  className="orange-button"
                  mt={{ base: '16px', md: '20px' }}
                  mb={'30px'}
                  display={'block'}
                  justifyContent="center"
                  alignItems="center"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={'18px'}
                  bgColor={'#cf4520'}
                  h={'auto'}
                  minH="50px"
                  cursor={'pointer'}
                  textAlign={'center'}
                  padding={'14px 29px'}
                  borderRadius="5px"
                  color="#faf7f0"
                  textDecoration="none"
                  width={'auto'}
                  _hover={{
                    bg: '#92361E',
                    color: '#fff',
                  }}
                  _active={{ bg: '#92361E', color: '#fff' }}
                  _focus={{ bg: '#92361E', color: '#fff' }}
                >
                  {data.json.showMore}
                </Button>
              </Center>
            )}
        </Show>
      )}
      {/* Program for you section */}
      {!userLocation?.browser?.latitude &&
        !isFiltersApplied &&
        filteredPrograms?.length > 0 && (
          <>
            <Box
              mt={{ base: '0px', md: '10px' }}
              fontSize="22px"
              fontFamily="FedraSansStd-medium"
              px={{ base: '15px', md: '0px' }}
              color="#28231E"
            >
              {data?.json?.showPgmForYou}
            </Box>
            <InfiniteScroll
              dataLength={count} //This is important field to render the next data
              next={countHandler}
              hasMore={sorted ? count < sorted.length : false}
              loader={<h4>Loading...</h4>}
              style={{ overflow: 'inherit' }}
            >
              <Flex
                mt={{ base: '17px', md: '13px' }}
                mb="1rem"
                // pl={{ base: '0', md: '4px' }}
                justify={{ base: 'center', md: 'initial' }}
                gridColumnGap="18px"
                px={{ base: '15px', md: '0px' }}
                gridRowGap="20px"
                flexWrap="wrap"
              >
                {!isFiltersApplied &&
                filteredPrograms?.length > 0 &&
                !userLocation?.browser?.latitude &&
                userLocation?.vpn?.latitude
                  ? orderByDistance(userLocation?.vpn, sorted)
                      .slice(0, count)
                      ?.map((program, k) => {
                        return (
                          <ProgramCard
                            data={data}
                            key={k}
                            card={program}
                            region={region}
                            lang={lang}
                          />
                        );
                      })
                  : ''}
              </Flex>
            </InfiniteScroll>
          </>
        )}
      {/* messages to show*/}
      <Box px={{ base: '15px', md: '0' }}>
        {filtersCount?.location > 0 &&
        filters?.find(el => el.id === '297' || el.parentId === '297') &&
        // isFiltersApplied &&
        finalCards?.length === 0 ? (
          <>
            <Box
              // m="0 1.5rem"
              mb={{ base: '20px', md: '30px' }}
              fontWeight="normal"
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="FedraSansStd-book"
            >
              {data?.json?.ieoNoResults}
            </Box>
          </>
        ) : finalCards?.length === 0 ? (
          <>
            <Text
              mb={{ base: '16px', md: '20px' }}
              // m="0 1.5rem"
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={{ base: '350', md: '400' }}
              fontFamily="FedraSansStd-book"
              color="#28231E"
            >
              <Icon
                width="21px"
                mr="8px"
                height="21px"
                viewBox="0 0 21px 21px"
                fill="none"
              >
                <path
                  d="M8.96875 18.5938H4.15625L4.15625 2.40625L16.8438 2.40625V9.40625M17.9375 18.8125L16.7764 17.6139M16.7764 17.6139C17.3592 17.0216 17.7188 16.2091 17.7188 15.3125C17.7188 13.5003 16.2497 12.0313 14.4375 12.0313C12.6253 12.0313 11.1563 13.5003 11.1563 15.3125C11.1563 17.1247 12.6253 18.5938 14.4375 18.5938C15.3531 18.5938 16.1812 18.2187 16.7764 17.6139ZM7.65625 5.90625H13.3438M7.65625 9.40625H10.7188"
                  stroke="#CF4724"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                />
              </Icon>
              {data.json.noResults}
            </Text>
            <Box
              // m="0 1.5rem"
              m="0"
              mb={{ base: '20px', md: '30px' }}
              fontWeight="normal"
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="FedraSansStd-book"
            >
              {data?.json?.emptyResults}
            </Box>
          </>
        ) : (
          ''
        )}
      </Box>
      {/* Fallback Card */}
      {finalCards?.length == 0 &&
        storedPrograms.map(prog => {
          if (prog.id === '12733') {
            return (
              <ProgramCard
                data={data}
                key={nanoid()}
                card={prog}
                region={region}
                lang={lang}
                m={{ base: '0', md: '0' }}
              />
            );
          }
        })}
      {!isLoading &&
        filteredPrograms?.length == 0 &&
        !finalCards?.length == 0 && (
          <Loader pos="relative" left="45%" top="40%" />
        )}
    </>
  );
};

export default React.memo(ListPrograms);
