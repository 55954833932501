/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
// import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextContent from '@components/UI/StructuredTextContent';
// import { consoleLog } from '@components/Utility/Shared/SharedService';

/* Services */

/**
 * Renders Top Banner Without Image Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data from api
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @param sectionContent.region
 * @param sectionContent.language
 * @returns
 */
const TopBannerWithoutImage = ({ sectionContent, region, language }) => {
  // consoleLog(sectionContent);
  const styles = {
    default: 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
    'without-bottom-angle': '',
    '': 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="#F5F5F5"
      w="full"
    >
      <Box justifyContent="center" w="full">
        <Box p={{ base: '10px', md: '30px', lg: '50px' }}>
          <Flex
            w={{ base: 'full', md: '80%', lg: '60%' }}
            mx="auto"
            textAlign="center"
            fontSize={{
              sm: '44px',
              base: '30px',
            }}
            lineHeight="1.11"
            fontFamily="FedraSerifAStdBook"
          >
            <Box as="h1" py="30px" fontSize={[30, 32, 44, 44]}>
              {sectionContent?.title}
            </Box>

            {sectionContent.description && (
              <Box
                fontSize={['16px', '16px', '16px', '27px']}
                padding={{ base: '8vw 1vw 2vw 0', md: '2vw 0 0' }}
                fontFamily="FedraSansStd-book"
              >
                {StructuredTextContent(sectionContent.description)}
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default TopBannerWithoutImage;
