/* Built In Imports */
/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import { render } from 'datocms-structured-text-to-html-string';

/* Services */
const TestimonialCard = ({ card, pageData }) => {
  const pName = pageData?.pageConfig?.pageName

  // console.log("p name", pName)
  return (
    <Box
      h={{
        base: pName === "hytt" ? "560px" : "436px", md: pName === "hytt" ? "460px" : "356px"
      }}
      my="50px"
      // border="1px solid black"
      width={{ base: '320px', sm: '320px', md: '503px' }}
      // mx={{ base: "5px", sm: "16px", md: "5px" }}
      mx={{ base: pName === "hytt" ? "auto" : "5px", sm: pName === "hytt" ? "auto" : "16px", md: pName === "hytt" ? "auto" : "5px" }}

    >
      <Box
        borderRadius="12px"
        background={pName === "hytt" ? "white" : "#FFF9EA"}
        width="100%"
        height='100%'
        py={"50px"}
        px={{ base: "24px", md: "35px" }}
        boxShadow={pName === "hytt" ? "none" : "0px 1.7680000066757202px 9.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 11.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 16.239999771118164px 0px rgba(0, 0, 0, 0.03), 0px 13.27199935913086px 18.079999923706055px 0px rgba(0, 0, 0, 0.04), 0px 6px 19px 0px rgba(0, 0, 0, 0.06)"}
        display="flex"
        alignItems={pName == "hytt" ? "left" : "center"}
        justifyContent="space-around"
        flexDir="column"
        position="relative"
      >

        <Box
          w={{ base: '220px', md: '325px' }}
          // h="137px"
          mx="auto"
          pos="relative"
          _before={{
            content: "''",
            backgroundImage: `${config.imgPath}/d/46272/1712733684-left-quote-gray.png`,
            width: { base: '22px', md: '33.236px' },
            height: { base: '20px', md: '30.053px' },
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'absolute',
            top: { base: '0px', md: '-20px' },
            left: { base: '-32px', md: '-54px' },
          }}
          _after={{
            content: "''",
            bottom: { base: '0', md: '-10px' },
            right: { base: '-22px', md: '-54px' },
            backgroundImage:`${config.imgPath}/d/46272/1712733679-right-quote-gray.png`,
            width: { base: '22px', md: '33.236px' },
            height: { base: '20px', md: '30.053px' },
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'absolute'
          }}
          textAlign="center"
        >
          <Box>
            <StructuredTextParser
              color="#28231E"
              textAlign={"center"}
              fontFamily="FedraSansStd-book, sans-serif"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="28px"
              mt="0"
              width={pName == "hytt" ? "350px" : "auto"}
              str={render(card?.testimonialText)}
            />
          </Box>
        </Box>
        <Box
          w="auto"
          maxW={{ base: '280px', md: '356px' }}
          //  h="137px"
          mx="auto"
          textAlign="center"
        >
          <Text
            color="#02026C"
            textAlign="center"
            fontFamily="FedraSansStd-medium, sans-serif"
            fontSize="22px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="28px"
          >
            {card?.userName}
          </Text>
          <Box
            color="#28231E"
            textAlign="center"
            fontFamily="FedraSansStd-book, sans-serif"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="450"
            lineHeight="28px"
          >
            <StructuredTextParser
              color="#28231E"
              textAlign="center"
              fontFamily="FedraSansStd-medium, sans-serif"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="450"
              lineHeight="28px"
              mt="0"
              str={render(card?.userDescription)}
            />
          </Box>
        </Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          pos="absolute"
          bottom={{ base: "-27px", md: "-42.5px" }}
          left={0}
        >
          <Box width={{ base: 54, md: 85 }} height={{ base: 54, md: 85 }} rounded="full">
            <Image
              alt={card?.photo?.alt}
              width={{ base: 54, md: 85 }}
              height={{ base: 54, md: 85 }}
              src={card?.photo?.url}
              loading="lazy"
              background="white"
              rounded="full"
              border="3px solid #B56576"
            />
          </Box>
        </Flex>
      </Box>
    </Box >
  );
};

export default TestimonialCard;
