/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
// import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getCardBgColors, refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Event Program Card component.
 * @param {Object} programs - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param {string} - Type of card in api
 * @returns {ReactElement} EventProgramCard Card component.
 */
const EventProgramCard = ({ programs, region, lang, cardType }) => {
  let bgColors = getCardBgColors(cardType);
  const clips = Styles.clipPaths;

  return (
    <Box
      display="flex"
      justifyContent="center"
      gridGap={{ base: '15px', lg: '30px' }}
      my="15px"
      flexWrap="wrap"
    >
      {/* dynamic binding */}
      {programs &&
        programs.length > 0 &&
        programs.map((item, index) => {
          return (
            <NextLink
              href={refineUrlDomain(item?.urlAlias, region, lang)}
              passHref
              legacyBehavior>
              <Link
                cursor={item?.urlAlias ? 'cursor' : 'auto'}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Box
                  textAlign="left"
                  boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                  _hover={{ boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)' }}
                  background="#ffffff"
                  w={{ base: '100%', sm: '330px', lg: '370px' }}
                  // alignItems="stretch"
                  h="full"
                  key={nanoid()}
                >
                  <Box
                    background={bgColors[index % bgColors.length]}
                    position={'relative'}
                  >
                    <Box>
                      <LazyLoadImageComponent
                        src={item?.heroImage?.url}
                        alt={item?.heroImage?.alt}
                        title={item?.heroImage?.title}
                        clipPath={clips[index % clips.length]}
                        // clipPath="polygon(0 0, 100% 0, 100% 100%, 0% 92%)"
                        h={{ sm: '220px', xl: '225px' }}
                        w="100%"
                      />
                    </Box>
                    <Box padding="18px">
                      <Text
                        fontFamily="FedraSansStd-A-medium"
                        fontSize={{
                          base: '24px',
                          md: '17px',
                          lg: '24px',
                        }}
                        className="truncate-text"
                        color="#ffffff"
                      >
                        {item.title}
                      </Text>
                    </Box>
                  </Box>
                  <Box padding="18px">
                    <Text
                      fontSize={{ base: '17px', md: '13px', lg: '17px' }}
                      lineHeight="1.35"
                      fontFamily="FedraSansStd-book"
                      color="#28231e"
                    >
                      <Box className="truncate-text">{item.summary}</Box>
                    </Text>
                    {item?.secondCtaLabel && (
                      <Box>
                        <NextLink
                          href={refineUrlDomain(
                            item?.secondCtaLink,
                            region,
                            lang
                          )}
                          passHref
                          legacyBehavior>
                          <Link
                            mb="20px"
                            display="block"
                            fontWeight={500}
                            fontFamily="FedraSansStd-book"
                            fontSize={'18px'}
                            h={'auto'}
                            textAlign="center"
                            padding="10px 36px"
                            borderRadius="3px"
                            color="#cf4520"
                            textDecoration="underline"
                            _hover={{
                              textDecoration: 'none',
                            }}
                            target={
                              item?.secondCtaLink.indexOf('http') > -1 &&
                              item?.secondCtaLink.indexOf(config.cdnPath) < 0
                                ? '_blank'
                                : ''
                            }
                          >
                            {item?.secondCtaLabel}
                          </Link>
                        </NextLink>
                      </Box>
                    )}
                    {item?.buttonText && (
                      <Box>
                        <NextLink
                          href={refineUrlDomain(item?.buttonLink, region, lang)}
                          passHref
                          legacyBehavior>
                          <Link
                            width="190px"
                            display="block"
                            fontWeight={500}
                            fontFamily="FedraSansStd-book"
                            fontSize={'18px'}
                            bgColor="#cf4520"
                            h={'auto'}
                            textAlign="center"
                            padding="10px 36px"
                            borderRadius="3px"
                            color="#faf7f0"
                            textDecoration="none"
                            _hover={{
                              bg: '#000054',
                            }}
                            margin="20px auto"
                            target={
                              item?.buttonLink.indexOf('http') > -1 &&
                              item?.buttonLink.indexOf(config.cdnPath) < 0
                                ? '_blank'
                                : ''
                            }
                          >
                            {item?.buttonText}
                          </Link>
                        </NextLink>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Link>
            </NextLink>
          );
        })}
    </Box>
  );
};

export default EventProgramCard;
