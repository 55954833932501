/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';
// import { LazyLoadComponent } from 'react-lazy-load-image-component';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import config from '@config';

/* Services */
/* Styles */
import { useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders VolunteerSharingTopSliderGroup Component
 *
 * @param {object} sectionContent - Data from api
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @returns
 */

const VolunteerSharingTopSliderGroup = ({
  sectionContent,
  region,
  language,
}) => {
  const [textIndex, setTextIndex] = useState(0);
  const styles = {
    default: 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
    'without-bottom-angle': '',
    '': 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top={{ base: '25%', md: '45%' }}
        zIndex="15"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ base: '2%', md: '3%' }}
        bgSize="10px"
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top={{ base: '25%', md: '45%' }}
        zIndex="15"
        // display="none"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        // left="10px"
        left={{ base: '10px', md: '20px' }}
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => setTextIndex(next),
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
        ></Text>
      );
    },
  };
  // consoleLog(textIndex);
  return (
    <Box
      pos={'relative'}
      // maxH="500px"
      height="auto"
      // bgColor={{
      //   base: '#f8f5f0;',
      //   lg: 'transparent',
      // }}
    >
      <Slider {...settings}>
        {sectionContent &&
          sectionContent?.slides.length > 0 &&
          sectionContent?.slides.map((slide, index) => {
            return (
              <Box height={'auto'} w={'100%'} pos="relative" key={index}>
                <Box
                  h={{
                    base: '105.9375vw',
                    sm: 'auto',
                  }}
                  minH={{
                    base: '105.9375vw',
                    sm: '500px',
                  }}
                  bgColor={{
                    base: '#f8f5f0;',
                    sm: 'transparent',
                  }}
                  // background="radial-gradient(circle at 0% 0.5%, rgb(228 222 212) 0.1%, rgb(224, 226, 228) 100.2%)"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  backgroundPosition={{
                    base: '45%',
                    sm: '45%',
                    lg: 'top center',
                    xl: '0',
                  }}
                  backgroundImage={{
                    md: slide?.desktopImage?.url,
                    base: slide?.mobileImage?.url || slide?.desktopImage?.url,
                  }}
                  mb={{ base: '0', xl: '0' }}
                  clipPath={styles[slide.style]}
                ></Box>
                <Flex
                  display={{
                    base: 'flex',
                    sm: 'flex',
                    md: 'flex',
                    lg: 'none',
                    xl: 'none',
                  }}
                  flexDirection={'column'}
                  mt={{ base: '0', xl: '0' }}
                  p={['15px', '24px', 4, 14]}
                  fontSize={{
                    sm: '44px',
                    base: '30px',
                  }}
                  color={'#28231e'}
                  background="#FFFCF5"
                  lineHeight="1.11"
                  fontFamily="FedraSansStd-A-medium"
                >
                  <Box
                    as="h1"
                    pt={['0', '0px', '0', '0']}
                    fontSize={[32, 32, 44, 60, 65]}
                    fontStyle="normal"
                    fontWeight="350"
                    height={['32px', '32px', '44px', '65px']}
                    lineHeight="40px"
                    maxW={456}
                  >
                    {slide?.title}
                  </Box>
                  {slide.description && (
                    <Box
                      fontSize={{ base: '16px', md: '18px' }}
                      padding={{ base: '10px 0' }}
                      fontFamily="FedraSansStd-book"
                      color="#28231e"
                      maxWidth="456px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="22px"
                      maxW={456}
                    >
                      {StructuredTextContent(slide.description)}
                    </Box>
                  )}
                  {slide.user && (
                    <Box
                      fontSize={['16px']}
                      fontFamily="FedraSansStd-book"
                      textAlign="left"
                      fontWeight="bold"
                      color="#CF4520"
                      pt={['11px']}
                      fontStyle="normal"
                      maxW={456}
                      lineHeight="20px"
                    >
                      {slide.user}
                    </Box>
                  )}
                  {slide.userDescription && (
                    <Box
                      fontSize={['16px']}
                      fontFamily="FedraSansStd-book"
                      textAlign="left"
                      fontWeight="400"
                      color="#CF4520"
                      pt={['11px']}
                      fontStyle="normal"
                      lineHeight="20px"
                      maxW={456}
                    >
                      {slide.userDescription}
                    </Box>
                  )}
                  {slide.quote && (
                    <Box
                      fontSize={['16px', '18px', '18px', '18px']}
                      fontFamily="FedraSansStd-book"
                      lineHeight="1.44"
                      textAlign="left"
                      color="#b95c23"
                      maxW={456}
                      pt={['32px', '32px', '32px', '32px']}
                    >
                      {StructuredTextContent(slide.quote)}
                    </Box>
                  )}
                  {slide?.button && (
                    <Box
                      display="grid"
                      placeItems="center"
                      borderRadius="3px"
                      width={{ sm: '100%' }}
                      h="50px"
                      mt="30px"
                      maxW={456}
                    >
                      <SimpleButton
                        style={slide.button[0]}
                        region={region}
                        lang={language}
                      />
                    </Box>
                  )}
                </Flex>
              </Box>
            );
          })}
      </Slider>
      <Box
        pos={{ base: 'absolute', md: 'absolute' }}
        top={{ base: '500px', md: '0' }}
        left="0"
        bottom={0}
        right={{ base: '0', md: '40%' }}
        // opacity="0.1"
        display={{ base: 'none', lg: 'block' }}
        background={{
          base: 'none',
          md: 'linear-gradient(95.19deg, #FFFFFF 15.12%, #ffffff 72.03%, rgba(255, 252, 245, 0) 93.98%)',
        }}
        zIndex={10}
      >
        <Flex
          display={{
            base: 'flex',
            sm: 'flex',
            lg: 'flex',
          }}
          flexDirection={'column'}
          justifyContent={[
            'center',
            'flex-start',
            'flex-start',
            'center',
            'center',
          ]}
          h={['99%', '99%', '99%', '99%', '99%']}
          mt={{ base: '0', xl: '0' }}
          p={['15px', '24px', '15%']}
          fontSize={{
            sm: '65px',
            base: '30px',
          }}
          color="#28231e"
          lineHeight="1.11"
          fontFamily="FedraSansStd-A-medium"
        >
          <Box
            as="h1"
            pt={['0', '0', '0', '0']}
            fontSize={[32, 32, 44, 60, 65]}
            fontStyle="normal"
            fontWeight="350"
            maxW={456}
            height={['38px', '38px', '44px', '65px']}
            lineHeight="40px"
            mb="15px"
          >
            {sectionContent?.slides[textIndex]?.title}
          </Box>
          {sectionContent?.slides[textIndex].description && (
            <Box
              fontSize={{ base: '16px', md: '18px' }}
              padding={{ base: '10px 0' }}
              fontFamily="FedraSansStd-book"
              color="#28231e"
              maxWidth="456px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="22px"
            >
              {StructuredTextContent(
                sectionContent?.slides[textIndex].description
              )}
            </Box>
          )}
          {sectionContent?.slides[textIndex].user && (
            <Box
              fontSize={['16px']}
              fontFamily="FedraSansStd-book"
              textAlign="left"
              fontWeight="bold"
              color="#CF4520"
              pt={['11px']}
              maxW={456}
              fontStyle="normal"
              lineHeight="20px"
            >
              {sectionContent?.slides[textIndex].user}
            </Box>
          )}
          {sectionContent?.slides[textIndex].userDescription && (
            <Box
              fontSize={['16px']}
              fontFamily="FedraSansStd-book"
              textAlign="left"
              color="#CF4520"
              pt="5px"
              maxW={456}
              fontStyle="normal"
              fontWeight="400"
              lineHeight="20px"
            >
              {sectionContent?.slides[textIndex].userDescription}
            </Box>
          )}
          {sectionContent?.slides[textIndex]?.button?.length > 0 && (
            <Box
              placeItems="center"
              borderRadius="3px"
              width="220px"
              h="50px"
              mt="20px"
              maxW={456}
            >
              <SimpleButton
                style={sectionContent?.slides[textIndex].button[0]}
                region={region}
                lang={language}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default VolunteerSharingTopSliderGroup;
