/* Built-in imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, GridItem, Link, SimpleGrid, Tab, TabList, Tabs } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getCardBgColors, refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';

/**
 * Renders the filter Yoga Meditation Program Card container component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param {string} cardType - Card type of card component
 * @param {string} program - Program of cards
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.program
 * @param sectionContent.cardType
 * @returns {ReactElement} Filter Yoga Meditation Program Card container
 */
const FilterYogaMeditationProgramCard = ({
  sectionContent,
  region,
  lang,
  program,
  cardType,
}) => {
  const progs = program || sectionContent.programs;
  const clips = Styles.clipPaths;
  const [programs, setPrograms] = useState(progs);
  let bgColors = getCardBgColors(cardType);

  // consoleLog('progs', progs);

  const filterOut = programTag => {
    if (programTag === 'All') {
      setPrograms(progs);
    } else {
      const newProgs = progs.filter(itm => {
        const progTag = itm.programCardTag.split(', ');
        return progTag.includes(programTag);
      });
      setPrograms(newProgs);
    }
  };

  const filters = sectionContent.filterValues.split(', ');

  return <>
    {sectionContent.showFilter ? (
      <Box
        px={{ base: 4, md: 8 }}
        py={2}
        display="flex"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        alignItems="center"
        id="filter-yoga-wrap"
      >
        <Box
          as="p"
          display="flex"
          flexDirection="row"
          fontSize="18px"
          w={{ base: 'full', md: 'auto' }}
          color="#676055"
          fontFamily="FedraSansStd-book"
        >
          {sectionContent.filterName}
        </Box>
        {filters.map(item => {
          return (
            <Tabs variant='unstyled'
            // _hover={{ background: 'none' }}
            >
              <TabList>
                <Tab
                  fontFamily="FedraSansStd-book"
                  cursor="pointer"
                  fontWeight={'normal'}
                  _hover={{ bg: '#ced4da' }}
                  fontSize={'10px'}
                  padding={'6px 15px'}
                  borderRadius="10px"
                  boxShadow="none"
                  border="1px solid black"
                  className={item}
                  onClick={() => filterOut(item)}>
                  {item}
                </Tab>
              </TabList>
            </Tabs>
          );
        })}
      </Box>
    ) : null
    }
    <Box
      m="30px auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '770px',
        xl: '1170px',
      }}
    >
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: programs?.length < 3 ? 2 : 3 }}
        spacing="30px"
        mx="auto"
      >
        {/* dynamic binding */}
        {programs &&
          programs.length > 0 &&
          programs.map((card, index) => {
            return (
              <GridItem
                boxShadow="lg"
                key={nanoid()}
                display="flex"
                flexDirection="column"
                mx="auto"
                w={{
                  base: '100%',
                  sm: '330px',
                  lg: '370px',
                }}
                zIndex={index}
                position="relative"
                _hover={{ boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)' }}
              >
                <NextLink
                  href={refineUrlDomain(card.urlAlias, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    cursor={card.urlAlias ? 'cursor' : 'auto'}
                    _hover={{ textDecoration: 'none' }}
                  >
                    <LazyLoadImageComponent
                      src={card?.heroImage?.url}
                      alt={card?.heroImage?.alt}
                      title={card?.heroImage?.title}
                      objectFit="cover"
                      w="100%"
                      minH={218}
                      maxH={218}
                      clipPath={clips[index % clips.length]}               
                      bgColor="#EEEEEE"
                    />
                    <Box
                      // margin={'75px 0 0 0'}
                      w={'100%'}
                      h={'140px'}
                      objectFit="contain"
                      top={{ base: '140px', md: '170' }}
                      // transform="rotate(-178.76deg)"
                      position="absolute"
                      // left={{ base: '5%' }}
                      background={bgColors[index % bgColors.length]}
                      outline="1px solid transparent"
                      zIndex="-1"
                    ></Box>
                    <Box width="90%" mx="auto">
                      {/* <NextLink
                    href={refineUrlDomain(card.urlAlias, region, lang)}
                    passHref
                  >
                    <Link _hover={{ textDecoration: 'none' }}> */}
                      <Box
                        as="span"
                        fontFamily="FedraSansStd-A-medium"
                        fontSize="24px"
                        color="#ffffff"
                        display={'flex'}
                        alignItems="center"
                        // p={'10px 20px'}
                        fontWeight="500"
                        w={'100%'}
                        lineHeight="1.08"
                        // textAlign="center"
                        minH={'80px'}
                        textAlign="left"
                      >
                        {card.title}
                      </Box>
                      {/* </Link>
                  </NextLink> */}
                      <Box
                        fontFamily="FedraSansStd-book"
                        fontSize="16px"
                        className="ellipsis-text"
                        pt={'12px'}
                        m={'30px 0'}
                        maxHeight="82px"
                        lineHeight="normal"
                      >
                        {/* StructuredTextContent(card.summary) */}
                        {card.summary}
                      </Box>
                    </Box>
                  </Link>
                </NextLink>
              </GridItem>
            );
          })}
      </SimpleGrid>
    </Box>

    <style global jsx>
      {`
        #filter-yoga-wrap button {
          margin: 5px !important;
        }
        .ellipsis-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      `}
    </style>
  </>;
};

export default FilterYogaMeditationProgramCard;
