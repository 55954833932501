/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Masonry from 'react-masonry-css';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
// import SectionTitle from '@components/Headings/SectionTitle';

/* Services */

/**
 * Returns the Local Center Contact Card Group Button.
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Content from API
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} LocalCenterContactCardGroup component
 */
const LocalCenterContactCardGroup = ({ sectionContent, region, lang }) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    980: 2,
    768: 2,
    736: 1,
  };
  return (
    <Box p="0 0 0 20px">
      {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}> */}
      <Masonry breakpointCols={breakpointColumnsObj} gutter="10px">
        {sectionContent?.contact?.map((item, index) => (
          <Box
            key={nanoid()}
            outline="none"
            style={{ width: '100%', display: 'block', textAlign: 'left' }}
            p={'0 15px'}
            minH={{ base: '150px', md: '200px' }}
            lineHeight="1.45"
            className="local"
          >
            <Box
              as="h2"
              fontFamily="FedraSansStd-A-medium"
              fontSize="20px"
              textAlign="left"
              color="#28231e"
              fontStyle="normal"
            // fontSmoothing="antialiased"
            >
              {item.title}
            </Box>
            <style global jsx>
              {`
                    .local strong {
                      font-size: 16px;
                      line-height: 1.5;
                      text-align: left;
                      color: #28231e;
                      font-Family: "FedraSansStd-medium"
                      font-weight: 400;
                    }
                    .local a {
                      font-family:"FedraSansStd-book";
                      font-size: 16px;
                      line-height: 1.5;
                      text-align: left;
                      color: #cf4824;
                      
                    }
                    .local a:hover{
                      border-bottom: 0.5px solid #cf4824;

                    }
                    
                    .local p {
                      padding-bottom: 20px;
                      font-family:"FedraSansStd-book";
                      font-size: 16px;
                      line-height: 1.5;
                      text-align: left;
                      color: #28231e;
                      font-weight:light;
                    }
                  `}
            </style>
            <StructuredTextParser
              str={render(item.description)}
              region={region}
              lang={lang}
            />
            <Box
              as="span"
              display="inline-block"
              w="58px"
              h="3px"
              m="10px ​0 30px 0"
              borderBottom="1px solid #dfd6c4"
            ></Box>
          </Box>
        ))}
      </Masonry>
      {/* </ResponsiveMasonry> */}
    </Box>
  );
};

export default LocalCenterContactCardGroup;
