/* Built In Imports */
/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls, getLatestBuild } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText) => {
  const data = config.BUILD_URLS?.NEWS?.enabled
    ? config.BUILD_URLS.NEWS
    : await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID || process.env.env_var_others_build_id || process.env.env_var_build_id
    );
  let urlArr = filterBuildUrls('others_section', level, url, notMatchText, data);
  return urlArr ? urlArr : [];
};

export const fetchDetails = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'others',
          slug: slug ? `news/${slug}` : `news`,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchNews = async (newstype, start = 0, limit = 10, newstag) => {
  const pageData = await new IsoBackendHttpService().get(
    `/content/fetch/news/iso`,
    !isCache,
    {
      params: {
        newstype,
        start,
        limit,
        newstag
      }
    }
  );
  return pageData;
};