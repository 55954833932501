/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders Top Banner Simple Component
 *
 * @param {object} sectionContent - data from api
 * @returns Top Banner Simple Component
 */

const TopBannerSimple = ({ sectionContent }) => {
  const router = useRouter();

  const bgImages = {
    health: `${config.imgPath}/d/46272/1663638520-iso18_healthheader-image_v2.jpg`,
    'center-landing-page': `${config.imgPath}/d/46272/1663638625-centers-header.jpg`,
    'local-centers': `${config.imgPath}/d/46272/1663638715-local-centers.jpg`,
    'local-center': `${config.imgPath}/d/46272/1663638802-centers-local-city-header.jpg`,
    events: `${config.imgPath}/d/46272/1663638898-events-landing-header.jpg`,
  };

  return (
    <Box
      h="208px"
      minH="208px"
      w="100%"
      display="table"
      position="relative"
      bgColor="#e4ded4"
      background="radial-gradient(circle at 0% 0.5%, rgb(228 222 212) 0.1%, rgb(224, 226, 228) 100.2%)"
      backgroundImage={bgImages[sectionContent.backgroundImageStyle]}
      backgroundPosition="top center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      textAlign="center"
      mb="30px"
    >
      <Box
        display="table-cell"
        verticalAlign=" middle"
        as="h1"
        width="100%"
        fontSize={{
          base: router.asPath.indexOf('/program-finder') > -1 ? '28px' : '47px',
          md: router.asPath.indexOf('/program-finder') > -1 ? '44px' : '83px'
        }}
        fontFamily="FedraSerifAStdBook"
        color="#faf7f0"
      >
        {sectionContent?.title}
      </Box>
    </Box>
  );
};

export default TopBannerSimple;
