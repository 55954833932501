/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders CardResearchPublication3ColumnGroup component
 * @param {Object} sectionContent - sectionContent
 * @returns {ReactElement} CardResearchPublication3ColumnGroup Card component
 */
const CardResearchPublication3ColumnGroup = ({
  sectionContent,
  region,
  lang
}) => {
  
  const [cards, setCards] = useState([]);
  // consoleLog('cards=>', sectionContent);

  const loadMorePosts = async () => {
    let morePosts = sectionContent?.cards.slice(cards.length, cards.length + sectionContent.initialCardCount);
    if (morePosts?.length > 0) {
      setCards(prev => [...prev, ...morePosts]);
    }
  };

  useEffect(() => {
    // consoleLog('calling useEffect=>');
    loadMorePosts();
  }, []);

  return (
    <Box
      w="full"
    >
      <Box
        display="flex"
        w="full"
        flexWrap="wrap"
        gridRowGap="35px"
        gridColumnGap="30px"
        justifyContent="center"
        mx="auto"
        mb="50px"
      >
        {cards?.map((card, i) => {
          return (
            <Box maxW={{ base: '100%', md: '266px' }} key={i}>
              <Box px={4} py={5} fontFamily="FedraSansStd-A-medium">
                <Box as="h4" fontSize={{ base: '16px', md: '18px' }}>
                  {card?.title}
                </Box>
                <Box
                  as="p"
                  fontFamily="FedraSansStd-book"
                  className="truncate-text-6"
                  pt={4}
                  fontSize="16px"
                  color="#676055"
                  mb="20px"
                >
                  <StructuredTextParser
                    str={render(card.description)}
                    region={region}
                    lang={lang}
                  />
                </Box>
                <NextLink
                  passHref
                  href={refineUrlDomain(card?.buttonLink, region, lang)}
                  legacyBehavior>
                  <Link
                    variant={'outline'}
                    display="block"
                    fontWeight="600"
                    w="fit-content"
                    px="15px"
                    py="5px"
                    border="1px solid #BB3E03"
                    borderRadius="3px"
                    color="#BB3E03"
                    fontSize="16px"
                    fontFamily="FedraSansStd-book"
                    textAlign="center"
                    mb="15px"
                    title="View more"
                    rel="next"
                    _hover={{
                      textDecoration: 'none',
                      bgColor: '#cf4520',
                      color: 'white',
                    }}
                    target={
                      card.buttonLink.indexOf('http') > -1 &&
                        card.buttonLink.indexOf(config.cdnPath) < 0
                        ? '_blank'
                        : ''
                    }
                  >
                    {card?.buttonText}
                  </Link>
                </NextLink>
              </Box>
            </Box>
          );
        })}      
      </Box>
      {sectionContent.showLoadmore && cards.length < sectionContent?.cards.length && (
        <Link
          onClick={loadMorePosts}
          variant={'outline'}
          display="block"
          width="164px"
          p="10px 23px"
          fontWeight="500"
          border="2px solid #cf4520"
          borderRadius="5px"
          color="#fff"
          fontSize="16px"
          fontFamily="FedraSansStd-medium"
          textAlign="center"
          m="0 auto"
          mb="5px"
          background="#D04723"
          _hover={{
            textDecoration: 'none',
            background: '#000422',
            color: '#fff',
            border: '2px solid transparent',
          }}
          title="Load More"
          rel="next"
        >
          Load More
        </Link>
      )}
    </Box>
  );
};

export default CardResearchPublication3ColumnGroup;
