/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextContent from '@components/UI/StructuredTextContent';

/* Services */

/**
 * Renders Top Banner V2 Component
 *
 * @param {object} sectionContent - Data from api
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @returns
 */

const TopBannerV2 = ({ sectionContent, region, language }) => {
  // consoleLog(sectionContent);
  const styles = {
    default: 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
    'without-bottom-angle': '',
    '': 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
  };

  //condition for ta lang
  let heig = '';
  if(language == 'ta'){
    heig = 'none';
  }
  else{
    heig = '50px';
  }

  return (
    <Box
      className="topBannerV2"
      display={'grid'}
      pos={'relative'}
      bgColor={{
        base: '#f8f5f0;',
        lg: 'transparent',
      }}
    >
      <Box height={'auto'} w={'100%'}>
        {/* <LazyLoadComponent id={'rrr'}> */}
        <Box
          className='bannerimge'
          h={{
            base: '105.9375vw',
            sm: '500px',
            md: '105.9375vw',
            lg: '500px'
          }}
          minH={{
            base: '105.9375vw',
            sm: '500px',
            md: '105.9375vw',
            lg: '500px'
          }}
          bgColor={{
            base: '#f8f5f0;',
            sm: 'transparent',
          }}
          background="radial-gradient(circle at 0% 0.5%, rgb(228 222 212) 0.1%, rgb(224, 226, 228) 100.2%)"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition={{
            base: '45%',
            md:'45%',
            sm: '45%',
            lg: 'top center',
            xl: '0',
          }}
          backgroundImage={{
            lg: sectionContent?.desktopImage?.url,
            md: sectionContent?.mobileImage?.url,
            base:
              sectionContent?.mobileImage?.url ||
              sectionContent?.desktopImage?.url,
          }}
          mb={{ base: '0', xl: '0' }}
          clipPath={styles[sectionContent.style]}
        >
          <Flex
            display={{
              base: 'none',
              sm: 'none',
              lg: 'flex',
            }}
            w={{ base: '100%', md: '50%' }}
            flexDirection={'column'}
            justifyContent={[
              'center',
              'flex-start',
              'flex-start',
              'center',
              'center',
            ]}
            h={['100%', '100%', '100%', '100%', '100%']}
            mt={{ base: '0', xl: '0' }}
            p={['15px', '24px', 4, 14]}
            pr="0 !important"
            maxW="476px"
            fontSize={{
              sm: '44px',
              base: '30px',
            }}
            color={
              sectionContent.style === 'dark-background'
                ? 'white'
                : sectionContent.style === 'red-title-text'
                  ? '#E1434D'
                  : '#28231e'
            }
            lineHeight="1.11"
            fontFamily="FedraSansStd-A-medium"
          >
            <Box
              as="h1"
              pt={['0', '40px', '44px', '0']}
              fontSize={[0, 32, 44, 44]}
            >
              {sectionContent?.title}
            </Box>

            {render(sectionContent?.description) &&
              render(sectionContent?.description) !== '<p></p>' && (
                <Box
                  fontSize={['16px', '16px', '16px', '27px']}
                  padding={{ base: '8vw 1vw 2vw 0', md: '2vw 0 0' }}
                  fontFamily="FedraSansStd-book"
                  color={
                    sectionContent.style === 'dark-background'
                      ? 'white'
                      : sectionContent.style === 'red-title-text'
                        ? '#E1434D'
                        : '#28231e'
                  }
                >
                  {StructuredTextContent(sectionContent.description)}
                </Box>
              )}

            {render(sectionContent?.quote) &&
              render(sectionContent?.quote) !== '<p></p>' && (
              <Box
                fontSize={['16px', '16px', '16px', '18px']}
                fontFamily="FedraSansStd-book"
                lineHeight="1.44"
                textAlign="left"
                // color="#b95c23"
                color={
                  sectionContent.style === 'dark-background'
                    ? 'white'
                    : sectionContent.style === 'red-title-text'
                      ? '#E1434D'
                      : '#b95c23'
                }
                pt={['32px', '20px', '32px', '32px']}
              >
                {StructuredTextContent(sectionContent.quote)}
              </Box>
              )}
            
            {sectionContent?.button?.length > 0 && (
              <Box
                // display={'grid'}
                placeItems="center"
                borderRadius="3px"
                width="220px"
                h={heig}
                mt="20px"
              >
                <SimpleButton
                  style={sectionContent.button[0]}
                  region={region}
                  lang={language}
                />
                {/* <Button borderRightRadius="0">Button 1</Button> */}
              </Box>
            )}
          </Flex>
        </Box>
        {/* </LazyLoadComponent> */}
        <Flex
          display={{
            base: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'none',
            xl: 'none',
          }}
          flexDirection={'column'}
          mt={{ base: '0', xl: '0' }}
          p={['32px', '24px', 4, 14]}
          fontSize={{
            sm: '44px',
            base: '30px',
          }}
          color={'#28231e'}
          lineHeight="1.11"
          fontFamily="FedraSansStd-A-medium"
        >
          <Box as="h1" pt={['0', '0', '0', '0']}>
            {sectionContent?.title}
          </Box>
          {render(sectionContent?.description) &&
              render(sectionContent?.description) !== '<p></p>' && (
            <Box
              fontSize={{ base: '16px', md: '27px' }}
              padding={{ base: '8vw 1vw 2vw 0', md: '2vw 0 0' }}
              fontFamily="FedraSansStd-book"
            >
              {StructuredTextContent(sectionContent.description)}
            </Box>
          )}
          {render(sectionContent?.quote) &&
              render(sectionContent?.quote) !== '<p></p>' && (
            <Box
              fontSize={['16px', '18px', '18px', '18px']}
              fontFamily="FedraSansStd-book"
              lineHeight="1.44"
              textAlign="left"
              color="#b95c23"
              pt={['32px', '32px', '32px', '32px']}
            >
              {StructuredTextContent(sectionContent.quote)}
            </Box>
          )}
          {sectionContent?.button.length > 0 && (
            <Box
              display="grid"
              placeItems="baseline"
              borderRadius="3px"
              width={{ sm: '200px' }}
              h="50px"
              mt="30px"
            >
              <SimpleButton
                style={sectionContent.button[0]}
                region={region}
                lang={language}
              />
            </Box>
          )}
        </Flex>
      </Box>
      <style>
        {`
      @media (min-width: 824px) and (max-width: 1024px) { 
        .bannerimge {
          background-position: 12%;
          height: 500px !important;
          min-height: 500px !important;  
        }
      }
      `}
    </style>
    </Box>
  );
};

export default TopBannerV2;
