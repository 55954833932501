/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Card Circular 2 Column Group Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Current User Language.
 * @param {string} region - Current User Country.
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} CardCircular2ColumnGroup Card component
 */
const CardCircular2ColumnGroup = ({ sectionContent, region, lang }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gridGap={10}
      justifyContent="center"
      textAlign="center"
      my={4}
    >
      {sectionContent?.cards?.map((section, index) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            key={nanoid()}
            maxWidth={{ base: 302, md: 330 }}
          >
            <Box
              mb={{ base: 4, md: 6 }}
              minH={{ base: '188px', sm: '148px', md: '270px' }}
            >
              {' '}
              <LazyLoadImageComponent
                src={section.thumbnail?.url}
                title={section.thumbnail?.title}
                alt={section.thumbnail?.alt}
                maxWidth={{ base: 190, md: 270 }}
              />
            </Box>

            <Box
              as="h2"
              fontFamily="FedraSansStd-A-medium"
              fontSize={{ base: '18px', md: '24px' }}
              color="#28231e"
              mb={4}
            >
              {section.title}
            </Box>
            <Box
              as="p"
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              color="#28231e"
            >
              <StructuredTextParser
                str={render(section.description)}
                region={region}
                lang={lang}
                textAlign="center"
                mt="0"
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CardCircular2ColumnGroup;
