/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Components */
//Components
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';

//Services
import { getCurrentRegion } from 'services/commonService';

/**
 * Renders the Ishanga Benefits Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Ishanga Benefits Card component
 */
const IshangaBenefits = ({
  sectionContent,
  region,
  lang,
  section,
  pageBody
}) => {
  const [cards, setCards] = useState(sectionContent?.cards);
  const [isVis, setIsVis] = useState(false);
  const [currRegion, setCurrRegion] = useState(null);

  const checkBlockedCountry = currRegion => {
    // currRegion = 'AT';
    // consoleLog('currRegion 11', pageBody[pageBody.length - 1]);
    let countryData = pageBody[pageBody.length - 1]?.sectionContent[0]?.data[0];
    setIsVis(Object.values(countryData).indexOf(currRegion) < 0);
  };

  useEffect(() => {
    if (currRegion) {
      // consoleLog('currRegion 11', currRegion);
      checkBlockedCountry(currRegion);
    }
  }, [currRegion]);

  (async () => {
    if (region !== 'us' && region !== 'ca') {
      let reg = await getCurrentRegion();
      setCurrRegion(reg?.country);
    } else {
      setIsVis(true);
    }
  })();

  if (isVis) {

    return (
      <Box mb="50px" h="100%">
        <Box
          mx="auto"
          maxW={{
            base: '302px',
            sm: '480px',
            md: '690px',
            lg: '770px',
            xl: '1200px',
          }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, xl: cards?.length > 2 ? 3 : 2 }}
            mx="auto"
            gap="15px"
          >
            {cards?.length &&
              cards.map((secCon, index) => {
                return (
                  <Box
                    marginBottom="2%"
                    display="flex"
                    flexDirection="column"
                    mx="auto"
                    w={{
                      base: '100%',
                      sm: '330px',
                      lg: '370px',
                    }}
                    key={nanoid()}
                    position="relative"
                  // backgroundColor="#F5F0E5"
                  >
                    <NextLink
                      href={refineUrlDomain(secCon.buttonLink, region, lang)}
                      passHref
                      legacyBehavior>
                      <LazyLoadImageComponent
                        src={secCon?.thumbnail?.url}
                        alt={secCon?.thumbnail?.alt}
                        title={secCon?.thumbnail?.title}
                        width={'100%'}
                        objectFit="cover"
                        h="100%"
                      />
                    </NextLink>
                    <Box>
                      <Box
                        as="h2"
                        fontSize={'22px'}
                        color="#28231e"
                        lineHeight="1.11"
                        textAlign={'center'}
                        marginTop="20px"
                        marginBottom="10px"
                        fontFamily="FedraSansStd-A-medium"
                      >
                        {secCon.title}
                      </Box>
                      <Box
                        // className="des event-text"
                        fontStyle="normal"
                        fontWeight={400}
                        fontSize={{ base: '18px', md: '24px' }}
                        lineHeight={{ base: '28px', md: '34px' }}
                        textAlign="center"
                        color="#28231E"
                        className="benefits-des"
                        fontFamily="FedraSansStd-book"
                      >
                        <StructuredTextParser
                          str={render(secCon.description)}
                          region={region}
                          lang={lang}
                          textAlign="center"
                          color="#28231E"
                          fontSize={{ base: '18px', md: '24px' }}
                          lineHeight={{ base: '28px', md: '34px' }}
                          fontFamily="FedraSansStd-book"
                        />
                        {/* {StructuredTextContent(secCon.description)} */}
                      </Box>
                      <style>
                        {`
                        .benefits-des a{
                          
                            color: #cf4824;
    word-break: break-word;
    border-bottom: 2px solid #cf4824;
    transition: background-size 0.125s ease-in;
    background-image: linear-gradient(120deg, #cf4824, #cf4824);
    background-repeat: no-repeat;
    background-size: 100% 0;
    background-position: 0 100%;
                          
                        }
                        .benefits-des a:hover{
                          background-color: #cf4824;
    color: white;
    background-image: linear-gradient(120deg, #cf4824, #cf4824);
    background-size: 100% 100%;
    transition: all 0.3s ease-in-out;
                        }
                        `}
                      </style>
                    </Box>
                  </Box>
                );
              })}
          </SimpleGrid>
        </Box>
      </Box>
    );
  }

  if (!isVis) {

    return (
      <Box mb="50px" h="100%">
        <Box
          mx="auto"
          maxW={{
            base: '302px',
            sm: '480px',
            md: '690px',
            lg: '770px',
            xl: '1200px',
          }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, xl: 2 }}
            mx="auto"
            gap="15px"
          >
            {cards?.length &&
              cards.map((secCon, index) => {
                return index < 2 && (
                  <Box
                    marginBottom="2%"
                    display="flex"
                    flexDirection="column"
                    mx="auto"
                    w={{
                      base: '100%',
                      sm: '330px',
                      lg: '370px',
                    }}
                    key={nanoid()}
                    position="relative"
                  // backgroundColor="#F5F0E5"
                  >
                    <NextLink
                      href={refineUrlDomain(secCon.buttonLink, region, lang)}
                      passHref
                      legacyBehavior>
                      <LazyLoadImageComponent
                        src={secCon?.thumbnail?.url}
                        alt={secCon?.thumbnail?.alt}
                        title={secCon?.thumbnail?.title}
                        width={'100%'}
                        objectFit="cover"
                        h="100%"
                      />
                    </NextLink>
                    <Box>
                      <Box
                        as="h2"
                        fontSize={'22px'}
                        color="#28231e"
                        lineHeight="1.11"
                        textAlign={'center'}
                        marginTop="20px"
                        marginBottom="10px"
                        fontFamily="FedraSansStd-A-medium"
                      >
                        {secCon.title}
                      </Box>
                      <Box
                        // className="des event-text"
                        fontStyle="normal"
                        fontWeight={400}
                        fontSize={{ base: '18px', md: '24px' }}
                        lineHeight={{ base: '28px', md: '34px' }}
                        textAlign="center"
                        color="#28231E"
                        className="benefits-des"
                        fontFamily="FedraSansStd-book"
                      >
                        <StructuredTextParser
                          str={render(secCon.description)}
                          region={region}
                          lang={lang}
                          textAlign="center"
                          color="#28231E"
                          fontSize={{ base: '18px', md: '24px' }}
                          lineHeight={{ base: '28px', md: '34px' }}
                          fontFamily="FedraSansStd-book"
                        />
                        {/* {StructuredTextContent(secCon.description)} */}
                      </Box>
                      <style>
                        {`
                        .benefits-des a{
                          
                            color: #cf4824;
    word-break: break-word;
    border-bottom: 2px solid #cf4824;
    transition: background-size 0.125s ease-in;
    background-image: linear-gradient(120deg, #cf4824, #cf4824);
    background-repeat: no-repeat;
    background-size: 100% 0;
    background-position: 0 100%;
                          
                        }
                        .benefits-des a:hover{
                          background-color: #cf4824;
    color: white;
    background-image: linear-gradient(120deg, #cf4824, #cf4824);
    background-size: 100% 100%;
    transition: all 0.3s ease-in-out;
                        }
                        `}
                      </style>
                    </Box>
                  </Box>
                );
              })}
          </SimpleGrid>
        </Box>
      </Box>
    );
  }
};

export default IshangaBenefits;
