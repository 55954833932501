/* Built In Imports */
/* External Imports */
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */
/* Styles */

/**
 * Renders Json Table UI Component
 * @param {Object} sectionContent - Data from api
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Json Table UI component.
 */
const JsonTableUI = ({ sectionContent, region, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <MidContainer maxW={'768'}>
      <>
        <Table variant="whiteAlpha" className="JsonTable">
          <Tbody fontFamily="FedraSansStd-book">
            {sectionContent &&
              sectionContent.data &&
              Object.keys(sectionContent.data)?.map((item, index) => {
                if (index === 0 && isMobile) {
                  return <></>;
                } 
                return (
                  <Tr className={index}
                    border="1px solid rgb(221 221 221)"
                    p="0.35em"
                    fontSize="18px"
                    fontWeight={index > 0 ? '' : '600'}
                  >
                    <Td>{parser(item)}</Td>
                    <Td borderLeft="1px solid rgb(221 221 221)">
                      {parser(sectionContent?.data[item])}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </>
      <style global jsx>
        {`
            @media (max-width: 767px) {
              .JsonTable td {
                padding-left: 0 !important;
              }
              .JsonTable td:nth-child(2) {
                border-left: 0px;
              }
              table.JsonTable>tbody>tr:nth-child(1) {
                display: block;
              }
              table.JsonTable td { width: 50%; display: inline-block;}
            }
        `}
      </style>
    </MidContainer>
  );
};

export default JsonTableUI;
