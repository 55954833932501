/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parse from 'html-react-parser';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
// import useDynamicRefs from 'use-dynamic-refs';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { ShortCodeProcess } from '@components/Utility/ArticleParser/ShortCode';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import useDynamicRefs from '@hooks/useDynamicRefs';

/* Services */

/**
 * Renders the Card Donation Group Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param {string} query — the media query to match
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} Card Donation Group Card Component
 */
const CardDonationGroup = ({ sectionContent, region, lang }) => {
  const [getRef, setRef] = useDynamicRefs();
  const isLessThen = useMediaQuery({ minWidth: 990 });

  const toggleLink = (id, button) => {
    // consoleLog('toggleLink4', id, document.getElementById(button).innerHTML);
    let currentElem = getRef(id)?.current;
    if (currentElem?.className.indexOf('truncate-text-4') > -1) {
      currentElem?.classList.remove('truncate-text-4');
      document.getElementById(button).innerHTML = 'Read Less';
    } else {
      currentElem?.classList.add('truncate-text-4');
      document.getElementById(button).innerHTML = 'Read More...';
    }
  };

  const ctaButton = (buttonText, buttonLink) => {
    let buttonUrl = '';
    buttonUrl = ShortCodeProcess(buttonLink.toString());
    buttonUrl = parse(buttonUrl);
    return (
      <NextLink
        href={refineUrlDomain(buttonUrl, region, lang)}
        passHref
        legacyBehavior
      >
        <Link
          mb={{ md: '20px', base: '16px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontWeight={500}
          fontFamily="FedraSansStd-medium"
          fontSize="16px"
          bgColor="orange.50"
          textAlign="center"
          padding="10px"
          borderRadius="3px"
          color="white"
          textDecoration="none"
          _hover={{
            bg: 'blue.50',
          }}
          cursor="pointer"
          height={{ base: '46px', md: '56px' }}
        >
          {buttonText}
        </Link>
      </NextLink>
    );
  };

  return (
    <>
      {sectionContent.cards.map((card, index) => {
        return (
          <Box
            as="article"
            display="flex"
            bgColor="#F5F5F5"
            borderRadius="5px"
            mb="30px"
            key={nanoid()}
            flexDir={{ base: 'column', lg: 'row' }}
            maxW={{
              base: '320px',
              sm: '480px',
              md: '690px',
              lg: '770px',
              xl: '1200px',
            }}
            w="full"
            h="full"
            mx="auto"
            _last={{
              mb: { base: '10', lg: '70' },
            }}
          >
            <Box maxW={{ base: '100%', lg: '260px', xl: '260px' }} w="100%">
              <Box
                bgImage={{
                  base: card.mobileThumbnail?.url,
                  lg: card.thumbnail?.url,
                }}
                bgRepeat="no-repeat"
                bgSize="cover"
                borderRadius={{
                  base: '8px 8px 0px 0px',
                  lg: '8px 0px 0px 8px',
                }}
                w="full"
                h={{ base: '221', md: '318', lg: 'full' }}
                pos="relative"
              >
                {card.isFeatured && (
                  <Box
                    pos="absolute"
                    bgColor="#CF4520"
                    color="#fff"
                    top="10px"
                    fontSize="16px"
                    p="0px 4px 0px 10px"
                    fontFamily="FedraSansStd-book"
                    _before={{
                      content: '" "',
                      height: 0,
                      width: 0,
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: '-12px',
                      borderTop: '12px solid #CF4520',
                      borderBottom: '12px solid #CF4520',
                      borderRight: '12px solid transparent',
                      borderLeft: '12px solid transparent',
                    }}
                  >
                    Featured
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDir={{ base: 'column', md: 'row' }}
              gridGap={{ base: '30px', md: '80px' }}
              width={{ base: '100%', md: 'full' }}
              p={{
                base: '24px 15px 12px 15px',
                sm: '27px 30px',
                lg: '27px 48px',
              }}
            >
              <Box
                w={{ base: '100%', lg: '70%' }}
                // p={{ md: '27px 36px 26px 48px', base: '24px 0 24px 0' }}
              >
                <Box
                  as="h2"
                  fontFamily="FedraSansStd-A-medium"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize={{ base: '22px', md: '28px' }}
                  lineHeight={{ base: '26px', md: '36px' }}
                  pb="15px"
                >
                  {card.title}
                </Box>
                <Box
                  as="p"
                  lineHeight="26px"
                  fontFamily="FedraSansStd-book"
                  ref={setRef(index + 1)}
                  id={index + 1}
                  className={
                    isLessThen ? 'event-text' : 'event-text truncate-text-4'
                  }
                >
                  <StructuredTextParser
                    str={render(card?.description)}
                    region={region}
                    lang={lang}
                    mt="0"
                    fontSize="16px"
                  />
                </Box>
                {!isLessThen && (
                  <Box mt={2}>
                    <Link
                      to="/"
                      color="#cf4520"
                      onClick={() => toggleLink(index + 1, `btn-${index + 1}`)}
                      fontFamily="FedraSansStd-book"
                      fontSize="16px"
                      id={`btn-${index + 1}`}
                      _hover={{
                        textDecoration: 'none',
                      }}
                    >
                      Read More...
                    </Link>
                  </Box>
                )}
              </Box>
              <Box
                w={{ base: '100%', lg: '46%' }}
                pt={{ base: '0', sm: '10px' }}
              >
                {card.buttonDescription && (
                  <Box
                    fontSize="16px"
                    fontFamily="FedraSansStd-book"
                    mb="2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridGap="6px"
                  >
                    <LazyLoadImageComponent
                      src={card?.buttonIcon?.url}
                      title={card?.buttonIcon?.title}
                      alt={card?.buttonIcon?.alt}
                    />
                    {card.buttonDescription}
                  </Box>
                )}
                {card.buttonText && (
                  // <NextLink
                  //   href={refineUrlDomain(card?.buttonLink, region, lang)}
                  //   passHref
                  // >
                  //   <Link
                  //     mb={{ md: '20px', base: '16px' }}
                  //     display="flex"
                  //     justifyContent="center"
                  //     alignItems="center"
                  //     fontWeight={500}
                  //     fontFamily="FedraSansStd-medium"
                  //     fontSize="16px"
                  //     bgColor="orange.50"
                  //     textAlign="center"
                  //     padding="10px"
                  //     borderRadius="3px"
                  //     color="white"
                  //     textDecoration="none"
                  //     _hover={{
                  //       bg: 'blue.50',
                  //     }}
                  //     height={{ base: '46px', md: '56px' }}
                  //   >
                  //     {card.buttonText}
                  //   </Link>
                  // </NextLink>
                  <Box>{ctaButton(card?.buttonText, card?.buttonLink)}</Box>
                )}

                {card.secondCtaDescription && (
                  <Box
                    fontSize="16px"
                    fontFamily="FedraSansStd-book"
                    mb="2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridGap="6px"
                  >
                    <LazyLoadImageComponent
                      src={card?.secondCtaIcon?.url}
                      title={card?.secondCtaIcon?.title}
                      alt={card?.secondCtaIcon?.alt}
                    />
                    {card.secondCtaDescription}
                  </Box>
                )}
                {card.secondCtaLabel && (
                  // <NextLink
                  //   href={refineUrlDomain(card?.secondCtaLink, region, lang)}
                  //   passHref
                  // >
                  //   <Link
                  //     mb="20px"
                  //     display="flex"
                  //     justifyContent="center"
                  //     alignItems="center"
                  //     fontWeight={500}
                  //     fontFamily="FedraSansStd-medium"
                  //     fontSize="16px"
                  //     bgColor="orange.50"
                  //     textAlign="center"
                  //     padding="10px"
                  //     borderRadius="3px"
                  //     color="white"
                  //     textDecoration="none"
                  //     _hover={{
                  //       bg: 'blue.50',
                  //     }}
                  //     height={{ base: '46px', md: '56px' }}
                  //   >
                  //     {card?.secondCtaLabel}
                  //   </Link>
                  // </NextLink>
                  <Box>
                    {ctaButton(card?.secondCtaLabel, card?.secondCtaLink)}
                  </Box>
                )}

                {card.thirdCtaDescription && (
                  <Box
                    fontSize="16px"
                    fontFamily="FedraSansStd-book"
                    mb="2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridGap="6px"
                  >
                    <LazyLoadImageComponent
                      src={card?.thirdCtaIcon?.url}
                      title={card?.thirdCtaIcon?.title}
                      alt={card?.thirdCtaIcon?.alt}
                    />
                    {card.thirdCtaDescription}
                  </Box>
                )}
                {card.thirdCtaLabel && (
                  // <NextLink
                  //   href={refineUrlDomain(card?.thirdCtaLink, region, lang)}
                  //   passHref
                  // >
                  //   <Link
                  //     mb={{ base: '0', lg: '40px' }}
                  //     display="flex"
                  //     justifyContent="center"
                  //     alignItems="center"
                  //     fontWeight={500}
                  //     fontFamily="FedraSansStd-medium"
                  //     fontSize="16px"
                  //     bgColor="orange.50"
                  //     textAlign="center"
                  //     padding="10px"
                  //     borderRadius="3px"
                  //     color="white"
                  //     textDecoration="none"
                  //     _hover={{
                  //       bg: 'blue.50',
                  //     }}
                  //     height={{ base: '46px', md: '56px' }}
                  //   >
                  //     {card?.thirdCtaLabel}
                  //   </Link>
                  // </NextLink>
                  <Box>
                    {ctaButton(card?.thirdCtaLabel, card?.thirdCtaLink)}
                  </Box>
                )}

                {card.fourthCtaDescription && (
                  <Box
                    fontSize="16px"
                    fontFamily="FedraSansStd-book"
                    mb="2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridGap="6px"
                  >
                    <LazyLoadImageComponent
                      src={card?.fourthCtaIcon?.url}
                      title={card?.fourthCtaIcon?.title}
                      alt={card?.fourthCtaIcon?.alt}
                    />
                    {card.fourthCtaDescription}
                  </Box>
                )}
                {card.fourthCtaLabel && (
                  <Box>
                    {ctaButton(card?.fourthCtaLabel, card?.fourthCtaLink)}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default CardDonationGroup;
