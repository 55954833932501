/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import MidContainer from '@Layout/MidContainer';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/* Services */

/**
 * Renders the In The News Slider component.
 * @param {Object} news component.
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @returns {ReactElement} In The News Slider component.
 */
const InTheNewsSlider = ({ sectionContent, region, lang }) => {
  // consoleLog('sectionContent', sectionContent);
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="25%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="-10px"
        onClick={onClick}
      ></Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="25%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left="-10px"
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <MidContainer>
      <SectionTitle titleObj={sectionContent} />
      <Box
        maxW="100%"
        m="0 auto"
        w="100%"
        overflow="hidden"
        p="0 15px 30px 15px"
      >
        <Slider {...settings} m="0">
          {sectionContent?.news?.map((news, i) => {
            return (
              <Box
                overflow="hidden"
                p="0 10%"
                key={nanoid()}
                textAlign="center"
                /* A placeholder for the title of the news item. */
                fontFamily="FedraSansStd-book"
                maxW="900px"
              >
                <Box
                  as="h2"
                  fontFamily="Fira Sans, sans-serif"
                  color="rgb(3 80 150) !important"
                  fontWeight="700"
                  fontSize="30px"
                  lineHeight="33px"
                  textAlign="left"
                >
                  <NextLink
                    href={refineUrlDomain(news?.ctaUrl, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link
                      // fontFamily="FedraSansStd-book"
                      _hover={{
                        textDecoration: 'none'
                      }}
                    >
                      {news.title}
                    </Link>
                  </NextLink>
                </Box>
                <Box
                  textAlign="left"
                  padding="0 0 15px 0"
                  fontWeight="bold"
                  borderBottom="2px solid rgb(188 170 170)"
                  marginBottom="1.5em"
                  lineHeight="1.25"
                  marginTop="15px"
                >
                  {news.newsSource ? `${news.newsSource} -` : ''} {news.newsPublishedDate}
                </Box>
                {/* <ContentArea
                  font="16px"
                  mainContent={news?.body}
                  region={region}
                  lang={lang}
                  tAlign="left"
                /> */}
                <Box
                  fontFamily="FedraSansStd-book"
                  font="16px"
                  textAlign="left"
                  color="#28231e"
                >
                  {StructuredTextContent(news.body)}
                </Box>
                
                <Box
                  textAlign="right"
                  paddingLeft="10px"
                  fontWeight="bold"
                >
                  <NextLink
                    href={refineUrlDomain(news?.ctaUrl, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link
                      color="#cf4824"
                      fontFamily="FedraSansStd-book"
                      _hover={{
                        textDecoration: 'none'
                      }}
                      fontWeight="100"
                    >
                      Continue reading
                    </Link>
                  </NextLink>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </MidContainer>
  );
};

export default InTheNewsSlider;
