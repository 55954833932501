/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the YogaCommonUI component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SingleLineText Component.
 */

const YsBenefitsTitle = ({
  sectionContent,
  fontStyle,
  lineHeight,
  fontWeight,
  color,
  pt,
  ...props
}) => {
  return (
    <MidContainer>
      <Box maxW="100%" my="0">
        <Box
          fontFamily="FedraSansStd-A-medium, serif"
          width="100%"
          fontSize={{ base: '27px', lg: '30px' }}
          color={color ? color : '#FFF'}
          lineHeight={lineHeight || "32px"}
          fontStyle={fontStyle || "normal"}
          fontWeight={fontWeight || 500}
          pt={{ md: pt ? pt : '45px', base: '35px' }}
        >
          <Box textAlign='center'>
            {sectionContent?.text}
          </Box>
        </Box>
      </Box>
    </MidContainer>
  );
};

export default YsBenefitsTitle;
