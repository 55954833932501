/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
/* Services */

/**
 * Renders the UpcomingEventsCelebrationsLargeCard Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} UpcomingEventsCelebrationsLargeCard Card component
 */

const UpcomingEventsCelebrationsLargeCard = ({
  sectionContent,
  region,
  lang,
}) => {
  return <>
    <Box w="100%" px="30px">
      <Box
        minH={'448px'}
        //backgroundImage={`url('${config.imgPath}/sites/default/files/2019-09/20140925_VAR_0165-e_3_1_0_0.jpg`}
        bgImage={`url('${sectionContent.desktopImage?.url}')`}
        backgroundSize={'cover'}
        boxShadow="0 28px 25px -5px rgb(0 0 0 / 50%)"
      >
        <Flex
          flexDir={'column'}
          w={{ xl: '45%', lg: '45%', md: '45%', sm: '95%' }}
          pl="30px"
        >
          <Box
            as="h2"
            fontSize="36px"
            lineHeight="1.08"
            textAlign="left"
            color="#ffd182"
            mt="8%"
          >
            {sectionContent.title}
          </Box>
          <Box
            as="h2"
            fontSize="28px"
            lineHeight="1.08"
            textAlign="left"
            color="#c6c6c6"
            mt="1%"
          >
            {sectionContent.date}
          </Box>
          <Box
            as="h3"
            fontSize="16px"
            lineHeight="1.5"
            textAlign="left"
            color="#eae3d4"
            mt="5%"
            mb="2%"
            fontFamily="FedraSansStd-book"
          >
            <StructuredTextParser
              str={render(sectionContent.description)}
              region={region}
              lang={lang}
            />
          </Box>
          {sectionContent.cta?.length && (
            <Box mt="5%" textAlign="center">
              {sectionContent.cta?.length && (
                <Box mt="5%" textAlign="center">
                  <NextLink
                    href={refineUrlDomain(
                      sectionContent.cta[0]?.linkUrl,
                      region,
                      lang
                    )}
                    passHref
                    legacyBehavior>
                    <Link
                      width="170px"
                      mb="40px"
                      display="block"
                      fontWeight={500}
                      fontFamily="FedraSansStd-medium"
                      fontSize={'13px'}
                      bgColor="#cf4824"
                      h={'auto'}
                      textAlign="center"
                      padding="10px 36px"
                      borderRadius="3px"
                      color="white"
                      textDecoration="none"
                      _hover={{
                        bg: '#000054',
                        color: 'white',
                      }}
                      target="_blank"
                    >
                      {sectionContent.cta[0]?.buttonText}
                    </Link>
                  </NextLink>
                </Box>
              )}
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  </>;
};

export default UpcomingEventsCelebrationsLargeCard;
