/* Built In Imports */

/* External Imports */ import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */
//! to be moved to a organised place
const BenefitsFourColumn = ({ sectionContent }) => {
  return (
    <Box
      as="div"
      fontSize="18px"
      m="0 auto"
      width={{ base: '100%', sm: '100%', md: '737px', lg: '933px' }}
      mb={{ md: '0', sm: '30.78px' }}
    >
      <Box
        as="div"
        display="flex"
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        margin="40px auto 0 auto"
        fontSize="18px"
        gap="10px"
        width={{ base: '100%', md: '737px', lg: '933px' }}
      >
        {sectionContent?.benefits?.map((data, indx) => {
          return (
            <Box
              key={`BenefitsFourColumn:${indx}`}
              maxW="274px"
              maxH="274px"
              w="100%"
              h="100%"
              flex={{
                base: '100%',
                md: '30%',
              }}
              display="block"
              ml={{ base: '17px', md: '26px' }}
              mb={{ base: '40px', lg: '53px', md: '60px' }}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                // as="div"
                //  minWidth={{ base: '37.17px', md: '100%', lg: '100%' }}
                //  maxW={{ base: '37.17px', md: '100%', lg: '100%' }}
                //  mr="17.93px"
                textAlign="center"
                // maxH={{ md: '100px', lg: '100px' }}
                maxw={{ md: '186px', base: '170px' }}
                maxH={{ md: '228px', base: '199' }}
                gap={{ md: '18px', base: '15px' }}
              >
                <LazyLoadImageComponent
                  loading="lazy"
                  src={data?.image?.url}
                  title={data?.image?.title}
                  /* height={{
                                         base: '44px',
                                         md: '80px',
                                     }}*/
                  //   minW={'31px'}
                  w={{ md: '174px', base: '150px' }}
                  h={{ md: '161.8px', base: '139px' }}
                  objectFit="cover"
                  // m={{ base: '0 0 22px 0' }}
                />
              </Flex>
              <Text
                fontFamily="FedraSansStd-medium, sans-serif"
                fontSize={{
                  base: '16px',
                  md: '18px',
                }}
                fontWeight="500"
                lineHeight={{ md: '24.4px', base: '21.7px' }}
                color="#28231E"
                mx={{ base: 'auto' }}
                m={{ lg: '0 auto 15px' }}
                textAlign="center"
                pt={{ base: '10px', sm: '10px', md: '5' }}
                maxW={{  base: '170px',md: '192px', }}
              >
                {data?.text}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BenefitsFourColumn;
