/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Flex, List } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import { NextArrow, PrevArrow } from '@components/Sliders/TestimonialYs/Icons';
import TestimonialCard from '@components/Sliders/TestimonialYs/TestimonialCard';
//styles
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

/**
 * Renders the Testimonial component.
 *
 * @returns {ReactElement} Testimonial component.
 */

const TestimonialYs = ({ sectionContent }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "Yoga-SandhyaTestimonial",
    // centerMode: true,
    // centerPadding: '0px 40px',
    appendDots: dots => (
      <Box h="1px">
        <List margin='0px'> {dots} </List>
      </Box>
    ),
    customPaging: () => (
      <Box
        background={'#D2C8B5'}
        width="12px"
        rounded="full"
        height="12px"
      ></Box>
    ),
    afterChange: (index) => setImgIndex(index),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1065,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '0px 40px',

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '0px 40px',

        }
      }
    ],
  };

  return (
    <Box
      maxW={1100}
      w="100%"
      mx="auto"
      py={{ base: "40px", md: "50px" }}
    >
      <SectionTitle titleObj={sectionContent.title[0]} loadInit="true"/>
      <Box>
        <Flex
          maxW={{ base: '340px', sm: "380px", md: '570px', lg: "570px", xl: '1100px' }}
          w="100%"
          mx="auto"
          // py="20px"
          justifyContent="center"
          flexDir="column"
          // alignItems="center"
          h={{ base: "550px", md: "450px" }}
          px={{ base: '0px', sm: '20px', md: '30px' }}

        >
          <Slider {...settings}>
            {sectionContent?.testimonials?.map(card => {
              return <TestimonialCard card={card} key={nanoid()} />;
            })}
          </Slider>
          <style>
            {
              `
            .Yoga-SandhyaTestimonial .slick-dots .slick-active div{
              background:#711B0F;
            }
            `
            }
          </style>
        </Flex>
      </Box>
    </Box>
  );
};

export default TestimonialYs;
