/* Built In Imports */
/* External Imports */
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
    chakra
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param faqSection.faqSection - Main FAQ data.
 * @param faqSection.region
 * @param faqSection.lang
 * @param faqSection.bgColor
 * @param faqSection.color
 * @param faqSection.faqSection.faqSection
 * @param faqSection.faqSection.region
 * @param faqSection.faqSection.lang
 * @param faqSection.faqSection.bgColor
 * @param faqSection.faqSection.color
 * @returns {ReactElement} FaqSectionV2 component.
 */
const FaqSection = ({ faqSection, region, lang, bgColor, color }) => {
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="1.1rem"
      lineHeight="1.61"
      width="100%"
      m="0"
      id={faqSection?.id}
    >
      <Accordion
        key={nanoid()}
        allowMultiple
        m={{ base: '0 auto' }}
        width={{ base: '100%' }}
      >
        <AccordionItem border="none">
          <AccordionButton
            bgColor={bgColor || "#000054"}
            borderRadius="3px"
            mb="2px"
            fontFamily="FedraSansStd-medium"
            fontSize="20px"
            border="none"
            padding="10px 13px 10px 13px"
            color={color || "white" }
            _hover={{ bgColor: bgColor || "#000054", color: color || "white" }}
            _focus={{ shadow: 'transparent' }}
            minHeight="55px"
            lineHeight="1.5"
          >
            <chakra.div flex="1" textAlign="left">
              {faqSection.title}
            </chakra.div>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={1}>
            {faqSection?.faqs?.length
              ? faqSection.faqs.map((faq, index) => {
                  return (
                    <FaqChildren
                      faq={faq}
                      region={region}
                      lang={lang}
                      key={index}
                    />
                  );
                })
              : null}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

const FaqChildren = ({ faq, region, lang }) => {
  return (
    <AccordionItem
      key={nanoid(4)}
      borderBottomWidth="1px"
      borderBottomColor="gray.400"
      borderTop="none"
      lineHeight="1.61"
    >
      <AccordionButton
        pt="0.8rem"
        pb="0.8rem"
        fontFamily="FedraSansStd-medium"
        fontSize="1.1rem"
        borderTop="none"
        _hover={{ background: 'none' }}
        _focus={{ shadow: 'transparent' }}
      >
        <chakra.div flex="1" textAlign="left" key={nanoid(4)}>
          {faq.question}
        </chakra.div>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        pb={2}
        fontSize="1.1rem"
        fontFamily="FedraSansStd-book"
        lineHeight="1.61"
      >
        <Box
          display="block"
          width="100%"
          lineHeight="1.61"
          fontSize={{ lg: '18px', sm: '16px' }}
          color="#28231e"
        >
          <Box className="event-text">
            <StructuredTextParser
              str={render(faq.answer)}
              region={region}
              lang={lang}
            />
          </Box>
        </Box>
      </AccordionPanel>
      <style global jsx>
        {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }
          .faq p {
            padding-bottom: 20px;
          }
        `}
      </style>
    </AccordionItem>
  );
};

export default FaqSection;
