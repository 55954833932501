/* Built In Imports */
import { Box, Flex } from '@chakra-ui/react';

/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';
// import { nanoid } from 'nanoid';

/* Internal Imports */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
/* Components */
/* Styles */
/* Services */

const SamskritiBanner = ({ sectionContent, region, lang }) => {
  let tags = sectionContent?.tags?.trim().split(',');
  // consoleLog('sectionContent', sectionContent);
  return (
    // Banner Starts Here
    <Box w="full">
      {/* I had to add this style to the root so the word does not break */}
      <style jsx global>
        {`
          * {
            word-wrap: normal !important;
          }
        `}
      </style>
      <Box
        pos="relative"
        className="Header-background"
        width={'100%'}
        bg="black"
        minH={{ base: 'auto', lg: '573.91px', md: 'auto', sm: 'auto' }}
        height={{ lg: '573.91px', md: '573.91px', sm: 'auto', base: 'auto' }}
      >
        <Flex
          width={{ md: '70%', sm: '100%', base: '100%' }}
          ml={{ md: '30%', sm: '0', base: '0' }}
          height={{ md: '100%', sm: '253px', base: '253px' }}
          justifyContent="flex-end"
          // background={"white"}
          clipPath={{
            md: 'polygon(30% 0%, 100% 0%, 100% 100%, 24% 100%)',
            sm: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 98%)',
            base: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 98%)',
          }}
        >
          {/* Image of the Banner */}
          <Box
            backgroundImage={{
              md: sectionContent.desktopImage.url,
              base:
                sectionContent.mobileImage.url ||
                sectionContent.desktopImage.url,
            }}
            backgroundPosition={'center'}
            backgroundSize={'cover'}
            w="100%"
            h="100%"
          />
        </Flex>
        {/* Content of the banner */}
        <Box
          pos={{ md: 'absolute', sm: 'relative', base: 'relative' }}
          top={'0'}
          left="0"
          zIndex={'3'}
          h={{ md: '100%', sm: 'auto', base: 'auto' }}
          w={{ md: '43%', sm: '100%', base: '100%' }}
          pt={{ md: '55px', sm: '43.53px', base: '43.53px' }}
          pl={{ xl: '143px', sm: '20px', base: '15px' }}
          pb={{ md: '0', sm: '30px', base: '30px' }}
          pr={{ md: '0', base: '15px' }}
        >
          <Flex flexDir={'column'}>
            <Box
              as="h1"
              fontFamily={'FedraSansStd-A-medium'}
              fontSize={{ lg: '50px', md: '50px', base: '31px' }}
              lineHeight={{ base: '44px', md: '46px' }}
              fontStyle={'normal'}
              w="50%"
              fontWeight={'400'}
              color="#ffffff"
              width={{ md: '80%', base: '100%' }}
            >
              {sectionContent.title}
            </Box>
            <Flex marginTop="10px" marginBottom="17px">
              {tags.map((tag, idx) => {
                return (
                  tag.trim() && (
                    <Box
                      width="auto"
                      fontFamily="'FedraSansStd-book', sans-serif"
                      fontSize="12px"
                      color="rgb(0, 0, 0)"
                      textAlign="center"
                      marginRight="10px"
                      background="rgb(234, 182, 70)"
                      borderRadius="30px"
                      padding="2px 10px"
                    >
                      {tag}
                    </Box>
                  )
                );
              })}
            </Flex>
            <Box
              as="span"
              width="100%"
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize={{ md: '16px', base: '14px' }}
              color="#F5E3B5"
              paddingBottom="30px"
            >
              {/* {
                sectionContent[0].titleSubtext.value.document.children[0]
                  .children[0].value
              } */}
              <StructuredTextParser
                str={render(sectionContent.titleSubtext)}
                region={region}
                lang={lang}
              />
            </Box>
            <Box
              as="p"
              width="100%"
              lineHeight={{ md: '28px', base: '' }}
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize="16px"
              color="#C1C1C1"
            >
              <StructuredTextParser
                str={render(sectionContent.description)}
                region={region}
                lang={lang}
              />
            </Box>

            <Box
              as="span"
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              color="#FFDC92"
              paddingTop="30px"
              textAlign="left"
            >
              {sectionContent.sessions}
            </Box>

            {sectionContent?.button?.length > 0 && (
              <Box padding={'17px 0'}>
                <SimpleButton
                  style={sectionContent.button[0]}
                  region={region}
                  lang={lang}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default SamskritiBanner;
