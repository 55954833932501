/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getCardBgColors } from '@components/Utility/utils';
import config from '@config';

/**
 * Renders the Featured Programs component.
 *
 * @param programs.programs
 * @param {object} programs - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param programs.region
 * @param programs.lang
 * @param programs.section
 * @returns {ReactElement} Featured Programs component.
 */
const RelatedPrograms = ({ programs, region, lang, section }) => {
  let bgColors = getCardBgColors(section);
  return programs?.relatedPrograms?.length ? (
    <Box
      background="#0b055f"
      clipPath={{
        base: 'polygon(0 2%, 100% 0, 100% 100%, 0% 100%)',
        md: 'polygon(0 7%, 100% 0, 100% 100%, 0% 100%)',
      }}
      paddingBottom="60px"
      className="clear-both"
    >
      <Box color="#28231e" textAlign="center">
        <Box
          fontSize={{ base: '24px', md: '30px' }}
          padding={{ base: '60px 0 10px 0', md: '123px 0 10px 0' }}
          color="#f7f5f2"
          fontFamily="FedraSansStd-A-medium"
        >
          {programs.title}
        </Box>
        <LazyLoadImageComponent
          alt="divider"
          title="Divider"
          src={`${config.staticPath}/assets/images/flower-divider-horizontal-beige.svg`}
          // pb={{ md: '38px' }}
          // pt={{ base: '38px' }}
          w={{ base: '300px' }}
          transform={{ base: 'rotate(180deg)', lg: 'none' }}
        />

        <Box
          display="flex"
          flexWrap="wrap"
          gridGap={{ base: '15px', lg: '30px' }}
          mx="auto"
          maxWidth="1700px"
          justifyContent="center"
          marginTop="30px"
        >
          {programs &&
            programs.relatedPrograms.length > 0 &&
            programs.relatedPrograms.map((item, index) => {
              return (
                <NextLink
                  href={`/${region}/${lang}/${item.urlAlias}`}
                  passHref
                  prefetch={false}
                  key={index}
                  legacyBehavior>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Box
                      pos={'relative'}
                      w={{ base: '302px', md: '330px', lg: '370px' }}
                      padding="0 0 20px 0"
                      boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                      minHeight={{ base: 'unset', lg: '385px' }}
                      background="#ffffff"
                      key={index}
                      h="full"
                    >
                      <Box>
                        <Box
                          background={bgColors[index % bgColors.length]}
                        // background={index % 2 ? '#cd6727' : '#ae4511'}
                        >
                          <Box>
                            <LazyLoadImageComponent
                              src={item.heroImage?.url}
                              alt={item.heroImage?.alt}
                              title={item.heroImage?.title}
                              clipPath={
                                index % 2
                                  ? 'polygon(0 0, 100% 0, 100% 98%, 0 90%)'
                                  : 'polygon(0 0, 100% 0, 100% 90%, 0% 98%)'
                              }
                            />
                          </Box>
                          <Box padding="5% 20px">
                            <Text
                              fontFamily="FedraSansStd-A-medium"
                              fontSize={{
                                base: '24px',
                                md: '17px',
                                lg: '24px',
                              }}
                              color="#ffffff"
                              lineHeight="1.08"
                              className="truncate-text"
                              textAlign="left"
                            >
                              {item.title}
                            </Text>
                          </Box>
                        </Box>
                        <Text
                          fontSize="16px"
                          padding="18px"
                          textAlign="left"
                          maxHeight="80px"
                          lineHeight="20px"
                          className="truncate-text"
                          fontFamily="FedraSansStd-book"
                        >
                          {item.summary}
                        </Text>
                      </Box>
                    </Box>
                  </Link>
                </NextLink>
              );
            })}
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default RelatedPrograms;
