/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, Heading, Link, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import Loader from '@components/Utility/Loader';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */
import { fetchNews } from 'services/newsService';

/**
 * Renders the News Csr Embed component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param {Object} isLeftMenu - Responsive navbar for component
 * @returns {ReactElement} NewsCsrEmbed Card component
 */
const NewsCsrEmbed = ({
  sectionContent,
  region,
  lang,
  isLeftMenu,
  csrData,
}) => {
  const [posts, setPosts] = useState(sectionContent?.cards);
  const [isLoader, setIsLoader] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const loadMoreNews = async () => {
    // consoleLog('morePosts', posts);
    setIsLoader(true);
    let morePosts = await fetchNews(
      sectionContent.newsType,
      posts?.length,
      6,
      ''
    );
    // consoleLog('morePosts loaded', morePosts);
    if (morePosts?.posts?.cards?.length >= 0) {
      setIsLoader(false);
      setPosts(prev => [...prev, ...morePosts?.posts.cards]);
    }
    setHasLoadMore(posts.length < morePosts?.posts?.totalCount);
  };

  return (
    <Box
      mx="auto"
      maxW={{
        base: '278px',
        sm: '302px',
        md: '690px',
        lg: '828px',
        xl: '828px',
      }}
      mb="50px"
    >
      <SimpleGrid
        spacing={'30px'}
        columns={{ lg: '3', md: '2', sm: '1', base: '1' }}
      >
        {posts?.map((item, index) => {
          return (
            <Box w="266px" height={'auto'}>
              {item?.video?.thumbnailUrl && (
                <NextLink
                  href={refineUrlDomain(
                    item.video?.url || item.ctaUrl,
                    region,
                    lang
                  )}
                  passHref
                  legacyBehavior>
                  <Link
                    cursor={item.video?.url ? 'cursor' : 'auto'}
                    target={
                      (item.video?.url.indexOf('http') > -1 &&
                        item.video?.url.indexOf(config.cdnPath) < 0) ||
                        (item.ctaUrl.indexOf('http') > -1 &&
                          item.ctaUrl.indexOf(config.cdnPath) < 0)
                        ? '_blank'
                        : ''
                    }
                  >
                    <LazyLoadImageComponent
                      src={item?.video?.thumbnailUrl}
                      alt={item?.title}
                      title={item?.title}
                      objectFit="cover"
                      w="100%"
                      minH="210px"
                      maxH={218}
                      mb="15px"
                    />
                  </Link>
                </NextLink>
              )}
              <Box>
                <NextLink
                  href={refineUrlDomain(
                    item.video?.url || item.ctaUrl,
                    region,
                    lang
                  )}
                  passHref
                  legacyBehavior>
                  <Link
                    cursor={item.video?.url || item.ctaUrl ? 'cursor' : 'auto'}
                    _hover={{
                      textDecoration: 'none',
                    }}
                    target={
                      (item.video?.url?.indexOf('http') > -1 &&
                        item.video?.url?.indexOf(config.cdnPath) < 0) ||
                        (item?.ctaUrl?.indexOf('http') > -1 &&
                          item.ctaUrl?.indexOf(config.cdnPath) < 0)
                        ? '_blank'
                        : ''
                    }
                  >
                    <Heading
                      fontFamily={'FedraSansStd-A-medium'}
                      fontSize="18px"
                      fontWeight={'500'}
                      lineHeight="22.5px"
                      color={'#28231e'}
                    >
                      {item.title}
                    </Heading>
                  </Link>
                </NextLink>
                <Box
                  fontFamily={'FedraSansStd-medium'}
                  color="#8a8173"
                  fontSize="16px"
                  mt="10px"
                >
                  {item.newsPublishedDate}
                  {item.newsSource ? ` | ${item.newsSource}` : ''}
                </Box>

                <Box
                  className="event-text desc"
                  fontFamily={'FedraSansStd-book'}
                  color="#28231E"
                  p="5px 0"
                  mb="15px"
                >
                  {item.body && (
                    <Box p="0" className="truncate-text-6">
                      <StructuredTextParser
                        str={render(item.body)}
                        region={region}
                        lang={lang}
                        as="div"
                        mt="10px"
                      />
                    </Box>
                  )}
                </Box>

                {item.ctaUrl && (
                  <NextLink href={refineUrlDomain(item.ctaUrl, region, lang)} passHref legacyBehavior>
                    <Link
                      variant={'outline'}
                      display="block"
                      fontWeight="600"
                      w="fit-content"
                      px="15px"
                      py="5px"
                      border="1px solid #BB3E03"
                      borderRadius="3px"
                      color="#BB3E03"
                      fontSize="16px"
                      fontFamily="FedraSansStd-book"
                      textAlign="center"
                      mb="15px"
                      onClick={loadMoreNews}
                      title="View more"
                      rel="next"
                      _hover={{
                        textDecoration: 'none',
                        bgColor: '#cf4520',
                        color: 'white',
                      }}
                      target={
                        item.ctaUrl.indexOf('http') > -1 &&
                          item.ctaUrl.indexOf(config.cdnPath) < 0
                          ? '_blank'
                          : ''
                      }
                    >                      
                      {item.ctaLabel || 'View'}
                    </Link>
                  </NextLink>
                )}
              </Box>
            </Box>
          );
        })}
      </SimpleGrid>

      {hasLoadMore && (
        <Box
          pos="relative"
          float="left"
          w="100%"
          //   h={'76px'}
          m="25px auto"
          display="block"
          alignItems={'center'}
          justifyContent="center"
        >
          <Box w="100%" textAlign="center" display={'block'}>
            <Link
              variant={'outline'}
              display="block"
              width="164px"
              p="10px 23px"
              fontWeight="500"
              border="2px solid #cf4520"
              borderRadius="5px"
              color="#fff"
              fontSize="16px"
              fontFamily="FedraSansStd-medium"
              textAlign="center"
              m="0 auto"
              mb="5px"
              background="#D04723"
              _hover={{
                textDecoration: 'none',
                background: '#000422',
                color: '#fff',
                border: '2px solid transparent',
              }}
              onClick={loadMoreNews}
              title="Load More"
              rel="next"
            >
              Load more
            </Link>
          </Box>
          {isLoader && (
            <Box w="100%" textAlign="center" display={'block'}>
              <Loader pos="relative" />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NewsCsrEmbed;
