/* Built In Imports */
import Link from "next/link";
import React, { memo } from 'react';
/* External Imports */
import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiShareAlt } from 'react-icons/bi';

/* Internal Imports */
/* Components */
import config from '@config';

/* Styles */
/* Services */

/**
 *
 * @param {string} background
 * @returns
 */

const ShareIcon = ({ background, url, icon, color }) => {
  // const isMobile = useMediaQuery({ maxWidth: 766 });
  // const [visible, setIsVisible] = useState(false);
  // const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();
  const initRef = React.useRef();
  const socLink = 'https://www.addtoany.com/add_to';
  return (
    <Box>
      <Popover placement="bottom-end" initialFocusRef={initRef}>
        {({ isOpen }) => (
          <>
            <PopoverTrigger zIndex="12" pos="relative">
              <Flex
                width={{ base: '24px', md: '32px' }}
                height={{ base: '24px', md: '32px' }}
                // background={{
                //   base: isOpen ? '#A9392D' : background || 'transparent',
                //   md: isOpen ? '#A9392D' : background || 'transparent',
                // }}
                background="transparent"
                rounded="full"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                fontSize={{ base: '24px', md: '32px' }}
                color={color || (isOpen ? '#D04723' : '#B0AEAD')}
                _hover={{
                  color: '#D04723',
                }}
                // _hover={{ color: '#F6A15E' }}
                pos="relative"
                zIndex="11"
              >
                {isOpen && (
                  <Text fontWeight="light" as="span" fontFamily="monospace">
                    <BiShareAlt />
                  </Text>
                )}
                {!isOpen && (
                  <Box as="span">
                    {!icon && <BiShareAlt />}
                    {icon && (
                      <Text
                        color="buttonText.color1"
                        fontSize={{ base: '20px', md: '25px' }}
                        fontWeight="bold"
                      >
                        <BiShareAlt />
                      </Text>
                    )}
                  </Box>
                )}
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              height="auto"
              w={{ base: '28px', md: '36px' }}
              outline="none"
              _hover={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
              rounded="none"
              boxShadow="0px 4px 6px 0px rgba(0, 0, 0, 0.25)"
              background="#fff"
              // border="1px solid #EDD1C2"
              border="none"
              mt="-5px"
              pb="17px"
            >
              <PopoverBody
                boxSizing="border-box"
                padding="0px"
                background="#ffffff"
                pb="5px"
                minH={{ base: '1px', md: 'auto' }}
                height="fit-content"
                // rounded="md"
                zIndex={{ base: '10', md: '8' }}
              >
                <Flex
                  flexDir="column"
                  pt="10px"
                  // _hover={{ color: '#BC4034' }}
                  gap={{ base: '11px', md: '15px' }}
                  // color="#D47A33"
                  height="auto"
                  alignItems="center"
                  justifyContent="center"
                  gridGap={{ base: '11px', md: '15px' }}
                  fontSize={{ base: '15px', md: '18px' }}
                >
                  <Box>
                    {/* <WhatsappShareButton
                      url={`${config.BASE_PATH}${url}`}
                      title={''}
                      separator=":: "
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/facebook?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <Image
                        w={{ base: "14px", md: '20px' }}
                        h={{ base: "14px", md: '20px' }}
                        verticalAlign="middle"
                        _hover={{
                          w: { base: "19px", md: '30px' },
                          h: { base: "19px", md: '30px' },
                          content: `url(${config.imgPath}/d/46272/1690524542-62487-bluetie-icons-computer-facebook-login-icon-email-1-1-2.svg)`,
                          verticalAlign: "middle"
                        }}
                        src={`${config.imgPath}/d/46272/1690524548-62487-bluetie-icons-computer-facebook-login-icon-email-1-1.svg`}
                        alt="facebook icon"
                      />
                      {/* <Icon bg="transparent" as={FaFacebook} _hover={{ bg: "transparent", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }} color={"blue"} /> */}
                    </Link>
                    {/* </WhatsappShareButton> */}
                  </Box>
                  <Box>
                    {/* <FacebookShareButton
                      url={`${config.BASE_PATH}${url}`}
                      quote={''}
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/twitter?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <Image
                        w={{ base: "14px", md: '20px' }}
                        h={{ base: "14px", md: '20px' }}
                        verticalAlign="middle"
                        _hover={{
                          w: { base: "19px", md: '30px' },
                          h: { base: "19px", md: '30px' },
                          content: `url(${config.imgPath}/d/46272/1690524521-logo_of_twitter-1-1.svg)`,
                          verticalAlign: "middle"

                        }}
                        src={`${config.imgPath}/d/46272/1690524534-logo_of_twitter-1.svg`}
                        alt="twitter icon"
                      />
                      {/* <Icon
                        bg="transparent"
                        as={FaTwitter}
                        _hover={{
                          bg: 'transparent',
                          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                        color="skyblue"
                      /> */}
                    </Link>
                    {/* </FacebookShareButton> */}
                  </Box>
                  <Box>
                    {/* <LinkedinShareButton
                      url={`${config.BASE_PATH}${url}`}
                      quote={''}
                      title=""
                      summary=""
                      source=""
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/whatsapp?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <Image
                        w={{ base: "14px", md: '20px' }}
                        h={{ base: "14px", md: '20px' }}
                        verticalAlign="middle"
                        _hover={{
                          w: { base: "19px", md: '30px' },
                          h: { base: "19px", md: '30px' },
                          content: `url(${config.imgPath}/d/46272/1690524513-group-3502-1.svg)`,
                          verticalAlign: "middle"

                        }}
                        src={`${config.imgPath}/d/46272/1690524517-group-3502.svg`}
                        alt="whatsapp icon"
                      />
                    </Link>
                    {/* </LinkedinShareButton> */}
                  </Box>

                  <Box>
                    <CopyToClipboard
                      text={`${config.BASE_PATH}${url}`}
                      onCopy={() =>
                        toast({
                          title: 'Link copied',
                          description: 'Link copied',
                          // status: 'secondary',
                          duration: 3000,
                          isClosable: false,
                          colorScheme: 'gray',
                          render: () => (
                            <Box
                              background="#1f1f1f"
                              fontFamily="FedraSansStd-book"
                              fontSize="18px"
                              fontWeight="350"
                              padding="10px"
                              color="white"
                              width="150px"
                              border="1px solid #EFEFEF"
                              textAlign="center"
                              borderRadius="4px"
                            >
                              Link copied
                            </Box>
                          ),
                        })
                      }
                    >
                      <span>
                        {/* <TbCopy cursor="pointer" /> */}
                        <Image
                          w={{ base: "14px", md: '20px' }}
                          h={{ base: "14px", md: '20px' }}
                          verticalAlign="middle"
                          _hover={{
                            w: { base: "19px", md: '30px' },
                            h: { base: "19px", md: '30px' },
                            content: `url(${config.imgPath}/d/46272/1690524503-group-3479-1.svg)`,
                            verticalAlign: "middle"

                          }}
                          src={`${config.imgPath}/d/46272/1690524509-group-3479.svg`}
                          alt="Link icon"
                        />
                      </span>
                    </CopyToClipboard>
                  </Box>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
export default memo(ShareIcon);
