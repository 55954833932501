/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getCardBgColors } from '@components/Utility/utils';

/* Services */

/**
 * Renders the Two Column Card component.
 * @param {Object} programs - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param {string} cardType - Type of card in api
 * @returns {ReactElement} Two Column Card component.
 */
const TwoColumnCard = ({ programs, region, lang, cardType }) => {
  let bgColors = getCardBgColors(cardType);

  return (
    <Box display="flex" flexWrap={'wrap'} justifyContent="center">
      {/* dynamic binding */}
      {programs &&
        programs.length > 0 &&
        programs.map((item, index) => {
          return (
            <Box
              textAlign="left"
              w={{ base: '302px', sm: '330px', xl: '470px' }}
              boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
              background="#ffffff"
              margin={{ base: '7px', md: '15px' }}
              marginTop="15px"
              // h={{ base: '460px', md: '400px', lg: '510px' }}
              height='auto'
              key={nanoid()}
            >
              <NextLink
                href={`/${region}/${lang}/${item.urlAlias}`}
                passHref
                prefetch={false}
                legacyBehavior>
                <Link
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Box>
                    <Box
                      background={bgColors[index % bgColors.length]}
                      position={'relative'}
                    >
                      <Box
                        // minH={{ base: '231px', sm: '211px', lg: '222px' }}
                        h={'50%'}
                      >
                        {item?.programCardTag && (
                          <Box
                            pos={'absolute'}
                            w="130px"
                            h="38px"
                            top="0"
                            right="0"
                            textAlign="right"
                            fontSize="12px"
                            overflow="hidden"
                            zIndex="9000"
                          >
                            <Box
                              p="4px 14px"
                              clipPath="polygon(0 -190%, 100% 0, 100% 100%, 20% 100%)"
                              color="#fff"
                              bgColor="#0d5878"
                              data="English"
                              w="100%"
                              textAlign="right"
                              textShadow="#ffffff80 0px 1px 0px"
                              fontFamily="FedraSansStd-medium"
                              fontSize="12px"
                              boxShadow="0 0 3px rgb(0 0 0 / 30%)"
                            >
                              {item.programCardTag}
                            </Box>
                          </Box>
                        )}

                        {/* <LazyLoadImageComponent
                              src={item.heroImage.url}
                              alt={item.title}
                              title={item.title}
                              clipPath="polygon(0 0, 100% 0, 100% 90%, 0% 98%)"
                            /> */}
                        <LazyLoadImageComponent
                          src={item?.heroImage?.url}
                          alt={item.title}
                          title={item.title}
                          width="100%"
                          objectFit={'cover'}
                          h={{ base: '100%', md: '190px', lg: '250px' }}
                          clipPath="polygon(0 0, 100% 0, 100% 100%, 0% 92%)"
                        />
                      </Box>
                      <Text
                        fontFamily="FedraSansStd-A-medium"
                        className="line2ellipsis"
                        fontSize={{
                          base: '24px',
                          md: '17px',
                          lg: '24px',
                        }}
                        m="18px"
                        pb="18px"
                        color="#ffffff"
                        h="20%"
                      >
                        <Box className="truncate-text"> {item.title} </Box>
                      </Text>
                    </Box>
                    <Text
                      fontSize={{ base: '17px', md: '13px', lg: '17px' }}
                      px="18px"
                      pb="18px"
                      lineHeight="1.35"
                      fontFamily="FedraSansStd-book"
                      color="#28231e"
                      height={'30%'}
                    >
                      <Box className="truncate-text">{item.summary}</Box>
                    </Text>
                  </Box>
                </Link>
              </NextLink>
            </Box>
          );
        })}
    </Box>
  );
};

export default TwoColumnCard;