/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import { nanoid } from 'nanoid';

/* Internal Components */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';

/**
 * Renders the Ishanga Benefits Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Ishanga Benefits Card component
 */
const BenefitsUsUk = ({ sectionContent, region, lang, section }) => {
  return (
    <Box
      // backgroundColor="#F5F0E5"
      mb="50px"
      h="100%"
    >
      <Box
        mx="auto"
        maxW={{
          base: '302px',
          sm: '480px',
          md: '690px',
          lg: '770px',
          xl: '1200px',
        }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
          mx="auto"
          gap="15px"
          // backgroundColor="#F5F0E5"
        >
          {sectionContent?.cards?.map((secCon, index) => {
            return (
              <Box
                marginBottom="2%"
                display="flex"
                flexDirection="column"
                mx="auto"
                w={{
                  base: '100%',
                  sm: '330px',
                  lg: '370px',
                }}
                key={nanoid()}
                position="relative"
                // backgroundColor="#F5F0E5"
              >
                <NextLink
                  href={refineUrlDomain(secCon.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <LazyLoadImageComponent
                    src={secCon?.thumbnail?.url}
                    alt={secCon?.thumbnail?.alt}
                    title={secCon?.thumbnail?.title}
                    width={'100%'}
                    objectFit="cover"
                    h="241px"
                  />
                </NextLink>
                <Box>
                  <Box
                    as="h2"
                    fontSize={'22px'}
                    color="#28231e"
                    lineHeight="1.11"
                    textAlign={'center'}
                    marginTop="20px"
                    marginBottom="10px"
                    fontFamily="FedraSansStd-A-medium"
                  >
                    {secCon.title}
                  </Box>
                  <Box
                    className="benefits-des"
                    textAlign={'center'}
                    fontFamily="FedraSansStd-book"
                    fontSize={'18px'}
                  >
                    {StructuredTextContent(secCon.description)}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
      <style>
        {`
        .benefits-des a{
          color: #cf4824;
          word-break: break-word;
          border-bottom: 2px solid #cf4824;
          transition: background-size 0.125s ease-in;
          background-image: linear-gradient(120deg, #cf4824, #cf4824);
          background-repeat: no-repeat;
          background-size: 100% 0;
          background-position: 0 100%;
        }
        .benefits-des a:hover{
          background-color: #cf4824;
          color: white;
          background-image: linear-gradient(120deg, #cf4824, #cf4824);
          background-size: 100% 100%;
          transition: all 0.3s ease-in-out;
        }
        `}
      </style>
    </Box>
  );
};
export default BenefitsUsUk;
