/* Built In Imports */
/* External Imports */
import {
    Box, Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';
import { useState } from 'react';

/* Internal Imports */
/* Components */
/* Services */

/**
 *Renders TabGroupV2 Component
 * @returns {ReactElement} TabGroupV2 Block component
 */
const TabGroupV2 = ({ sectionContent, region, lang }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const sevaDates_women = sectionContent?.tabContent[0]?.content[0]?.text;
  const sevaDates_men = sectionContent?.tabContent[1]?.content[0]?.text;

  const men = sevaDates_men.split(';');
  const women = sevaDates_women.split(';');

  // consoleLog(sectionContent);
  return (
    <Box pos="relative" w="100%" float="left">
      <Flex
        flexDirection="column"
        padding="0"
        maxW="1270px"
        m="0 auto"
        alignItems="center"
      >
        <Tabs
          onChange={index => setTabIndex(index)}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          // marginBottom="15px"
        >
          <TabList>
            {sectionContent?.tabContent?.map((tabItem, index) => {
              return (
                <Tab
                  w={{ base: "50%", sm: "184px" }}
                  color="#A39D93"
                  fontWeight='400'
                  fontFamily="FedraSansStd-book"
                  borderBottomColor='#A39D93'
                  fontSize='18px'
                  _selected={{
                    color: '#CF4520',
                    fontWeight: '500',
                    borderBottom: '4px solid',
                    borderBottomColor: '#CF4520'
                  }}
                >
                  {tabItem?.title}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels
            p="15px 0"
            w="30rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {sectionContent?.tabContent?.map((tabContent, index) => {
              const dates = tabContent?.content[0]?.text?.split(';');
              return (
                <TabPanel
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                >
                  {dates?.map((val, index) => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="250px"
                        fontSize="16px"
                        margin={{ base: '5px', sm: '5px', md: '10px' }}
                        height="45px"
                        backgroundColor="white"
                        borderRadius="6px"
                        padding="12px 22px"
                        index={index}
                        fontWeight="400"
                        fontFamily="FedraSansStd-book"
                      >
                        {val}
                      </Box>
                    );
                  })}
                </TabPanel>
              );
            })}

            {/* <TabPanel
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              {women.map((val, index) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    fontSize="16px"
                    margin={{ base: '5px', sm: '5px', md: '10px' }}
                    height="45px"
                    backgroundColor="white"
                    borderRadius="6px"
                    padding="12px 22px"
                    index={index}
                    fontWeight="400"
                    fontFamily="FedraSansStd-book"
                  >
                    {val}
                  </Box>
                );
              })}
            </TabPanel>
            <TabPanel
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              {men.map((val, index) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    fontSize="16px"
                    margin={{ base: '5px', sm: '5px', md: '10px' }}
                    height="45px"
                    backgroundColor="white"
                    borderRadius="6px"
                    padding="12px 22px"
                    index={index}
                    fontWeight="400"
                    fontFamily="FedraSansStd-book"
                  >
                    {val}
                  </Box>
                );
              })}
            </TabPanel> */}
          </TabPanels>
        </Tabs>
        {/* <Button borderRadius="5px" fontWeight="500" fontSize="18px" paddingLeft="26px" paddingRight="26px" paddingTop="26px" paddingBottom="26px" >I am Interested</Button> */}
      </Flex>
    </Box>
  );
};

export default TabGroupV2;
