/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Image } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import ContentArea from '@Layout/ContentArea';
import MidContainer from '@Layout/MidContainer';
import AccordionSection from '@components/Accordions/AccordionSection';
import AccordionV2 from '@components/Accordions/AccordionV2';
import Faq from '@components/Accordions/FaqQuestionier';
import FaqSectionV2 from '@components/Accordions/FaqSectionV2';
import FaqSectionV2Volunteer from '@components/Accordions/FaqSectionV2Volunteer';
import YogaSandhyaFaq from '@components/Accordions/YogaSandhyaFaq';
import SimpleButton from '@components/Buttons/SimpleButton';
import CardCircular2ColumnGroup from '@components/Card/CardCircular2ColumnGroup';
import CardCustomCardGroup from '@components/Card/CardCustomCardGroup';
import CardDonationGroup from '@components/Card/CardDonationGroup';
import CardHorizontalGroup from '@components/Card/CardHorizontalGroup';
import CardInfo2 from '@components/Card/CardInfo2';
import CardInfo3 from '@components/Card/CardInfo3';
import CardInfo4 from '@components/Card/CardInfo4';
import CardProgram2ColumnGroup from '@components/Card/CardProgram2ColumnGroup';
import CardProgram3ColumnGroup from '@components/Card/CardProgram3ColumnGroup';
import CardResearchPublication3ColumnGroup from '@components/Card/CardResearchPublication3ColumnGroup';
import CardZigzagGroup from '@components/Card/CardZigzagGroup';
import EventProgramCard from '@components/Card/EventProgramCard';
import FilterYogaMeditationProgramCard from '@components/Card/FilterYogaMeditationProgramCard';
import LocalCenterContactCardGroup from '@components/Card/LocalCenterContactCardGroup';
import NewsCsrEmbed from '@components/Card/NewsCsrEmbed';
import ResearchProject3ColumnCardsFilterLinked from '@components/Card/ResearchProject3ColumnCardsFilterLinked';
import SingleImage from '@components/Card/SingleImage';
import TagCloud from '@components/Card/TagCloud';
import TwoColumnCard from '@components/Card/TwoColumnCard';
import UpcomingEventsCelebrationsLargeCard from '@components/Card/UpcomingEventsCelebrationsLargeCard';
import VolunteeringHowToApplyStep from '@components/Card/VolunteeringHowToApplyStep';
import PubListingFacadePlaceholderTabGroup from '@components/Header/Menu/PubListingFacadePlaceholderTabGroup';
import TopBannerEvent from '@components/HeaderBanner/TopBannerEvent';
import TopBannerSimple from '@components/HeaderBanner/TopBannerSimple';
import TopBannerV2 from '@components/HeaderBanner/TopBannerV2';
import TopBannerWithoutImage from '@components/HeaderBanner/TopBannerWithoutImage';
import VolunteerSharingTopSliderGroup from '@components/HeaderBanner/VolunteerSharingTopSliderGroup';
import SectionTitle from '@components/Headings/SectionTitle';
import TopKolam from '@components/Headings/TopKolam';
import QuoteSymbolImageSignature from '@components/Quotes/QuoteSymbolImageSignature';
import AdBannerGroup from '@components/Sliders/AdBannerGroup';
import CardInfo2Slider from '@components/Sliders/CardInfo2Slider';
import ImageGallery from '@components/Sliders/ImageGallery';
import InTheNewsSlider from '@components/Sliders/InTheNewsSlider';
import SliderGallery from '@components/Sliders/SliderGallery';
import TestimonialYs from '@components/Sliders/TestimonialYs/TestimonialYs';
import Testimonials from '@components/Sliders/Testimonials';
import TopicBasedWisdomContent from '@components/Sliders/TopicBasedWisdomContent';
import VolunteerBrownTestimonial from '@components/Sliders/VolunteerBrownTestimonial';
import WhyBooksSlider from '@components/Sliders/WhyBooksSlider';
import YogaSandhyaBrownGallery from '@components/Sliders/YogaSandhyaBrownGallery';
import JsonTable from '@components/Tables/JsonTable';
import JsonTableUI from '@components/Tables/JsonTableUI';
import BrownSpiralBullets from '@components/Text/BrownSpiralBullets';
import YsBenefitsTitle from '@components/Text/YsBenefitsTitle';
import FaqExpandedSection from '@components/UI/FaqExpandedSection';
import LargeBrownBullets from '@components/UI/Health/LargeBrownBullets';
import HtmlBlock from '@components/UI/HtmlBlock';
import ImportantMessage from '@components/UI/ImportantMessage';
import ProgramFinderComp from '@components/UI/ProgramFinder/ProgramFinderComp';
import PubHomePageCard from '@components/UI/Publications/PubHomePageCard';
import Quote from '@components/UI/Quote';
import RelatedPrograms from '@components/UI/RelatedPrograms';
import RelatedStories from '@components/UI/RelatedStories';
import RelatedVolunteering from '@components/UI/RelatedVolunteering';
import SamskritiRedBox from '@components/UI/SamskritiRedBox';
import SingleLineText from '@components/UI/SingleLineText';
import TabGroupV2 from '@components/UI/TabGroupV2';
import IdyCardInfo3 from '@landing_pages/idy/CardInfo3';
import IdyTopBannerEvent from '@landing_pages/idy/IdyTopBannerEvent';
import IshangaBenefits from '@landing_pages/ishanga7/Benefits';
import BenefitsUsUk from '@landing_pages/ishanga7/BenefitsUsUk';
import IshangaTestimonials from '@landing_pages/ishanga7/IshangaTestimonials';
import IshangaUsCaTest from '@landing_pages/ishanga7/IshangaUsCaTest';
import IshangaQuote from '@landing_pages/ishanga7/Quote';
import IshangaSingleImage from '@landing_pages/ishanga7/SingleImage';
import SamskritiBanner from '@landing_pages/project-samskriti/SamskritiBanner';
import SamskritiBenefit from '@landing_pages/project-samskriti/SamskritiBenefit';
import BenefitsFourColumn from '@sections/publications/BenefitsFourColumn';
import { useEffect, useState } from 'react';
import { IsoApiConstants } from '../../../constants';
import FeeDetails from '../FeeDetails';
import ItalianStyleText from '../ItalianStyleText';
import BenefitsGroup from '../LandingPages/BenefitsGroup';

/* Services */

/**
 * Renders the Section UI Component
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @returns {ReactElement} UpcomingDates component
 */

const SectionUI = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageBody,
  FYDdate,
  pageConfig,
  disableButton,
}) => {
  // console.log("Section content", sectionContent);

  const router = useRouter();
  return (
    <Box w="100%" display="flex" flexDirection="column">
      {(pageName === 'ishanga7' || pageName === 'bog') &&
        RenderIshangaComponents({
          sectionContent,
          region,
          language,
          section,
          isLeftMenu,
          pType,
          pageName,
          csrData,
          router,
          pageBody,
        })}
      {(!pageName || pageName === 'IDYPage') &&
        RenderComponents({
          sectionContent,
          region,
          language,
          section,
          isLeftMenu,
          pType,
          pageName,
          csrData,
          router,
          pageBody,
          FYDdate,
          pageConfig,
          disableButton,
        })}
    </Box>
  );
};

// async function loadModuleDynamically(moduleName) {
//   const SamskritiBanner = await import('@landing_pages/project-samskriti/SamskritiBanner');
//   return SamskritiBanner;
//   return (
//     <SamskritiBanner
//       sectionContent={sectionContent}
//       region={region}
//       lang={language}
//     />
//   );
// }

const RenderComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  router,
  pageBody,
  FYDdate,
  pageConfig,
  disableButton,
}) => {
  switch (sectionContent._modelApiKey) {
    case 'top_banner_simple':
      // const TopBannerSimple = await import('@components/HeaderBanner/TopBannerSimple');
      return <TopBannerSimple sectionContent={sectionContent} />;
    case 'top_banner_v2':
      return (
        <TopBannerV2
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'volunteer_sharing_top_slider_group':
      return (
        <VolunteerSharingTopSliderGroup
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    // case 'top_banner_v2':
    //   import('@components/HeaderBanner/TopBannerV2')
    //     .then((TopBannerV2) => {
    //       return (
    //         <TopBannerV2
    //           sectionContent={sectionContent}
    //           region={region}
    //           language={language}
    //         />
    //       );
    //     })
    //     .catch(err => console.err('An error occurred while loading a component', err));
    // const TopBannerV2 = await import('@components/HeaderBanner/TopBannerV2');

    // const compNew = (10>5) && import('@sections/ishanga/IshangaStickyBar');
    // return compNew;

    case 'top_banner_event':
      // const TopBannerEvent = await import('@components/HeaderBanner/TopBannerEvent');
      if (router.asPath.indexOf('21-day-challenge-idy') > -1) {
        return (
          <IdyTopBannerEvent
            sectionContent={sectionContent}
            region={region}
            language={language}
            pageName={pageName}
            FYDdate={FYDdate}
            pageConfig={pageConfig}
            disableButton={disableButton}
            csrData={csrData}
          />
        );
      }
      return (
        <TopBannerEvent
          sectionContent={sectionContent}
          region={region}
          language={language}
          pageName={pageName}
          FYDdate={FYDdate}
          pageConfig={pageConfig}
          disableButton={disableButton}
          csrData={csrData}
        />
      );
    case 'top_banner_samskriti':
      // async function load() {
      //   let SamskritiBanner = await(await import('@landing_pages/project-samskriti/SamskritiBanner')).default();
      //  consoleLog('SamskritiBanner', SamskritiBanner);
      return (
        <SamskritiBanner
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    // }
    // load();

    case 'event_program_card':
      // const EventProgramCard = dynamic(() => import('@components/Card/EventProgramCard'));
      return (
        <EventProgramCard
          programs={sectionContent.cards}
          region={region}
          lang={language}
          cardType="event"
        />
      );
    case 'top_kolam':
      // const TopKolam = dynamic(() => import('@components/Headings/TopKolam'));
      return <TopKolam sectionContent={sectionContent} />;
    case 'structured_text':
      return (
        <RenderStructuredText
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'section_title':
      // const SectionTitle = dynamic(() => import('@components/Headings/SectionTitle'));
      return <SectionTitle titleObj={sectionContent} pageName={pageName} />;
    case 'card_info2_column_group':
      // const CardInfo2 = dynamic(() => import('@components/Card/CardInfo2'));
      return (
        <MidContainer w={'768'}>
          <Box display="flex" marginTop="50px">
            <CardInfo2
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        </MidContainer>
      );
    case 'card_info4_column_group':
      // const CardInfo4 = dynamic(() => import('@components/Card/CardInfo4'));
      return (
        <CardInfo4
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_small_info3_column_group':
    case 'card_info3_column_group':
      if (section?.sectionId === 'idy-how-to-make') {
        return (
          <IdyCardInfo3
            sectionContent={sectionContent}
            region={region}
            lang={language}
            section={section}
            isLeftMenu={isLeftMenu}
            pageName={pageName}
          />
        );
      }
      // const CardInfo3 = dynamic(() => import('@components/Card/CardInfo3'));
      return (
        <CardInfo3
          sectionContent={sectionContent}
          region={region}
          lang={language}
          section={section}
          isLeftMenu={isLeftMenu}
          pageName={pageName}
        />
      );
    case 'samskriti_benefit':
      // const SamskritiBenefit = dynamic(() => import('@landing_pages/project-samskriti/SamskritiBenefit'));
      return (
        <SamskritiBenefit
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'health2_column_card':
      // const TwoColumnCard = dynamic(() => import('@components/Card/TwoColumnCard'));
      return (
        <TwoColumnCard
          programs={sectionContent.healthPrograms}
          region={region}
          lang={language}
          cardType="health"
        />
      );
    case 'simple_button':
      return (
        <RenderSimpleButton
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
          styleName={pageName}
          pageConfig={pageConfig}
          disableButton={disableButton}
          csrData={csrData}
        />
      );
    case 'gallery':
      return (
        <RenderGallery
          sectionContent={sectionContent}
          region={region}
          lang={language}
          section={section}
        />
      );
    case 'testimonials_group_v2':
      // const Testimonials = dynamic(() => import('@components/Sliders/Testimonials'));
      if (sectionContent?.style === 'volunteer-brown-slider') {
        return (
          <Box display="flex" my="30px" border="2px" borderColor="transparent">
            <VolunteerBrownTestimonial
              testimonialsObj={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        );
      } else if (section?.sectionId === 'testimonial-ys') {
        return (
          <Box display="flex" my="30px" border="2px" borderColor="transparent">
            <TestimonialYs
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        );
      }
      return (
        <MidContainer>
          <Box
            display="flex"
            marginTop="20px"
            border="2px"
            borderColor="transparent"
          >
            <Testimonials
              testimonialsObj={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        </MidContainer>
      );
    case 'related_content':
      // const RelatedStories = dynamic(() => import('@components/UI/RelatedStories'));
      return (
        <RelatedStories
          region={region}
          lang={language}
          data={sectionContent.wisdomBlogContent}
          titleObj={sectionContent}
          isShareEnabled
        />
      );
    case 'related_health_program':
    case 'related_processes_offering':
    case 'related_yoga_meditation_program':
      // const RelatedPrograms = dynamic(() => import('@components/UI/RelatedPrograms'));
      return (
        <RelatedPrograms
          programs={sectionContent}
          region={region}
          lang={language}
          section={
            sectionContent._modelApiKey === 'related_yoga_meditation_program'
              ? 'yoga'
              : ''
          }
        />
      );
    case 'related_volunteering':
      return (
        <RelatedVolunteering
          programs={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'upcoming_programs_v2':
      const UpcomingProgramsV2 = dynamic(() =>
        import('@components/UI/UpcomingProgramsV2')
      );
      return (
        <UpcomingProgramsV2
          sectionContent={sectionContent}
          region={region}
          language={language}
          isLeftMenu={isLeftMenu}
        />
      );
    case 'newsletter_subscription_form':
      const SubscribeContent = dynamic(() =>
        import('@components/UI/Subscribe/SubscribeContent')
      );
      return (
        <Box
          bgColor={pType === 'center' ? '' : '#f5f0e5'}
          display="flex"
          width="full"
          justifyContent="center"
        >
          <SubscribeContent
            language={language}
            contentSection="wisdom"
            contentType="poem"
            contentObj={sectionContent}
            ctaText={''}
            bgColor={''}
            hideSpamText={false}
            // ctaText={pType === 'center' ? '' : 'Sign Up'}
            // bgColor={pType === 'center' ? '' : '#f5f0e5'}
            // hideSpamText={pType === 'center' ? false : true}
            pType={pType}
          />
        </Box>
      );
    case 'multiple_newsletter_subscription_form':
      const NewsletterSubscription = dynamic(() =>
        import('@components/UI/Newsletter/NewsletterSubscription')
      );
      return (
        <NewsletterSubscription
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    // case 'newsletter_subscription_form_popup':
    //   const NewsletterSubscriptionPopup = dynamic(() =>
    //     import('@components/RegisterModal')
    //   );
    //   return (
    //     <NewsletterSubscriptionPopup
    //       sectionContent={sectionContent}
    //       region={region}
    //       lang={language}
    //     />
    //   );
    case 'single_image':
      return (
        <SingleImage
          sectionContent={sectionContent}
          region={region}
          lang={language}
          w={{
            base: sectionContent.fieldId === 'ys-benefits-title' ? '135px' : null,
            md: sectionContent.fieldId === 'ys-benefits-title' ? '253px' : null,
          }}
          sectionId={section?.sectionId}
        />
      );
    case 'ad_image_banner':
      const ImageBannerAd = dynamic(() =>
        import('@components/Card/ImageBannerAd')
      );
      return (
        <ImageBannerAd
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'quote':
      if (sectionContent.decoration == 'quote-symbol-image-signature') {
        return <QuoteSymbolImageSignature sectionContent={sectionContent} />;
      }
      // const Quote = dynamic(() => import('@components/UI/Quote'));
      return <Quote sectionContent={sectionContent} />;
    case 'media_embed':
      const MediaEmbed = dynamic(() => import('@components/Embeds/MediaEmbed'));
      return <MediaEmbed sectionContent={sectionContent} />;
    // case 'media_embed':
    //   import('@components/Embeds/MediaEmbed')
    //     .then((MediaEmbed) => {
    //  consoleLog('MediaEmbed 2', MediaEmbed);
    //       return (
    //         <MediaEmbed sectionContent={sectionContent} />
    //       );
    //     });
    case 'flockler_embed':
      const FlocklerEmbedWidget = dynamic(() =>
        import('@components/Embeds/FlocklerEmbedWidget')
      );
      return <FlocklerEmbedWidget sectionContent={sectionContent} />;
    case 'share_widget':
      const SocialLinks = dynamic(() => import('@components/UI/SocialLinks'));
      return <SocialLinks url={router.asPath} />;
    case 'upcoming_dates_abode_offering':
      const UpcomingDates = dynamic(() =>
        import('@components/UI/Center/UpcomingDates')
      );
      return (
        <UpcomingDates
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'local_center_contact_cards_group':
      // const LocalCenterContactCardGroup = dynamic(() => import('@components/Card/LocalCenterContactCardGroup'));
      return (
        <LocalCenterContactCardGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_donation_group':
      // const CardDonationGroup = dynamic(() => import('@components/Card/CardDonationGroup'));
      return (
        <CardDonationGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    // case 'card_info3_column_imageless_group':
    //   return (
    //     <CardInfo3ColumnImageLessGroup sectionContent={sectionContent} />
    //   );
    case 'google_map':
      const GoogleMap = dynamic(() => import('@components/Embeds/GoogleMap'));
      return <GoogleMap sectionContent={sectionContent} />;
    case 'important_message':
      // const ImportantMessage = dynamic(() => import('@components/UI/ImportantMessage'));
      return (
        <ImportantMessage
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'upcoming_events_celebrations_large_card':
      // const UpcomingEventsCelebrationsLargeCard = dynamic(() => import('@components/Card/UpcomingEventsCelebrationsLargeCard'));
      return (
        <UpcomingEventsCelebrationsLargeCard
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'card_info2_column_slider_group':
      // const CardInfo2Slider = dynamic(() => import('@components/Sliders/CardInfo2Slider'));
      return (
        <CardInfo2Slider
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'card_program2_column_group':
      // const CardProgram2ColumnGroup = dynamic(() => import('@components/Card/CardProgram2ColumnGroup'));
      return (
        <CardProgram2ColumnGroup
          programs={sectionContent.cards}
          region={region}
          lang={language}
          cardType={pType}
        />
      );
    case 'json_table':
      const JsonTableData = dynamic(() =>
        import('../LandingPages/JsonTableData')
      );
      return (
        <>
          {section.sectionId == 'program-json-table' ? (
            <JsonTableData
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          ) : (
            <JsonTable
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          )}
        </>
      );
    case 'json_table_ui':
      return (
        <RenderJsonTableUI
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'tab_group_v2':
      return (
        <TabGroupV2
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );

    case 'artwork_text3_group_column':
      const ArtworkText3GroupColumn = dynamic(() =>
        import('@sections/ArtworkText3GroupColumn')
      );
      return (
        <ArtworkText3GroupColumn
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'usa_upcoming_program_embed':
      const ProgramFinder = dynamic(() =>
        import('@components/UI/Center/ProgramFinder')
      );
      const ProgramFinder_Volunteer = dynamic(() =>
        import('@components/UI/Volunteer/ProgramFinder_Volunteer')
      );
      const UpcomingProcesses = dynamic(() =>
        import('@components/UI/Center/UpcomingProcesses')
      );
      return (
        <>
          {section.sectionId == 'upcoming-processes' ? (
            <UpcomingProcesses />
          ) : section.sectionId == 'volunteer-general' ? (
            <ProgramFinder_Volunteer />
          ) : (
            <ProgramFinder region={region} />
          )}
        </>
      );
    case 'card_zigzag_group':
      // const CardZigzagGroup = dynamic(() => import('@components/Card/CardZigzagGroup'));
      return (
        <CardZigzagGroup
          section={section}
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'content_card':
      const LivingIshaCardContent = dynamic(() =>
        import('@sections/centers/LivingIshaCardContent')
      );
      return (
        <LivingIshaCardContent
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'filter_yoga_meditation_program_card':
      // const FilterYogaMeditationProgramCard = dynamic(() => import('@components/Card/FilterYogaMeditationProgramCard'));
      return (
        <FilterYogaMeditationProgramCard
          sectionContent={sectionContent}
          region={region}
          lang={language}
          cardType="yoga"
        />
      );
    case 'card_program3_column_group':
      // const CardProgram3ColumnGroup = dynamic(() => import('@components/Card/CardProgram3ColumnGroup'));
      return (
        <CardProgram3ColumnGroup
          programs={sectionContent.cards}
          region={region}
          lang={language}
          cardType={pType}
          pageConfig={pageConfig}
        />
      );
    case 'top_banner_video':
      // const TopBannerVideo = await import('@components/HeaderBanner/TopBannerVideo');
      return (
        <TopBannerVideo
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_horizontal_group':
      // const CardHorizontalGroup = dynamic(() => import('@components/Card/CardHorizontalGroup'));
      return (
        <CardHorizontalGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_custom_card_group':
      return (
        <CardCustomCardGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'topic_based_wisdom_content':
      // const TopicBasedWisdomContent = dynamic(() => import('@components/Sliders/TopicBasedWisdomContent'));
      return (
        <TopicBasedWisdomContent
          data={sectionContent}
          titleObj={sectionContent}
          isShareEnabled
          isLeftMenu={isLeftMenu}
        />
      );
    case 'volunteering_how_to_apply_step':
      return <VolunteeringHowToApplyStep sectionContent={sectionContent} />;
    case 'single_line_text':
      if (sectionContent?.style === 'tag-cloud') {
        return <TagCloud sectionContent={sectionContent} />;
      } else if (sectionContent.fieldId === 'ys-benefits-title') {
        return <YsBenefitsTitle sectionContent={sectionContent} />;
      } else if (sectionContent.fieldId === 'italian-style-text') {
        return <ItalianStyleText sectionContent={sectionContent} />;
      } else if (sectionContent.fieldId === 'fee-details') {
        return <FeeDetails sectionContent={sectionContent} />;
      }
      // const SingleLineText = dynamic(() => import('@components/UI/SingleLineText'));
      return <SingleLineText sectionContent={sectionContent} />;
    case 'generic_iframe':
      const GenericFrame = dynamic(() =>
        import('@components/Embeds/GenericFrame')
      );
      return <GenericFrame sectionContent={sectionContent} section={section} />;
    case 'faq':
      // const Faq = dynamic(() => import('@components/Accordions/FaqQuestionier'));
      return <Faq sectionContent={sectionContent} />;
    case 'faq_section_v2':
      // const FaqSectionV2 = dynamic(() => import('@components/Accordions/FaqSectionV2'));
      if (
        sectionContent?.style === 'volunteer-section' ||
        section?.sectionId == 'volunteer-section'
      ) {
        return (
          <MidContainer w="748">
            <FaqSectionV2Volunteer
              faqSection={sectionContent}
              region={region}
              lang={language}
            />
          </MidContainer>
        );
      } else if (section?.sectionId === 'yogasandhya-faq') {
        return (
          <YogaSandhyaFaq
            faqSection={sectionContent}
            region={region}
            lang={language}
          />
        );
      }
      return (
        <MidContainer w="768">
          <FaqSectionV2
            faqSection={sectionContent}
            region={region}
            lang={language}
          />
        </MidContainer>
      );
    case 'faq_expanded_section':
      // const FaqExpandedSection = dynamic(() => import('@components/UI/FaqExpandedSection'));
      return (
        <FaqExpandedSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
          sectionId={section}
        />
      );
    case 'accordion_section':
      // const AccordionSection = dynamic(() => import('@components/Accordions/AccordionSection'));
      return (
        <AccordionSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'accordion_v2':
      // const AccordionSection = dynamic(() => import('@components/Accordions/AccordionV2'));
      return (
        <AccordionV2
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_circular2_column_group':
      // const CardCircular2ColumnGroup = dynamic(() => import('@components/Card/CardCircular2ColumnGroup'));
      return (
        <CardCircular2ColumnGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'social_media_icon':
      const SocialMediaIcon = dynamic(() =>
        import('@components/UI/SocialMediaIcon')
      );
      return <SocialMediaIcon sectionContent={sectionContent} />;
    case 'wistia_video_embed':
      const WistiaVideoEmbed = dynamic(() =>
        import('@components/Widgets/Wistia/WistiaVideoEmbed')
      );
      return (
        <WistiaVideoEmbed
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'news_csr_embed':
      // const NewsCsrEmbed = dynamic(() => import('@components/Card/NewsCsrEmbed'));
      return (
        <NewsCsrEmbed
          sectionContent={sectionContent}
          region={region}
          lang={language}
          csrData={csrData}
        />
      );
    case 'in_the_news_slider':
      // const InTheNewsSlider = dynamic(() => import('@components/Sliders/InTheNewsSlider'));
      return (
        <InTheNewsSlider
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'research_project3_column_cards_filter_linked':
      // const ResearchProject3ColumnCardsFilterLinked = dynamic(() => import('@components/Card/ResearchProject3ColumnCardsFilterLinked'));
      return (
        <ResearchProject3ColumnCardsFilterLinked
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_research_publication3_column_group':
      // const CardResearchPublication3ColumnGroup = dynamic(() => import('@components/Card/CardResearchPublication3ColumnGroup'));
      return (
        <CardResearchPublication3ColumnGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'top_banner_without_image':
      // const TopBannerWithoutImage = await import('@components/HeaderBanner/TopBannerWithoutImage');
      return (
        <TopBannerWithoutImage
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'samskriti_red_box':
      // const SamskritiRedBox = dynamic(() => import('@components/UI/SamskritiRedBox'));
      return (
        <SamskritiRedBox
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'html_block':
      return <HtmlBlock sectionContent={sectionContent} />;
    case 'benefits_group':
      if (sectionContent.style === 'four-column') {
        return (
          <BenefitsFourColumn
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        );
      }
      // const BenefitsGroup = dynamic(() => import('../LandingPages/BenefitsGroup'));
      return (
        <BenefitsGroup
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'program_finder':
      return (
        <ProgramFinderComp
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'publications_listing_facade_placeholder_tab_group':
      return (
        <PubListingFacadePlaceholderTabGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'ad_banner_group':
      return (
        <AdBannerGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'publications_homepage_card':
      return (
        <PubHomePageCard
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
  }
};

const RenderIshangaComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  router,
  pageBody,
}) => {
  switch (sectionContent._modelApiKey) {
    /* Importing the TopBannerEvent component and then returning it. */
    case 'top_banner_event':
      // const TopBannerEvent = await import('@components/HeaderBanner/TopBannerEvent');
      return (
        <TopBannerEvent
          sectionContent={sectionContent}
          region={region}
          language={language}
          pageName={pageName}
        />
      );
    case 'top_kolam':
      // const TopKolam = dynamic(() => import('@components/Headings/TopKolam'));
      return <TopKolam sectionContent={sectionContent} />;
    case 'structured_text':
      return (
        <RenderStructuredText
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'section_title':
      // const SectionTitle = dynamic(() => import('@components/Headings/SectionTitle'));
      if (pageName === 'bog') {
        return <SectionTitle titleObj={sectionContent} fontSize="45px" />;
      }

      return (
        <SectionTitle
          titleObj={sectionContent}
          style={{
            fontStyle:
              section.sectionId === 'ishanga-desc' ||
                sectionContent.id == 142026206
                ? 'italic'
                : '',
            fontWeight:
              section.sectionId === 'ishanga-desc' ||
                sectionContent.id == 142026206
                ? '400 !important'
                : '500',
            fontSize:
              section.sectionId === 'ishanga-desc' ||
                sectionContent.id == 142026206
                ? '35px'
                : '30px',
            lineHeight:
              section.sectionId === 'ishanga-desc' ||
                sectionContent.id == 142026206
                ? '116%'
                : '',
            fontFamily:
              section.sectionId === 'ishanga-desc' ||
                sectionContent.id == 142026206
                ? 'FedraSansStd-book'
                : 'FedraSansStd-A-medium',
          }}
          pageName={pageName}
        />
      );
    case 'simple_button':
      return (
        <RenderSimpleButton
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'testimonials_group_v2':
      // const IshangaTestimonial = dynamic(() => import('@landing_pages/ishanga7/Testimonials'));
      return (
        <>
          {region !== 'us' && region !== 'ca' && (
            <Box padding="50px 0" mb="30px" background="#F5F0E5">
              <MidContainer>
                <IshangaTestimonials
                  width="auto"
                  height="190px"
                  testimonialsObj={sectionContent}
                  region={region}
                  lang={language}
                  background="#F5F0E5"
                  dots={true}
                />
              </MidContainer>
            </Box>
          )}
          {(region === 'us' || region === 'ca') && (
            <MidContainer>
              {/* <Box
                display="flex"
                marginTop="20px"
                border="2px"
                // background="#F5F0E5"
                padding="25px"
                borderColor="transparent"
              > */}
              <IshangaUsCaTest
                testimonialsObj={sectionContent}
                region={region}
                lang={language}
              // background="#F5F0E5"
              />
              {/* </Box> */}
            </MidContainer>
          )}
        </>
      );
    case 'card_info3_column_group':
      // const IshangaBenefits = dynamic(() => import('@landing_pages/ishanga7/Benefits'));
      if (region === 'us' || region === 'ca') {
        return (
          <BenefitsUsUk
            sectionContent={sectionContent}
            region={region}
            lang={language}
            pageBody={pageBody}
          />
        );
      } else {
        return (
          <IshangaBenefits
            sectionContent={sectionContent}
            region={region}
            lang={language}
            pageBody={pageBody}
          />
        );
      }
    case 'single_image':
      // const IshangaSingleImage = dynamic(() => import('@landing_pages/ishanga7/SingleImage'));
      return (
        <IshangaSingleImage
          className={sectionContent._modelApiKey}
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'quote':
      // const IshangaQuote = dynamic(() => import('@landing_pages/ishanga7/Quote'));
      return <IshangaQuote sectionContent={sectionContent} />;
    case 'benefits_group':
      // const BenefitsGroup = dynamic(() => import('../LandingPages/BenefitsGroup'));
      return (
        <BenefitsGroup
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'media_embed':
      const MediaEmbed = dynamic(() => import('@components/Embeds/MediaEmbed'));
      return <MediaEmbed sectionContent={sectionContent} />;
    case 'media_embed_v2':
      const MediaEmbedV2 = dynamic(() =>
        import('@components/Embeds/MediaEmbedV2')
      );
      return <MediaEmbedV2 sectionContent={sectionContent} />;
    case 'google_map':
      const GoogleMap = dynamic(() => import('@components/Embeds/GoogleMap'));
      return <GoogleMap sectionContent={sectionContent} />;
    case 'faq_expanded_section':
      // const FaqExpandedSection = dynamic(() => import('@components/UI/FaqExpandedSection'));
      return (
        <FaqExpandedSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
          sectionId={sectionId}
        />
      );
  }
};

const RenderJsonTableUI = ({ sectionContent, region, language }) => {
  if (sectionContent?.fieldId === 'youtube-video-3column') {
    const JsonTableUIYouTubeVideo3Column = dynamic(() =>
      import('@components/Tables/JsonTableUIYouTubeVideo3Column')
    );
    return (
      <JsonTableUIYouTubeVideo3Column
        sectionContent={sectionContent}
        region={region}
        lang={language}
      />
    );
  } else {
    // const JsonTableUI = dynamic(() => import('@components/Tables/JsonTableUI'));
    return (
      <Box my={10}>
        <JsonTableUI
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      </Box>
    );
  }
};

const RenderSimpleButton = ({
  sectionContent,
  section,
  region,
  lang,
  // disableButton
  pageName,
  pageConfig,
  csrData
}) => {
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    if (!csrData?.length > 0) {
      setDisableButton(true);
    }
  }, [csrData])

  // const SimpleButton = dynamic(() => import('@components/Buttons/SimpleButton'));
  if (
    section?.sectionId &&
    section?.sectionId === IsoApiConstants.sectionId.healthButtonLoginPopup
  ) {
    return (
      <SimpleButton
        style={sectionContent}
        bType={section.sectionId}
        region={region}
        lang={lang}
      />
    );
  } else {
    return (
      <SimpleButton
        style={sectionContent}
        region={region}
        lang={lang}
        disableButton={disableButton}
        pageConfig={pageConfig}
      />
    );
  }
};

const RenderStructuredText = ({ sectionContent, region, lang, position }) => {
  if (
    sectionContent.fieldId &&
    sectionContent.fieldId === IsoApiConstants.fieldId.largeBrownBulletsHealth
  ) {
    // const LargeBrownBullets = dynamic(() => import('@components/UI/Health/LargeBrownBullets'));
    return <LargeBrownBullets data={sectionContent.body} />;
  } else if (sectionContent?.style === 'brown-spiral-bullets') {
    return <BrownSpiralBullets sectionContent={sectionContent} />;
  } else if (
    sectionContent.fieldId &&
    sectionContent.fieldId === 'flexible-donation-option'
  ) {
    const FlexibleDonations = dynamic(() =>
      import('@landing_pages/ishanga7/FlexibleDonations')
    );
    return (
      <FlexibleDonations
        sectionContent={sectionContent.body}
        region={region}
        lang={lang}
      />
    );
  } else {
    return (
      <MidContainer>
        <Box className="event-text">
          <ContentArea
            className={sectionContent.id == 142469800 ? 'linkTextStyle' : ''}
            mainContent={sectionContent.body}
            tAlign={sectionContent.style}
            region={region}
            lang={lang}
            sectionContent={sectionContent}
            position={position}
          />
          <style>
            {`
              .linkTextStyle a{
                color:#CF4520;
              }
            `}
          </style>
        </Box>
      </MidContainer>
    );
  }
};

const RenderGallery = ({ sectionContent, region, lang, section }) => {
  if (sectionContent.galleryType === 'without_hero_image') {
    // const SliderGallery = dynamic(() => import('@components/Sliders/SliderGallery'));
    return <SliderGallery sectionContent={sectionContent} />;
  } else if (
    sectionContent.galleryType === 'slider_single_image' &&
    sectionContent.fieldId === 'why-books-gallery'
  ) {
    // const SliderGallery = dynamic(() => import('@components/Sliders/SliderGallery'));
    return <WhyBooksSlider sectionContent={sectionContent} />;
  } else if (sectionContent.galleryType === 'slider_single_image') {
    const SliderSingleImage = dynamic(() =>
      import('@components/Sliders/SliderSingleImage')
    );
    return <SliderSingleImage sectionContent={sectionContent} />;
  } else if (sectionContent.galleryType === 'with_hero_image') {
    // const ImageGallery = dynamic(() => import('@components/Sliders/ImageGallery'));
    return <ImageGallery sectionContent={sectionContent} section={section} />;
  } else if (
    sectionContent.galleryType === 'slider' &&
    sectionContent.style === 'yoga-sandhya-brown'
  ) {
    return <YogaSandhyaBrownGallery sectionContent={sectionContent} />;
  } else {
    return (
      <Box display="flex" marginTop="80px">
        <Image loading="lazy" Gallery sectionContent={sectionContent} />
      </Box>
    );
  }
};

export default SectionUI;
