/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders Loader Component
 *
 * @param {string} pos - Position of the Loader Component
 * @returns {ReactElement} Loader Component
 */

const Loader = ({ pos, top, bg, left, mt }) => {
  return (
    <Box
      position="relative"
      minHeight="400px"
      background={bg || 'rgba(255, 255, 255, 0.9)'}
    >
      <Box
        position="absolute"
        top={top || '0'}
        left={left || '46%'}
        zIndex="50"
        marginTop={mt || '0'}
      >
        <Box as="span" className="loader"></Box>
        <style>
          {`.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 1s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
  }
  66% {
    background: #FF3D00;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
  }
}`}
        </style>
      </Box>
    </Box>
  );
};

export default Loader;
