/* Builtin Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  HStack,
  Hide,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Show,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
// hooks
import { useProgramContext } from '@components/UI/ProgramFinder/ProgramFinderStore';
import { useDebounce } from '@hooks/useDebounce';
import { nanoid } from 'nanoid';

/**
 *
 */

const FilterMain = ({
  data,
  setMobFilterActive,
  mobFilterActive,
  search,
  tag,
  setSearch,
  userLocation,
  onLocation,
}) => {
  const {
    suggestionValues,
    getFilters,
    filterObjCopy,
    filtersCount,
    setIsFreeTextSearch,
    setFilterObjCopy,
    filters,
    setFilters,
    showFree,
    setShowFree,
  } = useProgramContext();
  const router = useRouter();
  const [obj, setObj] = useState(null);
  useEffect(() => {
    if (filters) {
      [...filters]?.map(el => {
        if (el?.activeFilter) {
          setObj(el || obj);
        }
      });
    }
  }, [router?.query?.activeFilter]);
  // console.log(Number((obj?.activeFilter / 100).toFixed()));
  const [count, setCount] = useState(0);
  let values = !filtersCount
    ? 0
    : Object.values(filtersCount)
        .filter(el => !isNaN(el))
        .reduce((a, b) => a + b);

  // const values = 0;
  // console.log(
  //   isArray(filtersCount),
  //   values,
  //   filtersCount,
  //   'filterscoutn',
  //   isNaN(undefined),
  //   Number(undefined),
  //   parseInt(null)
  // );
  const [suggestions, setSuggestions] = useState(null);
  const debouncedVal = useDebounce(suggestions, 300);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: '767px' });
  const [queryObj, setQueryObj] = useState(null);
  useEffect(() => {
    if (search == '') {
      setSuggestions(null);
      setShowSearchIcon(true);
    }
  }, [search]);

  useEffect(() => {
    if (
      router.isReady &&
      router.query?.freeTextSearch &&
      !router.query?.search
    ) {
      setShowSearchIcon(false);
    }
  }, [router.isReady]);
  function handleFree() {
    if (!showFree) {
      filterObjCopy.activeFilter = 'none';
      filterObjCopy.price = {
        showOnlyFree: 'Show free programs',
        isChecked: true,
      };
      setShowFree(true);
      window.dataLayer.push({
        event: 'pf_show_free',
      });
      // console.log(active, 'active', router, filterObjCopy, 'filterobj copy');
    } else if (filterObjCopy?.price || showFree) {
      setShowFree(false);
      delete filterObjCopy.price;
      router.query.free = '';
    }

    //send to context
    getFilters(filterObjCopy);
  }
  //show suggestions on textBox changes and handle checkboxes
  const handleChange = e => {
    if (Object.keys(e.target).includes('checked')) {
      //if event is from checkbox

      //for show only online
      filterObjCopy['onlineInperson'].forEach(item => {
        if (item[Object.keys(item)[0]] == e.target.name) {
          item.isChecked = e.target.checked;
          filterObjCopy.activeFilter =
            Object.keys(filterObjCopy).indexOf('onlineInperson') * 100 + 100;
          window.dataLayer.push({
            event: 'pf_show_online',
          });
        }
      });

      //for show only free
      if (e.target.name == 'showOnlyFree' && e.target.checked) {
        filterObjCopy.activeFilter = 'none';
        filterObjCopy.price = {
          showOnlyFree: 'Show free programs',
          isChecked: e.target.checked,
        };

        window.dataLayer.push({
          event: 'pf_show_free',
        });
      } else if (
        e.target.name == 'showOnlyFree' &&
        e.target.checked == false &&
        filterObjCopy?.price
      ) {
        delete filterObjCopy.price;
      }

      //send to context
      getFilters(filterObjCopy);
    } else {
      //if event is from textBox
      setSearch(e.target.value);

      //check if value exists in suggestionValues
      let temp = []; //for showing closest match first
      let tempSuggestions = suggestionValues.filter(obj => {
        let index = obj.name
          ?.toLowerCase()
          ?.indexOf(e.target.value?.toLowerCase());

        if (index > -1) {
          temp.push({ ...obj, index });
          return true;
        }
        return false;
      });

      //sorting based on index found
      let compare = (a, b) => {
        if (a.index == b.index) {
          return 0;
        } else if (a.index > b.index) {
          return 1;
        } else if (a.index < b.index) {
          return -1;
        }
      };

      temp.sort(compare);

      if (tempSuggestions.length) {
        //fixing autosuggest to 10
        if (tempSuggestions.length > 10) {
          temp = temp.splice(0, 10);
        }
        setSuggestions(temp);
      } else {
        setSuggestions(null);
      }
      // console.log({ suggestions, suggestionValues, search });
    }
  };

  //handles autosuggestion focus through keys
  const handleFocus = e => {
    const { key } = e;

    if (key == 'Escape') {
      setSuggestions(null);
      setSearch('');
      setActiveSuggestion(-1);
    } else if (key == 'ArrowDown') {
      if (suggestions?.length - 1 >= activeSuggestion + 1) {
        setActiveSuggestion(prev => {
          return prev + 1;
        });
      } else {
        setActiveSuggestion(-1);
      }
    } else if (key == 'ArrowUp') {
      if (activeSuggestion > 0) {
        setActiveSuggestion(prev => {
          return prev - 1;
        });
      } else {
        setActiveSuggestion(-1);
      }
    } else if (key == 'Enter' && activeSuggestion > -1) {
      // console.log('enter from popover ');

      handleClick(suggestions[activeSuggestion]);
      setActiveSuggestion(-1);
      setSuggestions(null);
    }
    // console.log(key, name, suggestions?.length, activeSuggestion + 1);
  };

  const changeFilterObjCopy = (suggestionObj, removeTag = false) => {
    let flag = removeTag ? false : true;

    // uncheck filter on old tag unmount
    if (tag?.isLanguage) {
      Object.keys(filterObjCopy?.language).forEach(key => {
        Object.keys(filterObjCopy.language[key]).forEach(lang => {
          // console.log(lang == tag.name);
          if (lang == tag.name) {
            filterObjCopy.language[key][lang] = {
              ...filterObjCopy.language[key][lang],
              isChecked: false,
            };
          }
        });
      });
    } else if (tag?.isProgramType) {
      filterObjCopy?.programType.forEach(progCategory => {
        progCategory['subPrograms']?.forEach(prog => {
          //parent prog
          if (prog?.id == tag?.id) {
            prog.isChecked = false;
          }

          //child prog

          prog['subPrograms']?.forEach(childProg => {
            if (childProg?.id == tag?.id) {
              childProg.isChecked = false;
            }
          });
        });
      });
    } else if (tag?.isCenter) {
      filterObjCopy?.location?.center.forEach(item => {
        if (item?.centerName == tag?.name) {
          item.isChecked = false;
        }
      });
    }
    else if (tag?.isState) {
      filterObjCopy?.location?.state.forEach(item => {
        if (Object.keys(item)[0] == tag?.name) {
          item.isChecked = false;
        }
      });
    }
    else if (tag?.isCountry) {
      filterObjCopy?.location?.country.forEach(item => {
        if (Object.keys(item)[0] == tag?.name) {
          item.isChecked = false;
        }
      });
    } else if (tag?.isCity) {
      filterObjCopy?.location?.city.forEach(item => {
        if (item?.city == tag?.name) {
          item.isChecked = false;
        }
      });
    }
    //for programType
    if (suggestionObj?.isProgramType && filterObjCopy?.programType) {
      filterObjCopy?.programType.forEach(progCategory => {
        progCategory['subPrograms']?.forEach(prog => {
          //parent prog
          if (prog?.id == suggestionObj?.id) {
            prog.isChecked = flag;
          }

          //child prog

          prog['subPrograms']?.forEach(childProg => {
            if (childProg?.id == suggestionObj?.id) {
              childProg.isChecked = flag;
            }
          });
        });
      });
    }

    //for location
    if (suggestionObj?.isCity) {
      filterObjCopy?.location?.city?.forEach(item => {
        if (item?.city == suggestionObj.name) {
          // check current city

          item.isChecked = flag;
          // uncheck all countries
          uncheckAllChildren('location', 'country');
        } else {
          // uncheck all except current city
          item.isChecked = false;
        }
      });
    } 
    else if (suggestionObj?.isState) {
      filterObjCopy?.location?.state?.forEach(item => {
        if (Object.keys(item)[0] == suggestionObj.name) {
          // check current state

          item.isChecked = flag;
          item.state = Object.keys(item)[0];

          // uncheck all countries
          uncheckAllChildren('location', 'country');
        } else {
          // uncheck all country except current
          item.isChecked = false;
        }
      });
    }
    else if (suggestionObj?.isCountry) {
      filterObjCopy?.location?.country?.forEach(item => {
        if (Object.keys(item)[0] == suggestionObj.name) {
          // check current country

          item.isChecked = flag;
          item.country = Object.keys(item)[0];
          //uncheck all cities
          uncheckAllChildren('location', 'city');

          //uncheck all centers
          uncheckAllChildren('location', 'center');

          //uncheck all states
          uncheckAllChildren('location', 'state');

        } else {
          // uncheck all country except current
          item.isChecked = false;
        }
      });
    } else if (suggestionObj?.isCenter) {
      filterObjCopy?.location?.center?.forEach(item => {
        if (item?.centerName == suggestionObj.name) {
          item.isChecked = flag;
          //uncheck all country
          uncheckAllChildren('location', 'country');
        }
      });
    }

    //for language
    if (suggestionObj?.isLanguage && filterObjCopy?.language) {
      Object.keys(filterObjCopy?.language).forEach(key => {
        Object.keys(filterObjCopy.language[key]).forEach(lang => {
          // console.log(lang);
          if (
            lang == suggestionObj.name &&
            typeof filterObjCopy.language[key][lang] == 'string'
          ) {
            filterObjCopy.language[key][lang] = {
              [lang]: filterObjCopy.language[key][lang],
              lang: filterObjCopy.language[key][lang],
              isChecked: flag,
            };
          } else if (lang == suggestionObj.name) {
            filterObjCopy.language[key][lang] = {
              ...filterObjCopy.language[key][lang],
              isChecked: flag,
            };
          }
        });
      });
    }

    return filterObjCopy;
  };

  //send selected suggestion to store on popover item click
  const handleClick = suggestionObj => {
    let modifiedFilterObjCopy = changeFilterObjCopy(suggestionObj);
    filterObjCopy.tag = suggestionObj;
    getFilters(modifiedFilterObjCopy, false, false);

    // close suggestions

    setSearch('');
    setActiveSuggestion(-1);
    setSuggestions(null);
  };

  const handleFreeTextSearch = (e, revertChanges = false) => {
    // let cards = finalCards?.length > 0 ? finalCards : storedPrograms;
    // let cards = storedPrograms;

    if (filterObjCopy?.tag) {
      delete filterObjCopy.tag;
    }

    if (revertChanges) {
      setSearch('');
      setShowSearchIcon(true);
      setIsFreeTextSearch(false);
      delete filterObjCopy.freeTextSearch;
      getFilters(filterObjCopy);
      return;
    }

    if ((e.key === 'Enter' || e.type === 'click') && activeSuggestion == -1) {
      filterObjCopy.freeTextSearch = search?.toLowerCase();
      getFilters(filterObjCopy);
      setShowSearchIcon(false);
      // setSearch('');
      setSuggestions(null);
    }
  };

  const removeTag = suggestionObj => {
    delete filterObjCopy.tag;
    getFilters(changeFilterObjCopy(suggestionObj, true));
  };

  const uncheckAllChildren = (pKey, cKey) => {
    filterObjCopy[pKey][cKey]?.forEach(item => {
      item.isChecked = false;
    });
  };

  const removePill = (filter, index) => {
    // filters.splice(index, 1);
    // console.log('filter', filter, index, filters);
    // getFilters(filterObjCopy);
  };
  // const unCheckChild = tag => {
  //   // filters array with filtered tag subprogram
  //   const childTags = [];
  //   if (childTags) {
  //     [...tag.subPrograms].map(el => {
  //       childTags.push({ ...el, isChecked: false });
  //     });
  //   }
  //   let childFilterd = [];
  //   if (filters?.length) {
  //     childFilterd = [...filters]?.filter(
  //       el => el.parentId !== tag.id && el?.isChecked
  //     );
  //   }
  //   // if (childTags && childFilterd) {
  //   setFilters(childFilterd.concat(childTags));
  //   const unchecked = [...filters].slice().filter(el => el.isChecked);
  //   setFilters(unchecked);
  //   console.log(filters, childFilterd, childTags, 'filters');
  //   // }
  // };
  const deleteFilter = tag => {
    let tagId = tag.id;
    tag.isChecked = false;
    setFilters(filters.slice().filter(el => el?.isChecked));
    // console.log(Object.values(tag)[0], 'value');
    if (tag?.subPrograms) {
      const fitlered = [...filters]?.map(filter =>
        filter?.parentId === tag?.id
          ? { ...filter, isChecked: false, isParentChecked: false }
          : filter
      );
      setFilters(fitlered);
      // filterObjCopy?.programType.forEach
      // setFilters(filters.filter(el => el?.isChecked));
      // );
      // console.log(fitlered, 'filtelhtowehuo', filters);
      // const obj = { ...filterObjCopy };
      filterObjCopy?.programType?.forEach((item, index) => {
        item.subPrograms.map(sub => {
          if (sub.id === tag.id) {
            sub.isChecked = false;
            sub?.subPrograms &&
              sub?.subPrograms.map(sup => {
                sup.isChecked = false;
              });
          }
        });
      });
      getFilters(filterObjCopy);
      // obj.programType[]
      // setFilterObjCopy(obj);
    }
  };
  useEffect(() => {
    // console.log(filters, 'use effect', filterObjCopy);
    // if (filters?.length) {
    //   setFilters(filters?.filter(el => el?.isChecked));
    // }
  }, [filters, filterObjCopy]);
  // console.log(
  //   filterObjCopy,
  //   filtersCount?.location,
  //   userLocation,
  //   'filter object copy'
  // );
  // console.log(filters, 'filters');
  return (
    <Box as="div">
      <Flex
        align="center"
        paddingLeft={{ base: '48px', md: '20px' }}
        paddingRight={{ base: '10px' }}
        mt={{ base: '15px', md: '0' }}
        w={{ base: '95%', md: 'auto' }}
        h={{ base: 'auto', md: 'auto' }}
        mx="auto"
        background="#FFFFFF"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.04), 0px 12px 14px rgba(0, 0, 0, 0.0425185), 0px 1.38519px 0px rgba(0, 0, 0, 0.0274815)"
        borderRadius="29px"
        pos="relative"
        fontFamily="FedraSansStd-book"
        gridGap="10px"
        minH={{ base: '42px', md: '58px' }}
      >
        {/* tag on popover selection */}
        {tag && (
          <Flex
            align="center"
            borderRadius="26.5px"
            bg="#D04723"
            color="white"
            // me="1rem"
            my="10px"
            ml="10px"
            gridGap="10px"
            flexGrow="1"
            flexShrink="0"
            maxW={{ base: '90%', sm: '95%' }}
            p={{ base: '0.2rem 1rem', md: '0.5rem 1rem' }}
          >
            <Box overflow="hidden" textOverflow=" ellipsis" whiteSpace="nowrap">
              {tag?.name}
            </Box>
            <Box
              cursor="pointer"
              onClick={() => {
                removeTag(tag);
              }}
            >
              <AiOutlineClose />
            </Box>
          </Flex>
        )}
        <Input
          placeholder={tag ? '' : data?.json?.searchPlaceholder}
          fontSize={{ base: '16px', md: '18px' }}
          w="100%"
          minW={{ base: '', md: '300px' }}
          h={{ base: '20px', md: '44px' }}
          border="none"
          outline="none"
          focusBorderColor="white"
          color="#676055"
          onKeyDown={handleFreeTextSearch}
          onChange={handleChange}
          onKeyUp={handleFocus}
          value={search}
          minHeight="42px"
        />
        {/* search icon */}
        <Box
          pos="absolute"
          right={{ base: 'initial', md: '25px' }}
          left={{ base: '20px', md: 'initial' }}
          top={{ base: '7px', md: '15px' }}
          cursor="pointer"
          onClick={handleFreeTextSearch}
          display={
            showSearchIcon
              ? 'block'
              : !showSearchIcon && isMobile
              ? 'block'
              : 'none'
          }
          _hover={{ color: '#BB3E03' }}
        >
          <Icon size="28px" w="28px" height="26px" viewBox="0 0 28px 26px">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.2394 26C24.5693 26 23.8993 25.74 23.3688 25.246L19.2088 21.398C18.1199 20.41 18.1478 18.824 19.2088 17.836C20.2976 16.822 21.9449 16.822 23.0338 17.836L27.1938 21.71C28.2547 22.698 28.2827 24.284 27.1938 25.246C26.6075 25.766 25.9095 26 25.2394 26ZM10.9166 3.12C8.90638 3.12 6.95201 3.874 5.55602 5.174C4.16004 6.474 3.35037 8.294 3.35037 10.166C3.35037 12.038 4.16004 13.832 5.55602 15.158C6.95201 16.458 8.90638 17.212 10.9166 17.212C12.9268 17.212 14.8533 16.484 16.2772 15.158C16.9793 14.5019 17.5356 13.7232 17.914 12.8666C18.2925 12.01 18.4858 11.0923 18.4828 10.166C18.4828 8.294 17.6732 6.474 16.2772 5.174C14.7974 3.796 12.9268 3.12 10.9166 3.12ZM10.9166 20.254C7.95711 20.254 5.27682 19.214 3.21077 17.264C2.19277 16.3249 1.38485 15.2072 0.833725 13.9754C0.282605 12.7437 -0.000767519 11.4224 1.56128e-06 10.088C1.56128e-06 7.41 1.14471 4.862 3.15493 2.938C5.16515 0.988 7.95711 0 10.9166 0C13.8482 0 16.5285 1.066 18.6224 2.99C19.6383 3.93056 20.4449 5.04859 20.9958 6.28C21.5468 7.5114 21.8314 8.83197 21.8332 10.166C21.8332 12.844 20.7164 15.392 18.6224 17.342C16.5285 19.292 13.7923 20.254 10.9166 20.254Z"
              fill="#676055"
            />
          </Icon>
        </Box>

        <Box
          pos="absolute"
          right={{ base: '20px', md: '25px' }}
          left={{ base: 'initial', md: 'initial' }}
          top={{ base: '12px', md: '20px' }}
          cursor="pointer"
          onClick={e => {
            handleFreeTextSearch(e, true);
          }}
          display={showSearchIcon ? 'none' : 'block'}
        >
          <AiOutlineClose size="20px" />
        </Box>
        {Array.isArray(debouncedVal) && debouncedVal.length && (
          <Box
            className="searchPopFullWidth"
            pos="absolute"
            bottom="0"
            bg="#E4DED4"
            width="100%"
            left={{ base: '20px', md: 'inherit' }}
          >
            <Popover autoFocus={false} isOpen={debouncedVal.length > 0}>
              <PopoverContent border="none">
                <PopoverArrow />

                <PopoverBody bg="#E4DED4" p="0 0 10px 0" m="0">
                  {debouncedVal?.map((suggestion, index) => {
                    return (
                      <Box
                        key={index}
                        // pl="0.5rem"
                        p="5px 13px"
                        fontFamily="FedraSansStd-book"
                        fontSize="1rem"
                        bg={activeSuggestion == index ? '#d04723' : 'none'}
                        color={activeSuggestion == index ? 'white' : 'black'}
                        _hover={{
                          backgroundColor: '#d04723 !important',
                          color: 'white',
                        }}
                        onKeyUp={handleFocus}
                        onClick={() => {
                          handleClick(suggestion);
                        }}
                        cursor="pointer"
                      >
                        {suggestion.name}
                      </Box>
                    );
                  })}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        )}
      </Flex>

      {/* mobile filter list with its icon */}

      <Flex
        mt="0.5rem"
        py={{ base: '8px', md: '0px' }}
        gridGap="0.5rem"
        overflow="auto"
        w="100vw"
        align="center"
        display={
          !isNaN(parseInt(mobFilterActive))
            ? 'none'
            : { base: 'flex', md: 'none' }
        }
      >
        {/* mobile filter icon */}
        <Flex
          ms="0.7rem"
          backgroundColor="#F5F0E5"
          width="40px"
          height="40px"
          p="9px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          cursor="pointer"
          borderRadius="50%"
          pos="relative"
          onClick={e => {
            e.stopPropagation();
            setMobFilterActive(
              // obj?.activeFilter
              Number((obj?.activeFilter / 100 - 1).toFixed()) ||
                mobFilterActive ||
                0
              // : 0
            );
          }}
          _before={{
            content: `'${values}'`,
            background: '#D04723',
            padding: '5px',
            color: 'white',
            fontSize: '12px',
            pos: 'absolute',
            top: '-7.5px',
            right: '-7.5px',
            rounded: 'full',
            width: '25px',
            height: '25px',
            textAlign: 'center',
            display: values > 0 ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'FedraSansStd-book',
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.675 18V12.375H9.175V14.45H18V15.95H9.175V18H7.675ZM0 15.95V14.45H6.175V15.95H0ZM4.675 11.8V9.75H0V8.25H4.675V6.15H6.175V11.8H4.675ZM7.675 9.75V8.25H18V9.75H7.675ZM11.825 5.625V0H13.325V2.05H18V3.55H13.325V5.625H11.825ZM0 3.55V2.05H10.325V3.55H0Z"
              fill="#676055"
            />
          </svg>
        </Flex>
        {/* {!filtersCount?.location && ( */}
        <Button
          value="nearMe"
          className="orange-button"
          // mb={'30px'}
          display={'block'}
          justifyContent="center"
          alignItems="center"
          fontWeight={'350'}
          fontFamily={'FedraSansStd-book'}
          fontSize={'12px'}
          h={'auto'}
          cursor={'pointer'}
          textAlign={'center'}
          padding={'13px 10px'}
          // color="#faf7f0"
          border="0px"
          textDecoration="none"
          width={'auto'}
          maxH="45.2px"
          rounded="full"
          _hover={{
            bg: !userLocation?.browser ? '#DCD5C3' : '#d04723',
            color: !userLocation?.browser ? '#676055' : '#fff',
          }}
          _active={{ border: '0px' }}
          name="nearMePrograms"
          backgroundColor={!userLocation?.browser ? '#EBE7DC' : '#D04723'}
          color={!userLocation?.browser ? '#676055' : '#ffffff'}
          onClick={onLocation}
        >
          {data?.json?.showPgmNearMe ? data.json.showPgmNearMe : 'Show Near me'}
        </Button>
        {/* )} */}
        <Button
          backgroundColor={filtersCount?.showOnlyFree ? '#D04723' : '#EBE7DC'}
          value="free"
          className="orange-button"
          // mb={'30px'}
          display={'block'}
          justifyContent="center"
          alignItems="center"
          fontWeight={'350'}
          fontFamily={'FedraSansStd-book'}
          fontSize={'12px'}
          h={'auto'}
          cursor={'pointer'}
          textAlign={'center'}
          padding={'13px 10px'}
          // color="#faf7f0"
          border="0px"
          _active={{ border: '0px' }}
          textDecoration="none"
          width={'auto'}
          maxH="45.2px"
          rounded="full"
          _hover={{
            bg: !filtersCount?.showOnlyFree ? '#DCD5C3' : '#d04723',
            color: !filtersCount?.showOnlyFree ? '#676055' : '#fff',
          }}
          onClick={handleFree}
          isChecked={filterObjCopy?.price ? true : false}
          name="showOnlyFree"
          color={filtersCount?.showOnlyFree ? '#ffffff' : '#676055'}
        >
          {data?.json?.showOnlyFree
            ? data.json.showOnlyFree
            : ' Show only free'}
        </Button>
        {/* <Flex gridGap="0.5rem" fontFamily="FedraSansStd-book" fontSize="14px">
          {filterObjCopy &&
            Object.keys(filterObjCopy).map((key, index) => {
              if (Object.keys(data.json).includes(key)) {
                return (
                  <Flex
                    key={index}
                    background={
                      filtersCount &&
                      Object.keys(filtersCount).includes(key) &&
                      filtersCount[key]
                        ? '#BB3E03'
                        : '#F3F3F3'
                    }
                    color={
                      filtersCount &&
                      Object.keys(filtersCount).includes(key) &&
                      filtersCount[key]
                        ? 'white'
                        : 'black'
                    }
                    borderRadius="50px"
                    border="none"
                    p="5px 12px"
                    whiteSpace="nowrap"
                    lineHeight="18px"
                    align="center"
                    justify="center"
                    onClick={() => {
                      setMobFilterActive(index);
                    }}
                  >
                    <Box>
                      {data.json[key]}{' '}
                      {filtersCount &&
                        Object.keys(filtersCount).includes(key) &&
                        filtersCount[key] &&
                        ` (${filtersCount[key]})`}
                    </Box>
                    <RiArrowDropDownLine size="30px" />
                  </Flex>
                );
              }
            })}
        </Flex> */}
      </Flex>

      <Flex
        gridGap="1.2rem"
        align="center"
        mt={{ base: '10px', md: '16px' }}
        mb={{ base: '25px', md: '20px' }}
        fontFamily="FedraSansStd-book"
        px={{ base: '15px', md: '0px' }}
        className="checkDesign"
        display={{ base: 'none', md: 'flex' }}
      >
        {/* <Checkbox
          value="online"
          isChecked={filterObjCopy?.onlineInperson[0]?.isChecked ? true : false}
          name="onlinePrograms"
          onChange={handleChange}
        >
          {data?.json?.showOnlyOnline
            ? data.json.showOnlyOnline
            : ' Show only online'}
        </Checkbox>
        <Checkbox
        value="free"
        isChecked={filterObjCopy?.price ? true : false}
        name="showOnlyFree"
        onChange={handleChange}
        >
        {data?.json?.showOnlyFree
          ? data.json.showOnlyFree
          : ' Show only free'}
        </Checkbox> */}
        {/* <CustomCheckbox
          value="free"
          isChecked={filterObjCopy?.price ? true : false}
          name="showOnlyFree"
          onChange={handleChange}
        >
          {data?.json?.showOnlyFree
            ? data.json.showOnlyFree
            : ' Show only free'}
        </CustomCheckbox> */}
        {/* {!filtersCount?.location && ( */}
        <Button
          value="nearMe"
          className="orange-button"
          // mb={'30px'}
          display={'block'}
          justifyContent="center"
          alignItems="center"
          fontWeight={'400'}
          fontFamily={'FedraSansStd-book'}
          fontSize={'14px'}
          h={'auto'}
          cursor={'pointer'}
          textAlign={'center'}
          padding={'13px 10px'}
          // color="#faf7f0"
          border="0px"
          textDecoration="none"
          width={'auto'}
          maxH="45.2px"
          rounded="full"
          _hover={{
            bg: !userLocation?.browser ? '#DCD5C3' : '#d04723',
            color: !userLocation?.browser ? '#676055' : '#fff',
          }}
          _active={{ border: '0px' }}
          //  isChecked={filterObjCopy?.onlineInperson[0]?.isChecked ? true : false}
          name="nearMePrograms"
          backgroundColor={!userLocation?.browser ? '#EBE7DC' : '#D04723'}
          color={!userLocation?.browser ? '#676055' : '#ffffff'}
          // style={{
          //   buttonStyle: 'Program Finder Button',
          //   buttonText: ' Show Near me',
          // }}
          onClick={onLocation}
        >
          {data?.json?.showPgmNearMe ? data.json.showPgmNearMe : 'Show Near me'}
        </Button>
        {/* )} */}
        <Button
          backgroundColor={filtersCount?.showOnlyFree ? '#D04723' : '#EBE7DC'}
          value="free"
          className="orange-button"
          // mb={'30px'}
          display={'block'}
          justifyContent="center"
          alignItems="center"
          fontWeight={'400'}
          fontFamily={'FedraSansStd-book'}
          fontSize={'14px'}
          h={'auto'}
          cursor={'pointer'}
          textAlign={'center'}
          padding={'13px 10px'}
          // color="#faf7f0"
          border="0px"
          _active={{ border: '0px' }}
          textDecoration="none"
          width={'auto'}
          maxH="45.2px"
          rounded="full"
          _hover={{
            bg: !filtersCount?.showOnlyFree ? '#DCD5C3' : '#d04723',
            color: !filtersCount?.showOnlyFree ? '#676055' : '#fff',
          }}
          onClick={handleFree}
          isChecked={filterObjCopy?.price ? true : false}
          name="showOnlyFree"
          color={filtersCount?.showOnlyFree ? '#ffffff' : '#676055'}
          // onClick={showFree}
          // py="12px"
          // style={{
          //   buttonStyle: 'Program Finder Button',
          //   buttonText: data?.json?.showOnlyFree
          //     ? data.json.showOnlyFree
          //     : ' Show only free',
          // }}
        >
          {data?.json?.showOnlyFree
            ? data.json.showOnlyFree
            : ' Show only free'}
        </Button>
      </Flex>
      {filters?.length > 0 && (
        <>
          <Show above="md">
            <Wrap mb="22px" spacingX="8px" spacingY="16px">
              {filters?.map((tag, index) => {
                if (tag?.country) {
                  const keys = Object.keys(tag).splice(0, 1).join('');
                  // console.log(keys, filters, '===>country');
                  return (
                    <Flex key={nanoid()}>
                      <Tag
                        px="9px"
                        py="4px"
                        fontFamily="fedraSansStd-book"
                        size={'lg'}
                        borderRadius="full"
                        variant="solid"
                        backgroundColor="#D04723"
                        color="#fff"
                        fontSize="14px"
                        fontStyle="normal"
                        lineHeight="normal"
                        _hover={{
                          bg: '#92361E',
                          color: '#fff',
                        }}
                        _active={{ bg: '#92361E', color: '#fff' }}
                        _focus={{ bg: '#92361E', color: '#fff' }}
                      >
                        <TagLabel>{keys}</TagLabel>
                        <TagCloseButton
                          color="#28231E"
                          background="#fff"
                          _hover={{
                            background: '#fff',
                          }}
                          _focus={{ background: '#fff' }}
                          opacity={1}
                          mr="2px"
                          onClick={() => {
                            deleteFilter(tag);
                          }}
                        />
                      </Tag>
                    </Flex>
                  );
                } else if (tag?.lang) {
                  const keys = Object.keys(tag).splice(1, 1).join('');
                  // console.log(keys, filters, '===>country');
                  return (
                    <Flex key={nanoid()}>
                      <Tag
                        px="9px"
                        py="4px"
                        fontFamily="fedraSansStd-book"
                        size={'lg'}
                        borderRadius="full"
                        variant="solid"
                        backgroundColor="#D04723"
                        color="#fff"
                        fontSize="14px"
                        fontStyle="normal"
                        lineHeight="normal"
                        _hover={{
                          bg: '#92361E',
                          color: '#fff',
                        }}
                        _active={{ bg: '#92361E', color: '#fff' }}
                        _focus={{ bg: '#92361E', color: '#fff' }}
                      >
                        <TagLabel>{keys}</TagLabel>
                        <TagCloseButton
                          color="#28231E"
                          background="#fff"
                          _hover={{
                            background: '#fff',
                          }}
                          _focus={{ background: '#fff' }}
                          opacity={1}
                          mr="2px"
                          onClick={() => {
                            deleteFilter(tag);
                          }}
                        />
                      </Tag>
                    </Flex>
                  );
                } else if (
                  tag?.programType ||
                  tag?.centerName ||
                  tag?.onlineInperson ||
                  tag?.date ||
                  tag?.city ||
                  tag?.state
                ) {
                  if (!tag.isParentChecked) {
                    const statekeys = Object.keys(tag).splice(0, 1).join('');
                    return (
                      <WrapItem mb="0px">
                        <Tag
                          px="9px"
                          py="4px"
                          fontFamily="fedraSansStd-book"
                          size={'lg'}
                          borderRadius="full"
                          variant="solid"
                          backgroundColor="#D04723"
                          color="#fff"
                          fontSize="14px"
                          fontStyle="normal"
                          lineHeight="normal"
                          _hover={{
                            bg: '#92361E',
                            color: '#fff',
                          }}
                          _active={{ bg: '#92361E', color: '#fff' }}
                          _focus={{ bg: '#92361E', color: '#fff' }}
                        >
                          <TagLabel>
                            {tag?.programType ||
                              tag?.centerName ||
                              data.json[tag?.onlineInperson] ||
                              tag?.lang ||
                              tag?.city ||
                              statekeys ||
                              tag?.country ||
                              data?.json[tag?.date]}
                          </TagLabel>
                          <TagCloseButton
                            color="#28231E"
                            background="#fff"
                            _hover={{
                              background: '#fff',
                            }}
                            _focus={{ background: '#fff' }}
                            opacity={1}
                            mr="2px"
                            onClick={() => {
                              deleteFilter(tag);
                            }}
                          />
                        </Tag>
                      </WrapItem>
                    );
                  }
                }
              })}
            </Wrap>
          </Show>
          <Hide above="md">
            <HStack
              mt="20px"
              mb="22px"
              spacingX="8px"
              spacingY="16px"
              maxW="100%"
              overflowX="auto"
              overflowY="hidden"
              pl="15px"
              className="no-scrollbar"
            >
              {filters?.map((tag, index) => {
                tag?.lang;
                if (tag?.country) {
                  const keys = Object.keys(tag).splice(0, 1).join('');
                  // console.log(keys, filters, '===>country');
                  return (
                    <Flex key={nanoid()}>
                      <Tag
                        px="9px"
                        py="4px"
                        fontFamily="fedraSansStd-book"
                        size={'lg'}
                        borderRadius="full"
                        variant="solid"
                        backgroundColor="#D04723"
                        color="#fff"
                        fontSize="14px"
                        fontStyle="normal"
                        _hover={{
                          bg: '#92361E',
                          color: '#fff',
                        }}
                        _active={{ bg: '#92361E', color: '#fff' }}
                        _focus={{ bg: '#92361E', color: '#fff' }}
                        lineHeight="normal"
                      >
                        <TagLabel>{keys}</TagLabel>
                        <TagCloseButton
                          color="#28231E"
                          background="#fff"
                          _hover={{
                            background: '#fff',
                          }}
                          _focus={{ background: '#fff' }}
                          opacity={1}
                          mr="2px"
                          onClick={() => {
                            deleteFilter(tag);
                          }}
                        />
                      </Tag>
                    </Flex>
                  );
                } else if (tag?.lang) {
                  const keys = Object.keys(tag).splice(1, 1).join('');
                  // console.log(keys, filters, '===>country');
                  return (
                    <Flex key={nanoid()}>
                      <Tag
                        px="9px"
                        py="4px"
                        fontFamily="fedraSansStd-book"
                        size={'lg'}
                        borderRadius="full"
                        variant="solid"
                        backgroundColor="#D04723"
                        color="#fff"
                        fontSize="14px"
                        fontStyle="normal"
                        lineHeight="normal"
                        _hover={{
                          bg: '#92361E',
                          color: '#fff',
                        }}
                        _active={{ bg: '#92361E', color: '#fff' }}
                        _focus={{ bg: '#92361E', color: '#fff' }}
                      >
                        <TagLabel>{keys}</TagLabel>
                        <TagCloseButton
                          color="#28231E"
                          background="#fff"
                          _hover={{
                            background: '#fff',
                          }}
                          _focus={{ background: '#fff' }}
                          opacity={1}
                          mr="2px"
                          onClick={() => {
                            deleteFilter(tag);
                          }}
                        />
                      </Tag>
                    </Flex>
                  );
                } else if (
                  tag?.programType ||
                  tag?.centerName ||
                  tag?.onlineInperson ||
                  tag?.date ||
                  tag?.lang ||
                  tag?.city ||
                  tag?.state
                ) {
                  if (!tag.isParentChecked) {
                    const statekeys = Object.keys(tag).splice(0, 1).join('');
                    return (
                      <Flex key={nanoid()}>
                        <Tag
                          px="9px"
                          py="4px"
                          fontFamily="fedraSansStd-book"
                          size={'lg'}
                          borderRadius="full"
                          variant="solid"
                          backgroundColor="#D04723"
                          color="#fff"
                          fontSize="14px"
                          fontStyle="normal"
                          lineHeight="normal"
                          _hover={{
                            bg: '#92361E',
                            color: '#fff',
                          }}
                          _active={{ bg: '#92361E', color: '#fff' }}
                          _focus={{ bg: '#92361E', color: '#fff' }}
                        >
                          <TagLabel>
                            {tag?.programType ||
                              tag?.centerName ||
                              data.json[tag?.onlineInperson] ||
                              tag?.lang ||
                              tag?.city ||
                              statekeys ||
                              tag?.country ||
                              data?.json[tag?.date]}
                          </TagLabel>
                          <TagCloseButton
                            color="#28231E"
                            background="#fff"
                            _hover={{
                              background: '#fff',
                            }}
                            _focus={{ background: '#fff' }}
                            opacity={1}
                            mr="2px"
                            onClick={() => {
                              deleteFilter(tag);
                            }}
                          />
                        </Tag>
                      </Flex>
                    );
                  }
                }
              })}
            </HStack>
          </Hide>
        </>
      )}
      <style jsx global>
        {`
          .searchPopFullWidth section[id^='popover-content-'],
          .searchPopFullWidth .css-1qq679y {
            width: 95%;
          }
        `}
      </style>
    </Box>
  );
};

export default FilterMain;
