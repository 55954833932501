/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Component */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import MidContainer from '@Layout/MidContainer';

/* Services  */
/* Styles */
/**
 * Renders Expression Component
 * @param {Object} sectionContent - data for the section from api
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 */

const SamskritiRedBox = ({ sectionContent, lang, region }) => {
  
  // if (sectionContent?.title?.length) {
  //   sectionContent.title[0].titleDecoration = 'samskriti-2';
  // }
  
  // consoleLog('sectionContentddd', sectionContent);
  return (
    <Box
      w="100%"
      h="100%"
      pb="90px"
      pt="70px"
      backgroundColor="#AF1F22"
      clipPath={{
        base: 'polygon(0 0%, 100% 0, 100% 100%, 0% 98%)',
        lg: 'polygon(0 0%, 100% 0, 100% 100%, 0% 95%)',
      }}
    >
      <SectionTitle titleObj={sectionContent?.title[0]} />
      
      <MidContainer w={'768'}>
        <Box
          textAlign="center"
          color="#FFBCBC"
          lineHeight="1.13"
          w="full"
          p="0 20px"
          fontFamily="'FedraSansStd-book', sans-serif"
          fontSize="14px"
        >
          <StructuredTextParser
            str={render(sectionContent?.description)}
            region={region}
            lang={lang}
            textAlign="center"
          />
        </Box>
        <Box
          bgColor="white"
          // w="full"
          p="10px 20px 20px 20px"
          fontFamily="'FedraSansStd-book', sans-serif"
          fontSize="14px"
          mt="30px"
          className="event-text"
          m="26px"
        >
          <StructuredTextParser
            str={render(sectionContent?.body)}
            region={region}
            lang={lang}
            fontSize="14px"
          />
        </Box>
      </MidContainer>
    </Box>
  );
};

export default SamskritiRedBox;
