/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/**
 * Returns the BenefitsGroup.
 * @param {Object} sectionContent - Content from API
 * @param {string} language - Language of the page
 * @param {string} region - Region of the page
 * @returns {ReactElement} Banner component
 */
const BenefitsGroup = ({ sectionContent, region, language }) => {
  return (
    // <Box display={'grid'} pos={'relative'} h={{ base: '100%', md: 'auto' }}>
    <Box
      as="div"
      fontSize="18px"
      m="0 auto"
      width={{ base: '100%', sm: '100%', md: '737px', lg: '933px' }}
      mb={{ md: '0', sm: '30.78px' }}
    >
      {sectionContent.title?.length > 0 && (
        <SectionTitle titleObj={sectionContent.title[0]} />
      )}
      <Box
        as="div"
        display="flex"
        flexWrap="wrap"
        // justifyContent={{ md: 'center', base: 'normal' }}
        // alignItems={{ md: 'center', base: 'normal' }}
        margin="40px auto 0 auto"
        fontSize="18px"
        gap="10px"
        width={{ base: '100%', md: '737px', lg: '933px' }}
      >
        {sectionContent &&
          sectionContent?.benefits?.map(data => {
            return (
              <Box
                //turn this on after you have done
                flex={{
                  base: '100%',
                  md: '30%',
                }}
                // p="0 10px"
                // alignItems="center"
                display={{
                  base: 'inline-flex',
                  md: 'block',
                }}
                width="calc((100%)/3)"
                marginBottom={{ base: '26.07px', md: '33px', lg: '36px' }}
                // display="block"
                ml={{ base: '17px', md: '26px' }}
                // h={{ md: '100px', lg: '100px' }}
                mb={{ base: '10px', lg: '53px', md: '80px' }}
              >
                <Flex
                  alignItems="center"
                  justifyContent={{ base: 'flex-start', md: 'center' }}
                  // as="div"
                  minWidth={{ base: '37.17px', md: '100%', lg: '100%' }}
                  maxW={{ base: '37.17px', md: '100%', lg: '100%' }}
                  mr="17.93px"
                  textAlign={{ base: 'left', md: 'center' }}
                  maxH={{ md: '100px', lg: '100px' }}
                >
                  <LazyLoadImageComponent
                    loading="lazy"
                    src={data?.image?.url}
                    title={data?.image?.title}
                    height={{
                      base: '44px',
                      md: '80px',
                    }}
                    minW={'31px'}
                    objectFit={{ base: 'contain', md: 'cover' }}
                    m={{ base: '0 0 22px 0' }}
                  />
                </Flex>
                <Text
                  fontFamily="FedraSansStd-book, sans-serif"
                  fontSize={{
                    base: '16px',
                    md: '18px',
                  }}
                  color="#28231E"
                  m={{ base: '0', lg: '0 auto 15px' }}
                  textAlign={{
                    base: 'left',
                    md: 'center',
                  }}
                  pt={{ base: '5px', sm: '10px', md: '0' }}
                >
                  {data?.text}
                </Text>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default BenefitsGroup;
