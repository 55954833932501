/* Built In Imports */
/* External Imports */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the AccordionV2 component.
 *
 * @param {object} sectionContent - Main data.
 * @param sectionContent.bgColor
 * @returns {ReactElement} AccordionV2 component.
 */

const AccordionV2 = ({ sectionContent, region, lang, bgColor }) => {
  return (
    <MidContainer w={'535px'}>
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="1.1rem"
        lineHeight="1.61"
        width="100%"
        m="0"
        id={sectionContent?.id}
        mb={sectionContent?.id === '143297004' ? '60px' : '14px'}
      >
        <Accordion
          key={nanoid()}
          allowMultiple
          m={{ base: '0 auto' }}
          width={{ base: '100%' }}
          bgColor={'#EEE3CF'}
          defaultIndex={sectionContent?.content[0]?.content[0]?.fieldId === 'open' ? [0] : []}
        >
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  bgColor={bgColor || '#EEE3CF'}
                  borderRadius="3px"
                  mb="2px"
                  fontFamily="FedraSansStd-medium"
                  fontSize="20px"
                  border="none"
                  padding="10px 26px"
                  color={'#000000'}
                  _hover={{ bgColor: bgColor || '#EEE3CF', color: '#000000' }}
                  _focus={{ shadow: 'transparent' }}
                  minHeight="55px"
                  lineHeight="1.5"
                >
                  <chakra.div flex="1" textAlign="left" fontSize={'16px'}>
                    {sectionContent?.content[0].title}
                  </chakra.div>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" color="#CF4520" />
                  ) : (
                    <AddIcon fontSize="12px" color="#CF4520" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={1}>
                  <Box
                    display={{ base: 'inherit', md: 'flex' }}
                    placeContent="center"
                  >
                    {Object.keys(
                      sectionContent?.content[0]?.content[0]?.data
                    )?.map((item, index) => {
                      return (
                        <Box
                          w={{ base: '100%', md: '33%' }}
                          display="flex"
                          flexDir="column"
                          alignItems="center"
                          gridRowGap='3'
                          mb='4'
                        // justifyContent="center"
                        >
                          <Box
                            as="h3"
                            fontFamily="FedraSansStd-medium"
                            fontWeight="500"
                            fontSize="16px"
                            lineHeight="20px"
                          >
                            {item}
                          </Box>
                          <Box>
                            {sectionContent?.content[0]?.content[0]?.data[item]
                              .split(',')
                              .map(date => {
                                return (
                                  <Box
                                    display="grid"
                                    placeItems="center"
                                    w="144px"
                                    h="37px"
                                    bgColor="#FFFFFF"
                                    borderRadius="3px"
                                    // p="10px"
                                    m="10px"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="20px"
                                  // fontFamily="FedraSansStd-medium"
                                  >
                                    {date}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
        <style jsx>
          {`
            #143297004{
              margin-bottom:40px;
            }
            `}
        </style>
      </Box>
    </MidContainer>
  );
};

export default AccordionV2;
