/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */

/* Services */

/**
 * Renders the TagCloud Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for section
 * @param {string} lang - Lang for section
 * @returns {ReactElement} TagCloud Card component
 */
const TagCloud = ({ sectionContent, region, lang }) => {
  const itemArr = sectionContent?.text.split(',');

  return (
    <Box
      display="inline-flex"
      flex="1"
      justifyContent="center"
      w="full"
      flexWrap="wrap"
      mb="15px"
    >
      {itemArr?.map((item, index) => {
        return (
          <Box
            // w={{ base: '302px', sm: '330px' }}
            p="5px 21px"
            m="6.75px"
            fontSize="16px"
            fontFamily="FedraSansStd-book"
            h="35px"
            key={nanoid()}
            position="relative"
            display="inline-flex"
            borderRadius="50px"
            textAlign="center"
            bgColor="#F5EDDF"
          >
            {item}
          </Box>
        );
      })}
    </Box>
  );
};

export default TagCloud;
