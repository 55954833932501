/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the YogaCommonUI component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SingleLineText Component.
 */

const ItalianStyleText = ({
    sectionContent,
    fontStyle,
    lineHeight,
    fontWeight,
    color,
    ...props
}) => {
    return (
        <MidContainer w='317px' >
            <Box   w={{base:'317px'}} my={10}  >
                <Box minW={300}
                    fontFamily="FedraSansStd-Book, sans-serif"
                    width="100%"
                    fontSize={{ base: '16px', lg: '16px' }}
                    color={color ? color : '#28231E'}
                    lineHeight={lineHeight || "28px"}
                    fontStyle={fontStyle || "italic"}
                    fontWeight={fontWeight || 400}>
                    <Box textAlign='center'>
                        {sectionContent?.text}
                    </Box>
                </Box>
            </Box>
        </MidContainer>
    );
};

export default ItalianStyleText;
