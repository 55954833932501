/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Ishanga Single Image Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Ishanga Single Image Card component
 */

const IshangaSingleImage = ({ sectionContent, region, lang }) => {
  return (
    <MidContainer maxW="756px">
      <Box textAlign="center" position="relative" mx="auto">
        <LazyLoadImageComponent
          width={{
            base:
              sectionContent.image?.url?.indexOf('sadhguru-signature') > -1 ? '71px' : '100%',
            md: sectionContent.image?.url?.indexOf('sadhguru-signature') > -1 ? '107px' : '100%',
          }}
          height={{
            base:
              sectionContent.image?.url?.indexOf('sadhguru-signature') > -1 ? '31px' : '100%',
            md:
              sectionContent.image?.url?.indexOf('sadhguru-signature') > -1 ? '46.42px' : '100%',
          }}
          alt={sectionContent.image?.alt}
          title={sectionContent.image?.title}
          textAlign="center"
          src={sectionContent.image?.url}
          maxW="756px"
        />
      </Box>
    </MidContainer>
  );
};

export default IshangaSingleImage;
