/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import CardSlider from '@components/UI/Publications/CardSlider';
import { refineUrlDomain } from '@components/Utility/utils';

/*styles*/

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const PubHomePageCard = ({ sectionContent, region, lang }) => {
  return (
    <Flex
      w="100%"
      maxW={1000}
      overflow="visible"
      mx="auto"
      flexDir="column"
      mb={{ base: '0', md: '0' }}
    // maxW={{ base: '100%' }}
    // pb={30}
    >
      <Flex
        px={{ base: "calc(0.5rem + 15px)", md: "calc(0.5rem + 15px)" }}
        align="center"
        justify="space-between"
        fontFamily="FedraSansStd-A-medium"
        fontSize="26px"
      >
        <Box fontSize={{ base: '22px', md: '26px' }} mb="16px" w={{ base: "161px", md: "auto" }}>
          {sectionContent?.title}
        </Box>
        {sectionContent?.showExploreMoreLink && (
          <NextLink
            href={refineUrlDomain(
              sectionContent.link.length && sectionContent.link[0]?.linkUrl,
              region,
              lang
            )}
            passHref
            legacyBehavior
          >
            <Link
              _hover={{
                textDecoration: 'none',
                backgroundColor: '#cf4824',
                color: '#fff',
                backgroundImage: 'linear-gradient(120deg,#cf4824,#cf4824)',
                backgroundSize: '100% 100%',
                // transition: 'all .3s ease-in-out'
              }}
              mb="16px"
              pos="relative"
              fontSize={{ base: '14px', md: '18px' }}
              color="#D04723"
              fontFamily="FedraSansStd-book,sans-serif"
              textDecoration="underline"
              mr={{ base: "7px", md: "10px" }}
            >
              <>
                {sectionContent.link[0].buttonText}
                <Box pos="absolute" top={{ base: "7px", md: "10px" }} right={{ base: "-0.8rem", md: "-1rem" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                  >
                    <path
                      d="M1 0.909668L4.95483 4.8645L1 8.81934"
                      stroke="#D04723"
                      stroke-width="1.31828"
                    />
                  </svg>
                </Box>
              </>
            </Link>
          </NextLink>
        )}
      </Flex>

      <Flex
        justify={{ base: 'start', md: 'center' }}
        gridColumnGap="30px"
        mt="0"
        mb={{ base: '0', md: '2rem' }}
      >
        <CardSlider
          sectionContent={sectionContent?.cards}
          region={region}
          lang={lang}
        />
      </Flex>
    </Flex>
  );
};

export default PubHomePageCard;
