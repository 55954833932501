// BuiltIn Imports
import { useEffect, useState } from 'react';

/**
 *
 * @param {any} value initial state value
 * @param {number} delay to throttle state update
 * @returns {any} debounced value
 */
export const useDebounce = (value, delay = 300) => {
  const [debouncedVal, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [value, delay]);

  return debouncedVal;
};
