/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import Card from '@components/UI/Publications/Card2';
import config from '@config';

/* Services */

/** 
 * Returns the PubListingFacadePlaceholderTabGroup.
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of the page
 * @param {string} region - Region of the page
 * @returns {ReactElement} PubListingFacadePlaceholderTabGroup component
 */


let targetIndex;
const PubListingFacadePlaceholderTabGroup = ({ sectionContent, region, lang }) => {
  const router = useRouter()
  const { tabs } = sectionContent;
  // const [menu, setMenu] = useState(router.query?.tab || 'All');
  const [menuIndex, setMenuIndex] = useState(0);
  const [container, setContainer] = useState({ totalCount: 0, cards: [] });


  const schema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": container?.cards.map((card, i) => {
      return {
        "@type": "ListItem",
        "position": i + 1,
        "url": card.url
      }
    })
  };

  const handleUrl = param => {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query, tab: param
        },
      },
      undefined,
      { scroll: false }
    );
  };

  const handleClick = async () => {
    // let uuid = sectionContent.tabs[targetIndex].filter?.split('=')[1];
    // const res = await fetchPubCategoryBooks(uuid, true, container?.cards.length, region, lang);
    // !api url should never be hardcoded
    console.log('handleClick called');
    const res = await axios.get(`${config.FACADE_BASE_PATH}/content/fetchcsr/content?format=json&sitesection=publication&${sectionContent.tabs[targetIndex].filter}&start=${container?.cards.length}&limit=12&sortby=sequence&lang=${lang}`)

    if (res?.data?.posts?.cards?.length) {
      const { cards } = res.data.posts;
      setContainer({ ...container, cards: [...container?.cards, ...cards] })
    } else {
      // console.log('something bad happened')
    }
  };

  useEffect(() => {
    if (router.pathname.includes('publications/other-publications') && container.cards.length) {
      setContainer({ totalCount: 0, cards: [] });
    }

    if (router.isReady && tabs?.length) {
      targetIndex = tabs?.findIndex(section => section.tabTitle.toLowerCase().replace(/&/g, '').replace(/,/g, '').replace(/ /g, '-').replace(/--/g, '-') === router.query.tab);
      // console.log(menu, router.query, router.query.tab, targetIndex);
      if (targetIndex === -1) {
        targetIndex = 0;
      }
      setMenuIndex(targetIndex);
      if (targetIndex > -1 && tabs[targetIndex]?.cards) {
        setContainer({ totalCount: tabs[targetIndex]?.totalCount, cards: [...tabs[targetIndex]?.cards] });
      }
    }
  }, [router.asPath, router.isReady]);

  return (
    <>
      {tabs.length > 1 && (
        <Box
          maxW={1300}
          // minW={320}
          h='55px'
          mt={{ base: '0' }}
          overflowX="auto"
          className='scroll'
          overflowY='hidden'
          background="linear-gradient(0deg, #E4DED4 0%, #E4DED4 100%), linear-gradient(270deg, rgba(228, 222, 212, 0.00) 0%, rgba(141, 135, 123, 0.55) 100%), #E4DED4"
        >
          <Flex
            w={{ base: "max-content", md: '100%' }}
            pt='17.5px'
            // h="100%"
            pl={{ lg: '101px', base: '21px' }}
            pr={{ base: '21px' }}
            alignItems='center'
            display="flex"
            justifyItems='center'
            gridGap={{ md: '10px', base: '10px' }}
            color='#000'
            fontSize={{ md: "16px", base: '14px' }}
            lineHeight={{ md: "20px", base: '17.5px' }}
            className='menu-title'
          >
            {tabs?.map((it, index) => {
              return it?.cards?.length > 0 && (
                <Box
                  fontFamily={index === menuIndex ? "FedraSansStd-medium" : "FedraSansStd-book"}
                  cursor='pointer'
                  _hover={{
                    color: '#D04723',
                  }}
                  w="auto"
                  onClick={() => {
                    // setMenu(it.tabTitle);
                    handleUrl(it.tabTitle.toLowerCase().replace(/&/g, '').replace(/,/g, '').replace(/ /g, '-').replace(/--/g, '-'));
                  }}
                  key={nanoid()}
                >
                  <Box
                    p="0 10px"
                  >
                    {it?.tabTitle}
                  </Box>

                  <Box
                    visibility={index === menuIndex ? 'visible' : 'hidden'}
                    borderBottom='4px solid #D04723'
                    pt='13px'
                    pos='relative'
                  >
                  </Box>
                </Box>
              )
            })}
          </Flex>
        </Box>
      )}

      <Box>
        {tabs?.map((section, ind) => {
          return (menuIndex === ind) && container?.cards?.length > 0 && (
            <Flex
              key={nanoid()}
              my='3rem'
              flexDir={{ base: 'column', md: 'row' }}
              flexWrap={{ base: 'nowrap', md: 'wrap' }}
              justify='center'
              align='center'
              gridGap={{ base: '40px', md: '30px' }}
              mx='2rem'
            >
              {container?.cards?.map((card, key) => {
                return <Card key={nanoid()} content={card} region={region} lang={lang} />
              })}

              {((container?.totalCount > container?.cards.length)) && (
                (container.cards?.length !== container.totalCount) && (
                  <Flex width='100%' align='center' justify='center' mt='40px'> 
                    <Button
                      className="orange-button"
                      mb={'30px'}
                      display="block"
                      fontWeight={'500'}
                      fontFamily={'FedraSansStd-medium'}
                      fontSize={'18px'}
                      bgColor={'#cf4520'}
                      h={'auto'}
                      minH="50px"
                      cursor={'pointer'}
                      // minW="200px"
                      textAlign={'center'}
                      padding={'14px 29px'}
                      borderRadius="5px"
                      color="#faf7f0"
                      textDecoration="none"
                      width={'auto'}
                      _hover={{
                        bg: '#000054',
                      }}
                      onClick={handleClick}
                    >
                      {`${section?.link[0].buttonText} (${container.cards.length}/${container.totalCount})`}
                    </Button>
                  </Flex>
                ))}
            </Flex>
          );
        })}

        <style jsx global>
          {
            `.menu-title {
              -webkit-line-clamp: 1;
            }
            .scroll::-webkit-scrollbar {
              width: 0px;
            }
            `
          }
        </style>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      </Box>

    </>
  )
};

export default PubListingFacadePlaceholderTabGroup;
