/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
// import StructuredTextContent from '@components/UI/StructuredTextContent';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Card Info 3 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param isLeftMenu - responsive navbar for the page
 * @returns {ReactElement} Card Info 3 Card component
 */

const IdyCardInfo3 = ({ sectionContent, region, lang, isLeftMenu, pageConfig }) => {
  // const bgColors = ['#cd6727', '#ae4511', '#10069f'];
  const pName = pageConfig?.pageName;
  // console.log("the page name is", pName)
  return (
    <Box
      mx="auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '770px',
        xl: '830px',
      }}
      my="60px"
    >
      <Flex
        gridRowGap={{ base: '15px', lg: '60px' }}
        flexWrap="wrap"
        spacing="30px"
        mx="auto"
      >
        {sectionContent?.cards?.map((secCon, index) => {
          return (
            <Box
              id="card3"
              key={nanoid()}
              display="flex"
              flexDirection="column"
              mx="auto"
              position="relative"
              mb={'20px'}
              maxW="300px"
              alignItems="center"
            >
              <LazyLoadImageComponent
                src={secCon.thumbnail?.url}
                alt={secCon.thumbnail?.alt}
                title={secCon.thumbnail?.title}
                h="50px"
                maxW="78px"
              />

              <Box
                m={{ base: '0 29px' }}
                textAlign={"center"}
              >
                <Box
                  lineHeight={1.1}
                  fontFamily="FedraSansStd-A-medium"
                  fontSize="18px"
                  mt="35px"
                  color="#28231e"
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={15}
                  textAlign={"center"}

                >
                  <StructuredTextParser
                    str={render(secCon.description)}
                    region={region}
                    lang={lang}
                    mt="0"
                    textAlign={"center"}
                  />
                </Box>
              </Box>
              <style global jsx>
                {`
                  #card3 a {
                    color: #cf4824 !important;
                  }
                  #card3 a:hover {
                    color: #ffffff !important;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default IdyCardInfo3;
