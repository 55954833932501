/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the Icon component.
 *
 * @param root0
 * @param root0.onClick
 * @param root0.children
 */
const NextArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      pos="absolute"
      top={{
        base: 'calc(50% - 19px)',
        sm: 'calc(50% - 19px)',
        md: '40%',
        lg: '40%',
        xl: '40%',
      }}
      right={{ base: '0px', md: '-29px' }}
      cursor="pointer"

    // display={{ base: 'none', md: 'block' }}
    >
      <Flex
        w={{ base: 38, md: 59 }}
        height={{ base: 38, md: 59 }}
        rounded="full"
        background="#FFAE27"
        alignItems="center"
        justifyContent="center"
        _hover={{
          boxShadow: '0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03), 0px 38.27199935913086px 26.079999923706055px 0px rgba(0, 0, 0, 0.04), 0px 65px 50px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        <svg
          width="17"
          height="25"
          viewBox="0 0 17 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Vector 2">
            <path
              id="Vector 2_2"
              d="M2.6533 22.7571L12.9902 12.4201L2.6533 2.08322"
              stroke="white"
              stroke-width="5"
            />
          </g>
        </svg>
      </Flex>
    </Box>
  );
};
const PrevArrow = ({ onClick }) => {
  return (
    <Box
      height="100%"
      onClick={onClick}
      pos="absolute"
      top={{
        base: 'calc(50% - 19px)',
        sm: 'calc(50% - 19px)',
        md: '40%',
        lg: '40%',
        xl: '40%',
      }}
      left={{ base: '0px', md: '-29px' }}
      zIndex={1}
      cursor="pointer"

    // display={{ base: 'none', md: 'flex' }}
    >
      <Flex
        w={{ base: 38, md: 59 }}
        height={{ base: 38, md: 59 }}
        rounded="full"
        background="#FFAE27"
        alignItems="center"
        justifyContent="center"
        _hover={{
          boxShadow: '0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03), 0px 38.27199935913086px 26.079999923706055px 0px rgba(0, 0, 0, 0.04), 0px 65px 50px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        <svg
          width="16"
          height="25"
          viewBox="0 0 16 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Vector 2">
            <path
              id="Vector 2_2"
              d="M14.1885 2.08398L3.85156 12.4209L14.1885 22.7578"
              stroke="white"
              stroke-width="5"
            />
          </g>
        </svg>
      </Flex>
    </Box>
  );
};
export { NextArrow, PrevArrow };

