/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders Ishanga Quote Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - data of the section from api
 * @returns {ReactElement} Ishanga Quote component
 */
const IshangaQuote = ({ sectionContent }) => {
  return (
    <Box
      maxW="660%"
      p={{ base: '15px', lg: '0% 15%', xl: '0% 15%' }}
      fontFamily="FedraSansStd-medium"
      fontSize={{ base: '20px', md: '24px' }}
      fontWeight="400"
      lineHeight="2"
      textAlign="center"
      color="#c7942b"
      marginBottom="10px"
    >
      {sectionContent?.quote}
    </Box>
  );
};

export default IshangaQuote;
