/* Built In Imports */
/* External Imports */
import { Box, List } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Common Component
 *
 * @param {object} sectionContent
 * @returns {ReactElement} Slider Common Component
 */

const WhyBooksSlider = ({ sectionContent }) => {
    const NextArrow = props => {
        const { onClick } = props;
        return (
            <Box
                pos="absolute"
                textAlign={'center'}
                top={{ base: "30%", md: '35%' }}
                zIndex="10"
                cursor="pointer"
                bgColor="#fff"
                border="0"
                borderRadius="100%"
                w={{ md: '34.46px', base: '34.4px' }}
                h={{ md: '34.46px', base: '34.4px' }}
                bgPos="center"
                bgRepeat="no-repeat"
                color="transparent"
                bgImage="/assets/images/NomRightArrow.svg"
                right={{ md: '0px', base: '3px' }}
                onClick={onClick}
                boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
                bgPosition="50% 30%"
            ></Box>
        );
    };

    const PrevArrow = props => {
        const { onClick } = props;
        return (
            <Box
                pos="absolute"
                top={{ base: "30%", md: '35%' }}
                zIndex="10"
                cursor="pointer"
                bgColor="#fff"
                border="0"
                borderRadius="100%"
                w='34.46px'
                h='34.46px'
                bgPos="center"
                bgRepeat="no-repeat"
                color="transparent"
                bgImage="/assets/images/NomLeftArrow.svg"
                left={{ md: '0px', base: '3px' }}
                onClick={onClick}
                boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
                bgPosition="50% 30%"
            />
        );
    };

    const settingsSmall = {
        dots: true,
        infinite: true,
        autoplay: false,
        lazyLoad: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        className: 'whyBooksSlider',
        //centerPadding: 0,
        appendDots: dots => (
            <Box h="1px" display='inline' bottom="0">
                <List
                    display='inline'
                    width="8px"
                    height="8px"
                >
                    {dots}
                </List>
            </Box>
        ),
        customPaging: () => (
            <Box
                background={'#D9D9D9'}
                width="8px"
                rounded="full"
                height="8px"
            >
            </Box>),
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 834,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Box
            mt={{ md: '40px', base: '32.09px' }}
            mb={{ md: '80px', base: '60px' }}
            //  p={{ sm: '0', xl: '0 30px' }}
            //h="168px"
            // id="slider-common"
            mx='auto'
            alignItems='center'
            justifyItems='center'
            // ml='200.5px'
            maxW={{ md: '568.93px', base: '296.4px' }}
            w='100%'
            h={{ md: '392px', base: '258px' }}
        >
            <Slider {...settingsSmall} h='300px'
                w='391px'>
                {sectionContent?.images?.map((image, index) => {

                    return (

                        <>
                            <Box

                                key={nanoid()}
                                outline="none"
                                textAlign='center'
                            // h="168px"
                            >

                                <Box
                                    background='#E8E8E8'
                                    w={{ md: '391px', base: '171px' }}
                                    h={{ md: '300px', base: '176px' }}
                                    mx='auto'
                                    cursor="pointer"
                                    transition="all 0.5s linear"
                                >
                                    <LazyLoadImageComponent
                                        w={{ base: '100%', md: '100%' }} h={{ base: '100%', md: 'auto' }}
                                       transition="all 0.05s linear"
                                        // title={item?.title}
                                        src={image.url}
                                        //  alt={image.alt}
                                        objectFit={'cover'}
                                    />

                                </Box>
                                <Box
                                    className='n-slide-title'
                                    w={{ md: '196px', base: '151px' }} h={{ md: '24px', base: '48px' }}
                                    color='#28231E'
                                    fontSize='16px' alignItems='center'
                                    fontWeight={{ md: '500', base: '400' }} lineHeight='24px'
                                    fontFamily="'FedraSansStd-book', sans-serif"
                                    mx='auto'
                                    mt={{ md: '20px', base: '10px' }} mb={{ md: '40px', base: '16px' }}>
                                    {image.alt}
                                </Box>
                            </Box>
                        </>
                    )
                }

                )}
            </Slider >
            <style global jsx>
                {`

                    
                    
                    
                    .whyBooksSlider .slick-dots .slick-active div{
                        background:#CF4520; 
                       
                      }
                     
                      .slick-dots li{
                        width: 8px;
                        height :8px;
                      }

                      @media only screen and (max-width: 768px).slick-slide img { 
                        height: auto;
                        width: 100%;
                      }

                      @media only screen and (max-width: 360px).slick-slide img { 
                        height: 100%;
                        width: 100%;
                      }
          `}
            </style>
        </Box >

    );
};

export default WhyBooksSlider;
