/* Built In Imports */
/* External Imports */
// import { Image } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/* Services */

/**
 * Renders the Testimonials component.
 * @param {Object} testimonialsObj component.
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @returns {ReactElement} Testimonials component.
 */
const VolunteerBrownTestimonial = ({
  testimonialsObj,
  myRef,
  region,
  lang,
  width,
  height,
  background,
}) => {
  const NextArrow = props => {
    // consoleLog('testimonial', testimonialsObj);
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="40%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        // w="40px"
        display="grid"
        placeItems="center"
        // h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        background="#FFFFFF"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)"
        // bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        w="46px"
        h="46px"
        right={{ base: '6px', md: '6px', lg: '-10px' }}
        onClick={onClick}
      >
        <Box
          bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
          width="7.26px"
          height="14.53px"
          backgroundSize="cover"
        ></Box>
      </Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="40%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        display="grid"
        placeItems="center"
        borderRadius="100%"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left="-10px"
        onClick={onClick}
        background="#FFFFFF"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)"
        w="46px"
        h="46px"
      >
        <Box
          width="7.26px"
          height="14.53px"
          backgroundSize="cover"
          bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        ></Box>
      </Box>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <Box w="100%">
      {testimonialsObj?.title[0] && (
        <SectionTitle titleObj={testimonialsObj?.title[0]} />
      )}
      <Box
        // display="flex"
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxW="100%"
        m="auto"
        w="100%"
        overflow="hidden"
        p={{
          base: '15px',
          sm: '20px 15px 5px 15px'
        }}
        height={{ base: 'auto', md: 'auto', lg: '449px' }}
        style={{ backgroundColor: '#402C23' }}
      >
        <Box maxW={{ base: '360px', sm: "780px", md: '912px' }} mx="auto" my="auto" id="chants-container">
          <Box id="chants-slider">
            <Slider {...settings} m="0">
              {testimonialsObj?.testimonials?.map((data, i) => {
                return (
                  <Box>
                    <Flex
                      flexDir="row"
                      overflow="hidden"
                      p="0 5%"
                      flexWrap="wrap"
                      key={nanoid()}
                      textAlign="left"
                      fontFamily="FedraSansStd-book"
                    >
                      <Box w={{ base: "400px", md: "320px" }} display="inline-block">
                        {data?.photo?.url && (
                          <LazyLoadImageComponent
                            src={data?.photo?.url}
                            alt={data?.photo?.alt}
                            title={data?.photo?.title}
                            // w={width ? width : '196px'}
                            // h={height ? height : '196px'}
                            // borderRadius="50%"
                            verticalAlign="middle"
                            pb="1vw"
                            minHeight={{ base: '300px' }}
                            maxH={{ base: '300px', md: '365px' }}
                          />
                        )}
                      </Box>
                      <Box
                        w="363px"
                        display="inline-block"
                        className="VolunteerBrownTestimonial"
                        height="auto"
                        my="auto"
                        mx="30px"
                      >
                        <ContentArea
                          tAlign="center"
                          font="16px"
                          textAlign="left"
                          mainContent={data?.testimonialText}
                          region={region}
                          lang={lang}
                          className="contentVolunteerBrown"
                        />
                        {data?.userName && (
                          <Box
                            marginTop="15px"
                            textAlign="left"
                            fontStyle="normal"
                            fontWeight="500"
                            fontSize="18px"
                            lineHeight="29px"
                            color="#E7952D"
                            fontFamily="FedraSansStd-medium"
                            textIndent={"-15px"}
                            paddingLeft={"12px"}
                          >
                            - {data?.userName}
                          </Box>
                        )}
                        {data?.userDescription["value"]["document"]["children"][0]["children"][0]["value"] && (
                          <Box
                            textAlign="left"
                            fontStyle="normal"
                            fontWeight="500"
                            fontSize="18px"
                            color="#E7952D"
                            fontFamily="FedraSansStd-medium"
                            paddingLeft={"10px"}
                          >
                          {data?.userDescription["value"]["document"]["children"][0]["children"][0]["value"]}
                          </Box>
                        )}

                        {/* {StructuredTextContent(data?.testimonialText)} */}
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
            </Slider>
            
                      <style jsx global>
                        {`
                        .contentVolunteerBrown, .VolunteerBrownTestimonial .css-rvo58s{
                          padding:0;
                        }
                    .VolunteerBrownTestimonial p{
                      font-style: italic;
                      font-weight: 400;
                      font-size: 18px;
                      line-height: 29px;
                      text-align:left;
                      color: #DED5C3;
                      font-family: 'FedraSansStd-book-i';
                    }
                    `}
                      </style>
            <style jsx global>
              {`
                .slick-dots {
                  margin-bottom: 0px;
                }
                .slick-dots li.slick-active p {
                  background-color: #f5ac3e;
                }
                @media (max-width: 668px) {
                  #chants-slider .slick-list .slick-active {
                    width: 300px !important;
                  }
                }
                @media (max-width: 350px) {
                  #chants-slider .slick-list .slick-active {
                    width: 280px !important;
                  }
                  #chants-container {
                    padding: 0;
                  }
                }
                @media (max-width: 320px) {
                  #chants-container {
                    padding: 0px;
                  }
                }
              `}
            </style>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VolunteerBrownTestimonial;
