/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Heading, Link, Stack, Text } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/**
 * Renders the RelatedVolunteering component.
 * @param {Object} programs - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} Related Volunteering component.
 */
const RelatedVolunteering = ({ programs, region, lang }) => {
  const isSlider = programs?.volunteerCards?.length > 3;
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top={{ base: '45%' }}
        zIndex="10"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ base: '2px' , sm: '2px' ,md: '-26px', lg: '0', xl: '-45px' }}
        bgSize="10px"
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top={{ base: '45%' }}
        zIndex="10"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ base:'2px', sm: '2px', md: '-15px', lg: '0', xl: '-40px' }}
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 750,
        settings:
          programs.volunteerCards.length > 3
            ? {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                initialSlide: 1,
              }
            : 'unslick',
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return programs?.volunteerCards?.length ? (
    <Box
      m={{ base: '10px auto', md: '30px auto 0 auto' }}
      w="100%"
      maxH={{ base: 'auto', md: '690px' }}
      minW="300px"
      // p={{
      //   md: '0',
      //   base: programs?.volunteerCards?.length > 3 ? '5%' : 0,
      // }}
      maxW={{
        base: '330px',
        md: 700,
        lg: 1050,
        xl: '1070px',
      }}
      id="volSlider"
    >
      <Slider {...settings}>
        {programs &&
          programs.volunteerCards.length > 0 &&
          programs.volunteerCards.map((card, index) => {
            return (
              <Box
                key={index}
                w={{ base: '290px', md: '330px !important' }}
                h={{ base: 'auto', md: '540px', lg: '540px' }}
                // mr="0"
                maxW={{ base: '290px', md: '330px !important' }}
                m={{
                  base: '0',
                  md:
                    programs?.volunteerCards?.length > 3
                      ? '0 15px'
                      : '0 15px 0 0',
                }}
                p="0"
                marginLeft={"20px"}
                //bg={useColorModeValue('white', 'gray.800')}
                boxShadow="0px 10px 11px rgb(0 0 0 / 15%)"
                bgColor="white"
                overflow={'hidden'}
                mb={{ base: '20px', md: '0' }}
              >
                <NextLink
                  href={refineUrlDomain(card.urlAlias, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    position="relative"
                    zIndex="1"
                    cursor={card.urlAlias ? 'cursor' : 'auto'}
                  >
                    <LazyLoadImageComponent
                      h={{ base: '160px', md: '209.04px' }}
                      w={'100%'}
                      clipPath="polygon(0 0, 0 100%, 100% 90%, 100% 0)"
                      src={
                        card.heroImage?.url ||
                        'https://i.ibb.co/L9x0dBn/Devi-Seva-Pg-Top-Banner20180924-SLH-0032-e-1.png'
                      }
                      objectFit={'cover'}
                    />
                  </Link>
                </NextLink>

                <Box
                  p={{ base: '20px', md: 5 }}
                  height="60%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Stack
                    spacing={2}
                    align={'left'}
                    mb={5}
                    // p={{ base: '10px', md: 0 }}
                  >
                    <NextLink
                      href={refineUrlDomain(card.urlAlias, region, lang)}
                      passHref
                      legacyBehavior>
                      <Link
                        position="relative"
                        zIndex="1"
                        cursor={card.urlAlias ? 'cursor' : 'auto'}
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        <Heading
                          fontSize={{ base: '20px', md: '24px' }}
                          fontWeight={500}
                          lineHeight="27px"
                          color="#28231E"
                          fontFamily="FedraSansStd-A-medium"
                          fontStyle="normal"
                        >
                          {card.title}
                        </Heading>
                      </Link>
                    </NextLink>
                    <Text
                      fontFamily="FedraSansStd-book"
                      fontStyle="normal"
                      color="#28231E"
                      maxH="70px"
                      overflow="hidden"
                    >
                      {card.summary}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'column'}
                    justify={'center'}
                    spacing="16px"
                    align="center"
                    width="100%"
                  >
                    <Stack
                      direction={'column'}
                      justify="center"
                      spacing={3}
                      align="center"
                      height={{ base: '82px', md: '82px' }}
                    >
                      {card.programCardTag.split(',').length &&
                        card.programCardTag.split(',').map((item, index) => {
                          return (
                            <Text
                              bg="#F5EDDF"
                              align="center"
                              fontSize={{ base: '13.877px', md: '16px' }}
                              borderRadius="50px"
                              w={{ base: '223.74px', md: '254.88px' }}
                              h="35px"
                              fontFamily="FedraSansStd-book"
                              fontStyle="normal"
                              fontWeight={'400'}
                              color="#000000"
                              p="5px 0 0 0"
                            >
                              {item}
                            </Text>
                          );
                        })}
                    </Stack>

                    <NextLink
                      href={refineUrlDomain(card.urlAlias, region, lang)}
                      passHref
                      legacyBehavior>
                      <Link
                        d="block"
                        textAlign="center"
                        cursor={card.urlAlias ? 'cursor' : 'auto'}
                        mt="20px"
                        bg="#CF4520"
                        color={'white'}
                        fontWeight="500"
                        fontSize="16px"
                        fontFamily="FedraSansStd-medium"
                        lineHeight="20px"
                        p="13px"
                        borderRadius="5px"
                        h="45.94px"
                        w={{ base: '242.67px', md: '276.45px' }}
                        _hover={{
                          color: '#fff',
                          bgColor: '#000054',
                          textDecoration: 'none',
                        }}
                        _focus={{
                          color: '#fff',
                          bgColor: '#000054',
                          textDecoration: 'none',
                        }}
                        _active={{
                          color: '#fff',
                          bgColor: '#000054',
                          textDecoration: 'none',
                        }}
                      >
                        {programs?.ctaLabel || 'Know More'}
                      </Link>
                    </NextLink>
                  </Stack>
                </Box>
              </Box>
            );
          })}
      </Slider>
      <style jsx>
        {`
        #volSlider div {
            display: flex;
            align-self: center;
          }
          #volSlider .slick-slide {
            padding: 0 0 30px 0;
          }
          #volSlider .slick-slider {
    width: 100%;
    margin: 0 auto;
        justify-content: center;
          }
          @media only screen and (max-width: 750px) {
            #volSlider div {
            display: ${isSlider ? 'block' : 'flex'};
            align-self: center;
            flex-direction:column;
          }
          #volSlider .slick-list .slick-active {
                    width: 275px !important;
                    margin:0 20px;
                  }
}
@media (max-width: 350px) {
                  #volSlider .slick-list .slick-active {
                    width: 275px !important;
                  }
                  #volSlider{
                    padding: 0;
                  }
                }
        `}
      </style>
    </Box>
  ) : null;
};

export default RelatedVolunteering;
