/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import CardInfoWithMultipleImageDetail from '@components/Card/CardInfoWithMultipleImageDetail';
import VolunteerCircularImage from '@components/Card/VolunteerCircularImage';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Card Zigzag Group 2 Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language for page
 * @param {string} region - Region for page
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const CardZigzagGroup = ({ sectionContent, region, lang, section }) => {

  // const outreactZigZagSection has been created just to add specific styling for the in/en/outreach page
  const outreactZigZagSection = section.sectionId === "zigzag-section";
  // console.log({outreactZigZagSection})
  return (
    <Box w={'100%'}>
      <Box
        width={'91.66%'}
        h="100%"
        mx="auto"
      >
        <Box mx={{ base: '1%', md: outreactZigZagSection ? '0%' : '2%', lg: outreactZigZagSection ? '0%' : '10%' }}>
          <Box textAlign={'center'} my="20px">
            {sectionContent.title}
            <Box>{sectionContent.structured_text}</Box>
          </Box>

          {sectionContent?.style !== 'volunteer-circular-image' && sectionContent.cards.map((data, index) => {
            return (
              <Box>
                <CardInfoWithMultipleImageDetail
                  section={section}
                  data={data}
                  sectionContent={sectionContent}
                  region={region}
                  lang={lang}
                  index={index}
                  key={index}
                />
              </Box>
            );
          })}

          {sectionContent?.style === 'volunteer-circular-image' && sectionContent.cards.map((data, index) => {
            return (
              <Box maxW="780px" m="0 auto">
                <VolunteerCircularImage
                  data={data}
                  sectionContent={sectionContent}
                  region={region}
                  lang={lang}
                  index={index}
                  key={index}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CardZigzagGroup;
