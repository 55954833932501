/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Image, Link, Tag, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';

/**
 *
 * @param {object} content
 * @returns
 */

const Card = ({ content, region, lang }) => {



  return (
    <NextLink
      href={refineUrlDomain(
        content.url || `publications/${content.urlAlias}`,
        region,
        lang
      )}
      passHref
      legacyBehavior
    >
      <Link
        textDecoration="none"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Flex
          bg="#F1F1F1"
          pos="relative"
          justify="space-between"
          flexDir="column"
          boxShadow="0px 0.3854708671569824px 1.3993395566940308px 0px rgba(0, 0, 0, 0.02), 0px 1.6960718631744385px 2.897455930709839px 0px rgba(0, 0, 0, 0.04), 0px 4.163085460662842px 5.778449058532715px 0px rgba(0, 0, 0, 0.05), 0px 8.017794609069824px 11.32641887664795px 0px rgba(0, 0, 0, 0.05), 0px 13.491479873657227px 20.825464248657227px 0px rgba(0, 0, 0, 0.07), 0px 20.815425872802734px 35.559688568115234px 0px rgba(0, 0, 0, 0.09)"
          // minWidth='225px'
          maxWidth={{ base: '225px', sm: '296px' }}
          h={{ base: '328px', sm: '367px' }}
          my={0}
          ml={{ base: 5, md: 5 }}
          mr={{ base: 5, md: 5 }}

        >
          <Flex justify="center" mr="1.5rem" mt="1rem" h={{ base: '180px', md: "220px" }} w="100%">
            <Image
              alt={content?.cardImage?.alt || content?.title || ''}
              w="80%"
              src={
                content?.cardImage?.url ||
                `https://static.sadhguru.org/d/46272/1690191476-default-card-image.png`
              }
            />
          </Flex>

          <Box
            fontSize="18px"
            fontWeight="bold"
            p="44px 20px 20px 20px"
            clipPath="polygon(0 21%, 100% 0, 100% 100%, 0% 100%)"
            minHeight="145px"
            w="100%"
            bg="white"
          >
            <Text className='ellipsis-text'>

              {content?.title}
            </Text>
            <style global jsx>
              {`
    
        
        .ellipsis-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      `}
            </style>
          </Box>

          {!content.isThisABook && (
            <Tag
              fontFamily="'FedraSansStd-book', sans-serif"
              textAlign="center"
              pos="absolute"
              borderRadius="43px"
              w="60px"
              h="31px"
              fontSize="10px"
              color="white"
              bg="rgba(0, 0, 0, 0.70)"
              top="17px"
              right="11px"
              p="6px 0"
            >
              USB Pendrive
            </Tag>
          )}
        </Flex>
      </Link>
    </NextLink>
  );
};

export default Card;
