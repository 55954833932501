/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
// import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';
import config from '@config';

/* Services */

/**
 * Renders the Card Horizontal Group Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} Card Horizontal Group Card component
 */
const CardHorizontalGroup = ({ sectionContent, region, lang }) => {
  const bgColors = Styles.bgColors;
  return <>
    {sectionContent?.cards?.map((secCon, index) => {
      return (
        <Box
          background={`${config.imgPath}/d/46272/1652112424-yogacontentbg.jpg`}
          backgroundSize="cover"
          objectFit="contain"
          width={'100%'}
          m="15px 0"
          key={index}
        >
          <Box
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            mb="10px"
          >
            <Box
              w={{ md: '33.3%', sm: '100%', base: '100%' }}
              pr="15px"
              pl="15px"
            >
              <Box position="relative" maxW="330px" mx="auto">
                <Box
                  objectFit="contain"
                  transform="rotate(-2deg)"
                  // -webkit-transform: rotate(-2deg);
                  // -moz-transform: rotate(-2deg);
                  // -ms-transform: rotate(-2deg);
                  opacity="0.26"
                  position="absolute"
                  height={{ base: '101%', sm: '108%', md: '115%' }}
                  w={{ base: '90%', sm: '310px', md: '90%' }}
                  left={{ base: '7%', sm: '2%', md: '7%' }}
                  top={{ base: '-10px', sm: '-20px', md: '-14px' }}
                  background={bgColors[index % bgColors.length]}
                  outline="1px solid transparent"
                  zIndex="-1"
                // -webkit-backface-visibility: hidden;
                ></Box>
                <NextLink
                  href={refineUrlDomain(secCon.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    cursor={secCon.buttonLink ? 'cursor' : 'auto'}
                  >
                    <LazyLoadImageComponent
                      src={secCon.thumbnails[0]?.url}
                      alt={secCon.thumbnails[0]?.alt}
                      title={secCon.thumbnails[0]?.title}
                      // -webkit-box-shadow: 0 12px 15px -6px rgb(0 0 0 / 30%);
                      // -moz-box-shadow: 0 12px 15px -6px rgba(0, 0, 0, 0.3);
                      boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                      verticalAlign="middle"
                      objectFit="cover"
                      width={{ base: 'full', sm: '330px' }}
                      mb={{ base: 4, md: '0' }}
                    />
                  </Link>
                </NextLink>
              </Box>
            </Box>
            <Box pr="15px" pl="15px" width={{ md: '66.3%', sm: '100%' }}>
              <Box
                as="span"
                fontFamily="FedraSansStd-A-medium"
                fontSize={{ sm: '22px', base: '20px' }}
                color="#28231e"
                pt={'12px'}
                fontWeight="500"
              >
                {secCon.title}
              </Box>
              <Box
                fontFamily="FedraSansStd-book"
                fontSize={{ sm: '16px', base: '16px' }}
                color={'#28231e'}
                pt={'12px'}
              >
                {StructuredTextContent(secCon.description)}
              </Box>
              <Box display="flex" justifyContent="space-between">
                {secCon.buttonLink && (
                  <NextLink
                    href={refineUrlDomain(secCon.buttonLink, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link
                      bgColor="transparent"
                      color="#cf4520"
                      mr={{ base: '0', md: '20px' }}
                      padding="5px 30px"
                      border="1px solid #cf4520"
                      borderRadius="5px"
                      display="inline-block"
                      mb="20px"
                      mt={['12px', '12px', '12px', '24px']}
                      fontFamily="FedraSansStd-medium"
                      fontSize="13px"
                      _hover={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _focus={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _active={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                    >
                      {secCon.buttonText}
                    </Link>
                  </NextLink>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    })}
  </>;
};
export default CardHorizontalGroup;
