/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the ContentArea component.
 *
 * @param {string} mainContent - Content to be shown.
 * @param {string} tAlign - Alignment of text
 * @param {string} font - Size of Font
 * @param {string} pb - Padding to bottom
 * @param {string} px - Padding from left and right
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @param {object} rest - Other styling
 * @returns {ReactElement} ContentArea component
 */
const ContentArea = ({
  sectionContent,
  mainContent,
  tAlign,
  font,
  pb,
  mx,
  px,
  region,
  className,
  fontStyle,
  lang,
  position,
  ...rest
}) => {
  const contentBody = render(mainContent) || '';
  // consoleLog('contentBody', contentBody);
  if (tAlign && tAlign === 'center-align') {
    tAlign = 'center';
  }

  return (
    <Box
      w={'100%'}
      maxW="100%"
      textAlign={'left'}
      display={{ base: '', lg: 'grid' }}
      placeItems="left"
      px={px || 15}
      pb={pb || '30px'}
      fontStyle={fontStyle ? fontStyle : ''}
    >
      <Box
        width={'100%'}
        mx={mx || 'auto'}
        lineHeight="1.61"
        fontSize={{ sm: font ? font : '16px', lg: font ? font : '18px' }}
        // pb="30px"
        color="#28231e"
        textAlign={tAlign ? tAlign : 'inherit'}
      >
        <StructuredTextParser
          sectionContent={sectionContent}
          str={contentBody}
          mt={rest.mt}
          className='content-area'
          textAlign={tAlign}
          region={region}
          lang={lang}
          fontSize={font}
          lineHeight={{ base: '27px', md: '29px' }}
        />
      </Box>
    </Box>
  );
};

export default ContentArea;
