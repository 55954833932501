/* Built In Imports */
/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the VolunteeringHowToApplyStep Card componesnt
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} VolunteeringHowToApplyStep component
 */
const VolunteeringHowToApplyStep = ({ sectionContent, region, lang }) => {
  return (
    <Box
      mx="auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '1050px',
      }}
      id="volCard"
    >
      <SimpleGrid
        gridRowGap={{ base: '15px', lg: '60px' }}
        columns={{
          base: 1,
          md: 3,
        }}
        spacing="30px"
        placeItems="center"
        mx="auto"
      >
        {sectionContent?.step?.map((step, index) => {
          return (
            <Box
              key={nanoid()}
              display="flex"
              flexDirection="column"
              mx="auto"
              w={{
                base: '219px',
                md: '245px'
              }}
              h={{ base: '205px', md: 'auto' }}
              position="relative"
              mt={'25px'}
              mb={'45px'}
              alignItems="center"
            >
              <LazyLoadImageComponent
                src={step.image?.url}
                alt={step.image?.alt}
                title={step.image?.title}
                objectFit="cover"
                w={{ base: '100px', md: '151px' }}
                h={{ base: '100px', md: '151px' }}
              />
              {index === 1 || index === 0 ? <ArrowApplyStep /> : null}

              <Box m={{ base: '0 29px' }}>
                <Box fontSize="16px" color="#28231E" pt={15} w="200px">
                  <StructuredTextParser
                    str={render(step.description)}
                    region={region}
                    lang={lang}
                    mt="0"
                    fontFamily="FedraSansStd-book"
                    textAlign="center"
                    fontWeight="500"
                    fontSize={{ base: '16px', md: '20px' }}
                    lineHeight={{ base: '26px', md: '29px' }}
                  />
                </Box>
              </Box>
              <style global jsx>
                {`
                  #volCard a {
                    color: #cf4824;
                  }
                  #volCard a:hover {
                    color: #ffffff !important;
                    background: #cf4824 !important;
                  }
                  
                  }
                `}
              </style>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default VolunteeringHowToApplyStep;

const ArrowApplyStep = () => {
  return (
    <Box
      position={'absolute'}
      top={{ base: 0, md: '30%' }}
      right={{ base: '', sm: '', md: '4px', lg: '', xl: '' }}
      left={{
        base: '-57%',
        sm: '-131px',
        md: '230px',
        lg: '255px',
        xl: '250px',
      }}
      // display={{ lg: 'block', md: 'none', base: 'none' }}
      className="arrowApplyStep"
      bottom={{ base: '-265px', md: '' }}
      transform={{ base: 'rotate(90deg)', md: 'none' }}
    >
      <svg
        width="28"
        height="18"
        viewBox="0 0 28 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 16L26.5 9M26.5 9L19.5 2M26.5 9H2"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
