/* Built In Imports */
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, GridItem, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import FilterAside from '@components/UI/ProgramFinder/FilterAside';
import FilterMain from '@components/UI/ProgramFinder/FilterMain';
import ListPrograms from '@components/UI/ProgramFinder/ListPrograms';
import Loader from '@components/UI/ProgramFinder/Loader';
import { useProgramContext } from '@components/UI/ProgramFinder/ProgramFinderStore';

/* Services */
import { getLatLong } from '@Services/othersService';

/**
 * Returns the ProgramFinder component.
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of the page
 * @param {string} region - Region of the page
 * @returns {ReactElement} ProgramFinder component
 */
const ProgramFinderComp = ({ sectionContent, region, lang }) => {
  const [showMobFilter, setShowMobFilter] = useState(false);
  const [mobFilterActive, setMobFilterActive] = useState(null);

  const {
    suggestionValues,
    filteredPrograms,
    finalCards,
    isLoading,
    error,
    userLocation,
    setUserLocation,
    filterObjCopy,
    filtersCount,
    filters,
    setFilters,
  } = useProgramContext();

  const isMobile = useMediaQuery({ maxWidth: '767px' });

  // right side TextBox
  const [search, setSearch] = useState('');
  const router = useRouter();

  // tags
  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (filteredPrograms?.length == 0 && finalCards && window.scrollY >= 400) {
      window.scrollTo(0, 300);
    }
  }, [filteredPrograms]);

  useEffect(() => {
    if (router.isReady && router.query.search) {
      suggestionValues.forEach(item => {
        if (item?.name?.toLowerCase() == router.query.search?.toLowerCase()) {
          setTag(item);
          return;
        }
      });
    }

    if (router.isReady && router.query?.popup && isMobile) {
      if (router.query?.popup === '0') {
        setMobFilterActive(null);
      } else if (router.query.popup === '1') {
        let no = router.query?.activeFilter;
        if (isNaN(no)) {
          setMobFilterActive(0);
        }
        if (!isNaN(no)) {
          if (no > 99 && no < 901) {
            setMobFilterActive(Math.floor(no / 100));
          }
        }
      }
    }

    if (
      router.isReady &&
      router.query?.freeTextSearch &&
      !router.query.search
    ) {
      setSearch(router.query.freeTextSearch);
    }
  }, [router.isReady]);

  const uncheckAllChildren = (pKey, cKey) => {
    filterObjCopy[pKey][cKey]?.forEach(item => {
      item.isChecked = false;
    });
  };
  // removing the location filters
  const uncheckLoc = () => {
    const noLocation = [];
    if (filters?.length > 0) {
      [...filters].slice().map(filter => {
        if (filter.city || filter.state || filter.country || filter.centerName) {
          filter.isChecked = false;
        }
        noLocation.push(filter);
      });
    }
    setFilters(noLocation);
    if (filters) {
      setFilters([...filters]?.slice()?.filter(el => el?.isChecked));
    }
    // console.log(noLocation, 'no location');
  };
  //show user location
  const getUserLocation = () => {
    uncheckAllChildren('location', 'country');
    uncheckAllChildren('location', 'center');
    uncheckAllChildren('location', 'city');
    uncheckAllChildren('location', 'state');
    if (!userLocation?.browser && navigator?.geolocation) {
      // setting browser with api data
      (async () => {
        let pos = await getLatLong();
        const [latitude, longitude] = pos.latlong.split(',');
        setUserLocation({
          ...userLocation,
          browser: { latitude, longitude },
        });
      })();
      // setting location based on browser
      navigator?.geolocation?.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setUserLocation({
            ...userLocation,
            browser: { latitude, longitude },
          });
          // console.log(userLocation?.browser, 'browser location');
          const date = new Date();
          localStorage.setItem(
            'coords',
            JSON.stringify({
              latitude: latitude || '',
              longitude: longitude || '',
              date: date.toString,
            })
          );
        }
        // error => {
        //   if (!filtersCount?.location) {
        //     const location = useLocation();
        //     const { browser } = location;
        //     setUserLocation({
        //       ...userLocation,
        //       browser,
        //     });
        //   }
        //   console.log(error);
        // }
      );

      uncheckLoc();
    } else if (userLocation?.browser?.latitude) {
      setUserLocation(null);
    } else {
      console.log('GeoLocation is not supported in this browser');
    }
  };
  // This sets vpn location when component mounts
  useEffect(() => {
    if (!userLocation) {
      (async () => {
        let pos = await getLatLong();
        if (pos) {
          const [latitude, longitude] = pos.latlong.split(',');
          setUserLocation({ ...userLocation, vpn: { latitude, longitude } });
        } else {
          if (typeof window !== undefined) {
            const { latitude, longitude } = JSON.parse(
              localStorage.getItem('coords')
            );
            setUserLocation({ ...userLocation, vpn: { latitude, longitude } });
          }
        }
      })();
    }
  }, [userLocation, filterObjCopy]);
  console.log(userLocation?.browser, 'browser location');

  // deselects the program near me, location filters selected
  useEffect(() => {
    if (filtersCount?.location) {
      const location = userLocation;
      delete location?.browser;
      setUserLocation(location);
    }
  }, [filtersCount?.location]);

  console.log(userLocation, 'location');
  return (
    <Box minH="100vh">
      <NextLink href="/in/en/program-finder" passHref legacyBehavior>
        <Link> </Link>
      </NextLink>

      {isLoading && !error ? (
        <Box>
          <Loader left="46%" mt="0" top="20%" />
        </Box>
      ) : error ? (
        <Flex justify="center" px="1rem">
          {error?.message}
        </Flex>
      ) : (
        <>
          {(router.query?.location ||
            router.query?.programType ||
            router.query?.online ||
            router.query?.date ||
            router.query?.free ||
            router.query?.lang) && (
            <Head>
              <meta name="robots" content="noindex" />
            </Head>
          )}
          <Box
            // style={{ scrollBehavior: 'smooth' }}
            pos={{ base: 'relative', md: 'initial' }}
            mt={{ base: '0', md: '40px' }}
            top={{ base: '-15px', md: 'initial' }}
            p={{ base: '0', md: '0 30px 0 15px', lg: '0 62px 0 65px' }}
          >
            <Box display={{ base: 'block', md: 'flex' }}>
              <GridItem
                colSpan={{ base: '6', md: '2' }}
                h={{ base: 'auto', md: '100vh' }}
                maxW={{ md: '330px', lg: '459px' }}
                w="100%"
                pos="relative"
                flexBasis={{ md: '330px', lg: '459px' }}
                flexGrow="0"
                flexShrink="0"
                position={{ base: 'relative', md: 'sticky' }}
                top="0"
                zIndex="10"
              >
                <FilterAside
                  data={sectionContent.placeholderText}
                  getMobileFilter={setShowMobFilter}
                  mobFilterActive={mobFilterActive}
                  setMobFilterActive={setMobFilterActive}
                  search={search}
                  setSearch={setSearch}
                  onLocation={getUserLocation}
                  userLocation={userLocation}
                  tag={tag}
                  setTag={setTag}
                />
              </GridItem>

              <GridItem
                display={showMobFilter ? 'none' : 'initial'}
                colSpan={{ base: '6', md: '4' }}
                mt={{ base: '0', md: '0' }}
                ml={{ base: '1rem', md: '2rem' }}
                width="100%"
              >
                <Box pos="relative" mb="1.5rem" width="100%">
                  <FilterMain
                    data={sectionContent.placeholderText}
                    setMobFilterActive={setMobFilterActive}
                    mobFilterActive={mobFilterActive}
                    search={search}
                    setSearch={setSearch}
                    tag={tag}
                    setTag={setTag}
                    userLocation={userLocation}
                    onLocation={getUserLocation}
                  />
                  <ListPrograms
                    data={sectionContent.placeholderText}
                    // id="listprograms"
                    region={region}
                    lang={lang}
                    userLocation={userLocation}
                    setSearch={setSearch}
                  />
                </Box>
              </GridItem>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProgramFinderComp;
