/* Built In Imports */
import Link from 'next/link';
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Flex, Image, Tag, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import ShareIcon from '@components/Icon/ShareIcon';

/**
 * 
 * @param {object} content 
 * @returns 
 */

const Card = ({ content, region, lang }) => {
    const router = useRouter();

    return (

        <Flex bg='#F1F1F1' pos='relative' justify='space-between' flexDir='column' boxShadow=' 0px 0.3854708671569824px 1.3993395566940308px 0px rgba(0, 0, 0, 0.02), 0px 1.6960718631744385px 2.897455930709839px 0px rgba(0, 0, 0, 0.04), 0px 4.163085460662842px 5.778449058532715px 0px rgba(0, 0, 0, 0.05), 0px 8.017794609069824px 11.32641887664795px 0px rgba(0, 0, 0, 0.05), 0px 13.491479873657227px 20.825464248657227px 0px rgba(0, 0, 0, 0.07), 0px 20.815425872802734px 35.559688568115234px 0px rgba(0, 0, 0, 0.09)' width={{ base: '288px', md: '362px' }} h={{ base: '463px', md: '526px' }} zIndex={0}>
            <Link href={content.url}>
                <Flex justify='center' mr='1.5rem' mt={{ base: '10px', md: '1rem' }} minH="220px">
                    <Image w='200px' src={content?.cardImage?.url || `https://static.sadhguru.org/d/46272/1690191476-default-card-image.png`}></Image>
                </Flex>

                <Box
                    pos='relative'
                    fontSize={{ base: '18px', md: '20px' }}
                    clipPath={{ base: 'polygon(0 13%, 100% 0, 100% 100%, 0% 100%)', md: 'polygon(0 16%, 100% 0, 100% 100%, 0% 100%)' }}
                    p={{ base: '34px 15px 15px 20px', md: '55px 20px 20px 24px' }}
                    h={{ base: '259px', md: '293px' }}
                    w='100%'
                    bg='white'
                    fontFamily="FedraSansStd-A-medium"
                    lineHeight={{ base: '24px', md: "26px" }}
                    fontWeight={lang !== 'en' ? 600 : 400}
                >
                    {content?.title}

                    {content.summary && (
                        <Box
                            lineHeight={{ base: '19px', md: '24px' }}
                            mt={{ base: '10px', md: '1rem' }}
                            color='#676055'
                            fontSize='16px'
                            fontFamily="FedraSansStd-book"
                            fontWeight="400"
                        >
                            <Text noOfLines='3'>
                                {content.summary}
                            </Text>

                        </Box>
                    )}

                    <Box
                        pos='absolute'
                        bottom={{ base: '26px', md: '30px' }}
                        textDecor='underline'
                        color='#CF4520'
                        fontSize='14px'
                        fontFamily="FedraSansStd-book"
                        _hover={{
                            textDecoration: 'none',
                            backgroundColor: '#cf4824',
                            color: '#fff',
                            backgroundImage: 'linear-gradient(120deg,#cf4824,#cf4824)',
                            backgroundSize: '100% 100%',
                            // transition: 'all .3s ease-in-out'
                        }}
                    >
                        Read More...
                    </Box>



                </Box>

                {!content.isThisABook && (
                    <Tag
                        textAlign='center'
                        fontFamily="'FedraSansStd-book', sans-serif"
                        pos='absolute'
                        borderRadius='43px'
                        w='60px'
                        h='31px'
                        fontSize='10px'
                        color='white'
                        bg=' rgba(0, 0, 0, 0.70)'
                        top='23px'
                        right='20px'
                        p="6px 0"
                        justifyContent="center"
                    >
                        {content.program_card_tag || 'USB Pendrive'}
                    </Tag>
                )}
            </Link>


            <Box pos='absolute' right='15px' top={{ base: '42%', md: '37%' }} p="5px">
                <ShareIcon color='#a3a2a2' url={content?.url} zIndex="2" />
            </Box>
        </Flex>
    );
};

export default Card