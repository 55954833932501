/* Built In Imports */
/* External Imports */
import { Box, Flex, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';

/**
 * Renders SamskritiBenefit Component
 * @param {Object} - data for the section
 * @param {string} region - region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} SamskritiBenefit Component
 */
const SamskritiBenefit = ({ sectionContent, region, lang }) => {
  return (
    <Box
      // pt="40px"
      pb={{ base: '46px', md: '0' }}
      paddingRight={{ base: '22px', lg: '305px' }}
      paddingLeft={{ base: '23px', lg: '343px' }}
      maxH={{ base: 'auto', md: '428.87px' }}
    >
      {/* without global before was not appearing so I had to write global  */}
      <style jsx global>
        {`
          .ProjectList {
            list-style: none !important;
          }
          .ProjectList li {
            position: relative;
            font-family: 'FedraSansStd-book', sans-serif;
            font-size: 16px;
            color: #28231e;
            padding-left: 27px;
            margin-bottom: 22px;
            list-style: none !important;
          }
          .ProjectList li:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 20px;
            height: 20px;
            background-image: url('${config.imgPath}/d/46272/1663651220-flower.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
        `}
      </style>
      <Box>
        <Flex
          flexDir={{ md: 'row', sm: 'column', base: 'column' }}
          alignItems="center"
        >
          <Box minW="243px" maxW={'243px'} h="243" margin="0 auto" flex={'1'}>
            <Image
              verticalAlign={'middle'}
              width={'100%'}
              height="100%"
              src={sectionContent.image.url}
              alt={sectionContent.image.alt}
              title={sectionContent.image.title}
              objectFit="cover"
            />
          </Box>
          <Box flex={'1'}>
            <Box
              as="ul"
              className="ProjectList"
              pr={{ base: '16px', md: '20px' }}
              marginTop="30px"
              paddingLeft={{ md: '60px', sm: '22.13px', base: '22.13px' }}
            >
              <StructuredTextParser
                str={render(sectionContent.description)}
                region={region}
                lang={lang}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default SamskritiBenefit;
