/* Built In Imports */
import NextLink from 'next/link';
import { useCallback, useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import filter from 'lodash/filter';
import Select from 'react-select';

/* Internal Imports */
import { refineUrlDomain } from '@components/Utility/utils';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders ResearchProject3ColumnCardsFilterLinked card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - card to render
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} ResearchProject3ColumnCardsFilterLinked Card component
 */
const ResearchProject3ColumnCardsFilterLinked = ({
  sectionContent,
  region,
  lang,
}) => {
  const bgColors = ['#663E01', '#8C5706', '#81510A', '#724502'];
  const [filterName, setFilter] = useState('');
  const [projects, setProjects] = useState(sectionContent?.projects);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#ffffff' : '#cf4520',
      '&:hover': {
        color: '#fff',
        cursor: 'pointer',
      },
      fontFamily: 'FedraSansStd-book',
    }),
    control: (provided, state) => ({
      ...provided,
      outline: 0,
      border: 0,
      borderBottom: '1px solid #aea493',
      backgroundColor: 'transparent',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  };

  const handleFilter = e => {
    // e.preventDefault();
    // consoleLog('e', e, e.target);
    setFilter(e.value);
  };

  const getProjects = useCallback(async () => {
    let filteredProj = filter(sectionContent?.projects, function (project) {
      return project?.programCardTag.indexOf(filterName) > -1;
    });
    setProjects(filteredProj);
  }, [
    filterName,
    projects
  ]);

  useEffect(() => {
    getProjects();
  }, [filterName]);

  return <>
    {sectionContent.filterName && (
      <MidContainer>
        <Box
          display="flex"
          w="full"
          flexWrap="wrap"
          gridRowGap="35px"
          gridColumnGap="0"
          justifyContent="left"
          mx="auto"
          mb="20px"
        >
          <Box
            as="span"
            fontFamily="FedraSansStd-book"
            p={{ base: "10px 10px 0 20px", sm: "10px 10px 0 45px" }}
          >
            {sectionContent.filterName}
          </Box>
          <Box width="130px">
            <Select
              name={filterName}
              minW="121px"
              outline="0"
              _focus={{
                outline: '0',
              }}
              maxWidth="170px"
              lineHeight="30px"
              border="0"
              onChange={handleFilter}
              value={sectionContent.filterProgramDropdown?.json.find(obj => obj.value == filterName)}
              styles={customStyles}
              defaultValue={filterName}
              options={sectionContent.filterProgramDropdown?.json?.map((el, i) => {
                return {
                  value: el?.value,
                  label: el?.label,
                };
              })}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#cf4520',
                  primary: '#cf4520',
                },
              })}
            >
              <style global jsx>
                {`
          input {
            border: '0px solid !important';
            border: '1px solid #CF4520';
          }
          .chakra-select__wrapper {
            border: 'none !important';
          }
        `}
              </style>
            </Select>
          </Box>
        </Box>
      </MidContainer>
    )}
    <Box
      display="flex"
      w="full"
      flexWrap="wrap"
      gridRowGap="35px"
      gridColumnGap="30px"
      justifyContent="center"
      mx="auto"
      mb="50px"
    >
      {projects?.map((project, index) => {
        return (
          <NextLink
            passHref
            href={refineUrlDomain(project?.urlAlias, region, lang)}
            key={index}
            legacyBehavior>
            <Link
              maxW={{ base: '90%', md: '266px' }}
              bg="#fff"
              boxShadow="0px 5.54412px 22.1765px rgba(0, 0, 0, 0.1)"
              _hover={{
                boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2);',
              }}
            >
              <Box
                background={bgColors[index % bgColors.length]}
                px={4}
                py={5}
                fontFamily="FedraSansStd-A-medium"
                clipPath="polygon(0 0, 100% 0, 100% 100%, 0 90%)"
              >
                <Box
                  as="h4"
                  color="#fff"
                  fontSize="18px"
                  className="truncate-text"
                >
                  {project?.title}
                </Box>
              </Box>
              <Box p={4}>
                <Box
                  as="p"
                  className="truncate-text"
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color="#676055"
                >
                  {project.summary}
                </Box>
              </Box>
            </Link>
          </NextLink>
        );
      })}
    </Box>

  </>;
};

export default ResearchProject3ColumnCardsFilterLinked;
