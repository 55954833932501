/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Image, Link } from '@chakra-ui/react';
import { format } from 'date-fns';
import { render } from 'datocms-structured-text-to-html-string';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextContent from '@components/UI/StructuredTextContent';

/**
 * Returns the Top Banner Event .
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Content from API
 * @param {string} region - Region of page
 * @param {string} language - Lang of page
 * @returns {ReactElement} TopBannerEvent component
 */

const IdyTopBannerEvent = ({
  sectionContent,
  region,
  language,
  pageName,
  FYDdate,
  pageConfig,
  csrData,
  secImage

}) => {
  const pName = pageConfig?.pageName;
  const [disableButton, setDisableButton] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const styles = {
    default: 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
    'without-bottom-angle': '',
    '': 'polygon(0 0, 100% 0, 100% 100%, 0 85%)',
  };
  const router = useRouter();

  useEffect(() => {
    // Check if programData is defined and has the active 'eventPgmId' property
    if (csrData && csrData[0]?.eventPgmId != null) {
      const date = new Date(csrData[0]?.date + 'T10:16:29.060Z');
      const newFormattedDate = format(date, 'MMM do yyyy');
      setFormattedDate(newFormattedDate);
      const disableButton = csrData[0]?.eventPgmId === undefined;
      setDisableButton(disableButton);
    }

    if (pName === 'freeyogaday' && !csrData?.length > 0) {
      setDisableButton(true);
    }
  }, [csrData]);

  return router?.asPath ? (
    <Box
      display={'grid'}
      // mb={'70px'}
      pos={'relative'}
      h={{ base: '100%', md: 'auto' }}
      // maxH={620}
      // minH={{ base: 550 }}
      bgColor={{
        base: pageName === 'IDYPage' ? '#fffcf5' : '#f8f5f0',
        lg: 'transparent',
      }}
    >
      <Box height={'auto'} w={'100%'}>
        <Box
          alignItems={'center'}
          display={'flex'}
          h={{
            base: '105.9375vw',
            sm: pName === "hytt" ? "760px" : "500px"
          }}
          minH={{
            base: '105.9375vw',
            sm: '500px',
          }}
          backgroundRepeat="no-repeat"
          bgColor={{
            base: pageName === 'IDYPage' ? '#fffcf5' : '#f8f5f0',
            sm: 'transparent',
          }}
          backgroundSize="cover"
          backgroundPosition={{
            base: '45%',
            sm: '45%',
            md: pageName === 'IDYPage' ? '100%' : '',
            lg: 'top center',
            xl: '0',
          }}
          backgroundImage={{
            md: sectionContent?.desktopImage?.url,
            base:
              sectionContent?.mobileImage?.url ||
              sectionContent?.desktopImage?.url,
            // ? show right of image in desktopImage when there is no mobileImage
          }}
          mb={{ base: '0', xl: '0' }}
          clipPath={styles[sectionContent.style]}
        >
          {!isMobile && (
            <Flex
              flexDirection="column"
              verticalAlign={'center'}
              p={{ base: '7px', md: pName === 'hytt' ? "0 0 0 103px" : '0 0 0 5%' }}
              w={{ base: '100%', sm: '100%', md: '50%' }}
              maxW={{
                base: 'auto',
                md: pageName === 'ishanga7' ? '430px' : pName === 'hytt' ? "450px" : '40%',
              }}
              fontSize={{
                sm: '44px',
                base: '30px',
              }}
              color={'#28231e'}
              fontFamily="FedraSansStd-A-medium"
              display={{
                base: 'none',
                sm: 'none',
                lg: 'flex',
              }}
            >
              {/* {pageName === 'IDYPage' && (
                <Box fontSize={{ md: '18px' }} fontWeight="400">
                  UNESCO PRESENTS
                </Box>
              )} */}
              {
                secImage?._modelApiKey === "single_image" && (
                  <Image src={secImage?.image?.url} w={"260px"} h={"50px"} marginBottom={"37px"} />
                )

              }
              {sectionContent?.title === 'MahaShivRatri' ? (
                <Box as="h1" lineHeight="45px" color="#c7bfad">
                  {sectionContent?.title}
                </Box>
              ) : pName == "hytt" ? <Box
                as="h1"
                color={'white'}
                // fontFamily={"Fedra Serif A Std"}
                fontWeight={'500'}
                fontSize={{ base: '32px', md: '42px' }}
                w={"382px"}
              >
                {sectionContent?.title}
              </Box> : (
                <Box
                  as="h1"
                  lineHeight={pageName === 'ishanga7' ? '116%' : '1.25em'}
                  fontWeight={'350'}
                  fontSize={{ base: '32px', md: '44px' }}
                >
                  {sectionContent?.title}
                </Box>
              )}
              {csrData && (
                <Box
                  fontSize={{
                    base: '16px',
                    md: language === 'en' ? '30px' : '20px',
                  }}
                  fontFamily="FedraSansStd-medium"
                  color="#B95C23"
                  mb={{ base: '8px', md: '15px' }}
                  letterSpacing="1px"
                >
                  {formattedDate}
                </Box>
              )}
              {render(sectionContent.titleSubtext) &&
                render(sectionContent?.titleSubtext) !== '<p></p>' && (
                  <Box
                    fontSize={{
                      base: '16px',
                      md: language === 'en' ? '30px' : '20px',
                    }}
                    fontFamily="FedraSansStd-medium"
                    color="#B95C23"
                    mb={{ base: '8px', md: '15px' }}
                    letterSpacing="1px"
                  >
                    {StructuredTextContent(sectionContent.titleSubtext)}
                  </Box>
                )}
              {render(sectionContent?.description) &&
                render(sectionContent?.description) !== '<p></p>' && (
                  <Box
                    id="event-description"
                    fontSize={{
                      base: '16px',
                      md: language === 'en' ? '18px' : pName === "hytt" ? "18px" : '12px',
                    }}
                    lineHeight={{ base: '24px', md: '28px' }}
                    fontFamily="FedraSansStd-book"
                    color={pName == "hytt" ? "#E9D5BD" : "#333333"}
                    w={"382px"}
                  >
                    {StructuredTextContent(sectionContent.description)}
                  </Box>
                )}
              {render(sectionContent?.date) &&
                render(sectionContent?.date) !== '<p></p>' && (
                  <Box
                    fontSize={{
                      base: '16px',
                      md: language === 'en' ? '22px' : pName == 'hytt' ? "24px" : '18px',
                    }}
                    fontFamily="FedraSansStd-book"
                    fontWeight="500"
                    mt={2}
                    color={pName == "hytt" ? "#FFAE27" : "#B95C23"}
                  >
                    {StructuredTextContent(sectionContent.date)}
                  </Box>
                )}
              {render(sectionContent?.closedMessage) &&
                render(sectionContent?.closedMessage) !== '<p></p>' && (
                  <Box
                    fontSize={{ base: '16px', md: '20px' }}
                    fontFamily="FedraSansStd-book"
                    color={pName == "hytt" && "#90816E"}
                  >
                    {StructuredTextContent(sectionContent.closedMessage)}
                  </Box>
                )}
              {sectionContent?.button?.length > 0 && (
                <Box marginTop="10px">

                  <NextLink
                    href={(region !== 'in' && router.query.referrer_id) ? '#challenge-form' : sectionContent?.button[0]?.linkUrl}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      className="orange-button"
                      display={'block'}
                      justifyContent="center"
                      alignItems="center"
                      fontWeight={'500'}
                      fontSize={{ base: '14px', sm: '14px', xl: '18px' }}
                      bgColor={'#cf4520'}
                      h={'auto'}
                      minH="44px"
                      cursor={'pointer'}
                      textAlign={'center'}
                      padding={'14px 29px'}
                      color="#faf7f0"
                      textDecoration="none"
                      width={'auto'}
                      background="#CA4E2A"
                      borderRadius={'5px'}
                      fontFamily="FedraSansStd-medium"
                      textDecor="none"
                      mx={{ base: '0', md: '0' }}
                      _hover={{
                        bg: '#983C21',
                      }}
                      maxW="280px"
                      mb="5px"
                      target={(region !== 'in' && router.query.referrer_id) ? '_self' : '_blank'}
                    >
                      {sectionContent?.button[0]?.buttonText}
                    </Link>
                  </NextLink>
                </Box>
              )}

              {render(sectionContent?.bottomText) &&
                render(sectionContent?.bottomText) !== '<p></p>' && (
                  <Box
                    id="donate-button"
                    fontSize={{
                      base: '16px',
                      md: language === 'en' ? '16px' : '12px',
                    }}
                    fontFamily="FedraSansStd-book"
                    mb="10px"
                    mt="10px"
                    lineHeight="160%"
                  >
                    {StructuredTextContent(sectionContent.bottomText)}
                  </Box>
                )}

              {sectionContent?.secondaryCta?.length > 0 && (
                <Box marginTop="5px">
                  <SimpleButton
                    style={sectionContent.secondaryCta[0]}
                    region={region}
                    lang={language}
                    mb="5px"
                    minW={pageName === 'ishanga7' ? '256px' : '200px'}
                    textAlign="center"
                    mt="0"
                    pageName={pageName}
                    pageConfig={pageConfig}
                    disableButton={disableButton}
                  />
                </Box>
              )}
            </Flex>
          )}
        </Box>

        {isMobile && (
          <Flex
            display={{
              base: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'none',
              xl: 'none',
            }}
            flexDirection="column"
            verticalAlign={'center'}
            w={pName === "hytt" ? { base: '100%', sm: '100%', md: '100%', lg: '50%' } : { base: '100%', sm: '80%', md: '96%', lg: '50%' }}
            p={{ base: pName == "hytt" ? "30px 40px" : '20px 15px', md: '30px' }}
            fontSize={{
              sm: '44px',
              base: '30px',
            }}
            color={'#28231e'}
            fontFamily="FedraSansStd-A-medium"
            // bgColor={{
            //   base: pageName === 'IDYPage' ? '#fffcf5' : '',
            //   lg: 'transparent',
            // }}
            bgColor={{
              base: pageName === 'IDYPage' ? '#fffcf5' : pName === 'hytt' ? "black" : '',
              lg: 'transparent',
            }}
            height={pName === "hytt" && "550px"}
          >
            {
              secImage?._modelApiKey === "single_image" && (
                <Image src={secImage?.image?.url} w={"260px"} h={"50px"} marginBottom={"37px"} />
              )

            }
            {/* {pageName === 'IDYPage' && (
              <Box fontSize={{ base: '16px', sm: '16px' }} fontWeight="400">
                UNESCO PRESENTS
              </Box>
            )} */}
            <Box
              as="h1"
              fontSize={{
                base: pName === 'hytt' ? '28px' : '32px',
                md: pageName === 'ishanga7' ? '65.67px' : '44px',
              }}
              lineHeight={{
                base: '40px',
                md: pageName === 'ishanga7' ? '116%' : '1.25em',
              }}
              fontWeight={pName === "hytt" ? "500" : "350"}
              color={pName == "hytt" ? "white" : ""}

            >
              {sectionContent?.title}
            </Box>
            {csrData && (
              <Box
                fontSize={{
                  base: '16px',
                  md: language === 'en' ? '30px' : '20px',
                }}
                fontFamily="FedraSansStd-medium"
                color="#B95C23"
                mb={{ base: '8px', md: '15px' }}
                letterSpacing="1px"
              >
                {formattedDate}
              </Box>
            )}
            {sectionContent.titleSubtext && (
              <Box
                fontSize={{ base: '20px', md: '28px', lg: '30px' }}
                fontFamily="FedraSansStd-medium"
                color="#B95C23"
              >
                {StructuredTextContent(sectionContent.titleSubtext)}
              </Box>
            )}
            {render(sectionContent?.description) &&
              render(sectionContent?.description) !== '<p></p>' && (
                <Box
                  id="event-description"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontFamily="FedraSansStd-book"
                  color={pName == "hytt" ? "#E9D5BD" : "#333333"}
                >
                  {StructuredTextContent(sectionContent.description)}
                </Box>
              )}
            {render(sectionContent?.date) &&
              render(sectionContent?.date) !== '<p></p>' && (
                <Box
                  fontSize={{ base: pName === "hytt" ? "20px" : "18px", md: '20px' }}
                  fontFamily="FedraSansStd-book"
                  fontWeight="500"
                  my={2}
                  color={pName == "hytt" ? "#FFAE27" : "#B95C23"}
                >
                  {StructuredTextContent(sectionContent.date)}
                </Box>
              )}
            {render(sectionContent?.closedMessage) &&
              render(sectionContent?.closedMessage) !== '<p></p>' && (
                <Box
                  fontSize={{ base: '16px', md: '20px' }}
                  fontFamily="FedraSansStd-book"
                  className={pName === "hytt" ? "hytt-closemessage" : "closeMessage"}
                >
                  {StructuredTextContent(sectionContent.closedMessage)}
                </Box>
              )}
            {sectionContent?.button?.length > 0 && (
              <Box marginTop={pName === "hytt" ? "8px" : "18px"} h="auto">
                <NextLink
                  href={(region !== 'in' && router.query.referrer_id) ? '#challenge-form' : sectionContent?.button[0]?.linkUrl}
                  passHref
                  legacyBehavior
                >
                  <Link
                    className="orange-button"
                    display={'block'}
                    justifyContent="center"
                    alignItems="center"
                    fontWeight={'500'}
                    fontSize={{ base: '14px', sm: '14px', xl: '18px' }}
                    bgColor={'#cf4520'}
                    h={'auto'}
                    minH="44px"
                    cursor={'pointer'}
                    textAlign={'center'}
                    padding={'14px 29px'}
                    color="#faf7f0"
                    textDecoration="none"
                    width={'auto'}
                    background="#CA4E2A"
                    borderRadius={'5px'}
                    fontFamily="FedraSansStd-medium"
                    textDecor="none"
                    mx={{ base: '0', md: '0' }}
                    _hover={{
                      bg: '#983C21',
                    }}
                    maxW="280px"
                    mb="5px"
                    target={(region !== 'in' && router.query.referrer_id) ? '_self' : '_blank'}
                  >
                    {sectionContent?.button[0]?.buttonText}
                  </Link>
                </NextLink>
                {/* <SimpleButton
                  style={sectionContent.button[0]}
                  region={region}
                  lang={language}
                  mb={pageName === 'ishanga7' ? '10px' : '0'}
                  pageName={pageName}
                  pageConfig={pageConfig}
                  disableButton={disableButton}
                  clickHandler={() => {
                    clickH();
                  }}
                /> */}
              </Box>
            )}
            {render(sectionContent?.bottomText) &&
              render(sectionContent?.bottomText) !== '<p></p>' && (
                <Box
                  id="donate-button"
                  fontSize={{ base: '16px', md: '20px' }}
                  fontFamily="FedraSansStd-book"
                  mb={sectionContent?.secondaryCta?.length ? '10px' : '0'}
                  mt={'10px'}
                >
                  {StructuredTextContent(sectionContent.bottomText)}
                </Box>
              )}
            {sectionContent?.secondaryCta?.length > 0 && (
              <Box mt="0">
                <SimpleButton
                  style={sectionContent.secondaryCta[0]}
                  region={region}
                  lang={language}
                  mb="15px"
                  minW={pageName === 'ishanga7' ? '256px' : '200px'}
                  textAlign="center"
                  pageName={pageName}
                  pageConfig={pageConfig}
                  disableButton={disableButton}
                />
              </Box>
            )}
            {/* {currentUrl && (
              <Box fontSize="16px" m="20px 0">
                <AddToCalendarButton
                  name="Guru Poornima Test Event"
                  description="Guru Poornima Test Event Description"
                  startDate="2023-05-22"
                  options={['Apple', 'Google', 'Yahoo', 'Microsoft365']}
                  hideBackground={true}
                  trigger="click"
                  hideCheckmark={true}
                ></AddToCalendarButton>
              </Box>
            )} */}
          </Flex>
        )}
      </Box>
      <style global jsx>
        {`
          #event-description h2 {
            font-size: 27px;
            color: #28231e;
            font-family: 'FedraSansStd-medium';
            padding: 15px 0 0 0;
          }
          #donate-button a {
            color:#cf4520;
            text-decoration:underline;
          }
          #donate-button a:hover {
            text-decoration:none;
          }
          .closeMessage {
            color: black;
            margin-top: 5px;
            font-size: 16px;
          }
          .closeMessage a {
            color: #cf4824;
          }
          .hytt-closemessage {
            color:#90816E;
            margin-top: 5px;
            font-size: 16px;
          }

          @media (max-width: 767px) {
            #event-description h2 {
              font-size: 20px;
          }        
        `}
      </style>
    </Box>
  ) : null;
};

export default IdyTopBannerEvent;
