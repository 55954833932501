/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the YogaCommonUI component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SingleLineText Component.
 */

const FeeDetails = ({
    sectionContent,
    fontStyle,
    lineHeight,
    fontWeight,
    color,
    ...props
}) => {
    return (
        <Box maxW="100%" my="0">
            <Box
                fontFamily="FedraSansStd-medium"
                width="100%"
                fontSize={{ base: '18px', lg: '22px' }}
                color={color ? color : '#28231E'}
                lineHeight={lineHeight || "28px"}
                fontStyle={fontStyle || "normal"}
                fontWeight={fontWeight || 500}
                textAlign='center'
            >
                sdsd{sectionContent?.text}
            </Box>
        </Box>
    );
};

export default FeeDetails;
