/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/layout';

/* Internal Imports */
/* Components */
import SliderCommon from '@components/Sliders/SliderCommon';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Gallery Component
 * @param {Object} sectionContent - Data from api
 * @returns {ReactElement} - Slider Gallery Component
 */
const SliderGallery = ({ sectionContent, isLeftMenu }) => {
  // consoleLog('isLeftMenu', isLeftMenu);

  const bgColors = {
    grey: '#e4ded4',
  };

  return (
    <Box
      bgColor={bgColors[sectionContent?.style]}
      w="100%"
      p="10px 0"
      textAlign={'center'}
      marginBottom="1px"
    >
      <Box w="100%">
        <SliderCommon sectionContent={sectionContent} isLeftMenu={isLeftMenu} />
        <style global jsx>
          {`
            #slider-common .slick-list .slick-track {
              padding-left: 0.1%;
              height: 65px;
            }
            #slider-common .slick-slide {
              min-height: 85px;
            }
            @media (max-width: 480px) {
              #slider-common .slick-list .slick-track {
                padding-left: 0;
                height: 168px;
              }
            }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default SliderGallery;
