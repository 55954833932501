/* Built In Imports */
/* External Imports */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param faqSection.faqSection
 * @param {Array} faqSection - Main FAQ data.
 * @param faqSection.region
 * @param faqSection.lang
 * @param faqSection.bgColor
 * @param faqSection.color
 * @returns {ReactElement} FaqSectionV2 component.
 */
const FaqSectionV2Volunteer = ({
  faqSection,
  region,
  lang,
  bgColor,
  color,
}) => {
  const [open, setIsOpen] = useState(false);
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="1.1rem"
      lineHeight="1.61"
      width="100%"
      m="0"
      p={{ base: '0 14px', md: '0' }}
      id={faqSection?.id}
      className="faq"
    >
      <Accordion
        // key={nanoid()}
        allowMultiple
        m={{ base: '0 auto' }}
        width={{ base: '100%' }}
      >
        <AccordionItem border="none">
          <AccordionButton
            bgColor={bgColor || 'white'}
            borderRadius="0"
            mb="2px"
            fontFamily="FedraSansStd-medium"
            fontSize="20px"
            border="none"
            padding="10px 13px 10px 0px"
            color={color || '#000000'}
            _hover={{ bgColor: 'transparent' }}
            _focus={{ shadow: 'transparent' }}
            minHeight="55px"
            lineHeight="1.5"
            onClick={() => setIsOpen(!open)}
            borderBottom={open ? 'none' : '0.5px solid #B9B8B8'}
          >
            <chakra.div
              flex="1"
              textAlign="left"
              fontWeight="500"
              fontSize="16px"
              lineHeight="20px"
            >
              {faqSection.title}
            </chakra.div>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={1} p="0">
            {faqSection?.faqs?.length
              ? faqSection.faqs.map(faq => {
                  return <FaqChildren faq={faq} region={region} lang={lang} />;
                })
              : null}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

const FaqChildren = ({ faq, region, lang }) => {
  return (
    <AccordionItem
      // key={nanoid(4)}
      borderBottomWidth="0"
      borderBottomColor="gray.400"
      borderTop="none"
      lineHeight="1.61"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            padding="10px 13px 10px 0px"
            fontFamily="FedraSansStd-medium"
            fontSize="14px"
            borderTop="none"
            _hover={{ background: 'none' }}
            _focus={{ shadow: 'transparent' }}
          >
            <chakra.div
              flex="1"
              textAlign="left"
              fontSize="14px"
              fontWeight="500"
              lineHeight="17.5px"
            >
              {faq.question}
            </chakra.div>
            {isExpanded ? (
              <Box
                width="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FaMinus
                  fontSize="14px"
                  color="#CF4520"
                  marginRight="5px"
                  // marginLeft={{ base: '6%', sm: '4.5%', md: '2.5%' }}
                />
              </Box>
            ) : (
              <Box
                width="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FaPlus
                  fontSize="14px"
                  color="#CF4520"
                  marginRight="5px"
                  // marginLeft={{ base: '6%', sm: '4.5%', md: '2.5%' }}
                />
              </Box>
            )}
          </AccordionButton>
          <AccordionPanel
            pb={2}
            fontSize="1.1rem"
            fontFamily="FedraSansStd-book"
            lineHeight="1.61"
            p="0"
          >
            <Box
              display="flex"
              justifyContent="start"
              width="90%"
              lineHeight="1.61"
              fontSize={{ lg: '14px', sm: '14px' }}
              color="#28231e"
            >
              <Box
                /*className="event-text" */ fontSize="14px"
                lineHeight="22px"
                fontWeight="400"
                mb="15px"
              >
                <StructuredTextParser
                  str={render(faq.answer)}
                  region={region}
                  lang={lang}
                  fontSize="14px"
                />
              </Box>
            </Box>
          </AccordionPanel>

          <style global jsx>
            {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }
          .faq p {
            font-size 14px;
          }
        `}
          </style>
        </>
      )}
    </AccordionItem>
  );
};

export default FaqSectionV2Volunteer;
