/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { getCardBgColors, refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Card Program 3 Column Group component.
 * @param {Object} programs - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param {string} cardType - Type of Card
 * @returns {ReactElement} CardProgram3ColumnGroup Card component.
 */
const CardProgram3ColumnGroup = ({ programs, region, lang, cardType, pageConfig }) => {
  const pageName = pageConfig?.pageName;
  let bgColors = getCardBgColors(cardType);
  const clips = Styles.clipPaths;

  return (
    <Box
      display="flex"
      mx="auto"
      maxWidth="1200px"
      justifyContent="center"
    // marginTop="42px"
    >
      {/* dynamic binding */}
      <Box
        display="flex"
        flexWrap="wrap"
        gridGap={{ base: '15px', lg: '30px' }}
        gridRowGap={{ base: '15px', lg: '60px' }}
        // justifyContent={programs?.length > 3 ? 'left' : 'center'}
        justifyContent={{ base: 'center', md: 'center', xl: 'left' }}
      >
        {programs &&
          programs.length > 0 &&
          programs.map((item, index) => {
            return (
              <Box
                textAlign="left"
                boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                _hover={{ boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)' }}
                background="#ffffff"
                maxWidth={{ base: '302px', md: '330px', lg: '370px' }}
                display="block"
                key={nanoid()}
                m={{ md: '0', sm: '0 auto', base: '0 auto' }}
              >
                {item?.cardLink && !item?.buttonText && (
                  <NextLink
                    href={refineUrlDomain(item.cardLink, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link
                      cursor={item.cardLink ? 'cursor' : 'auto'}
                      _hover={{ textDecoration: 'none' }}
                    >
                      <Box
                        background={bgColors[index % bgColors.length]}
                        position={'relative'}
                      >
                        <Box>
                          <LazyLoadImageComponent
                            src={item?.thumbnail?.url}
                            alt={item?.thumbnail?.title}
                            title={item?.thumbnail?.title}
                            clipPath={clips[index % clips.length]}
                            // clipPath="polygon(0 0, 100% 0, 100% 100%, 0% 92%)"
                            h={{ sm: '220px', xl: '225px' }}
                            w="100%"
                          />
                        </Box>
                        <Text
                          fontFamily="FedraSansStd-A-medium"
                          fontSize={{
                            base: '24px',
                            md: '17px',
                            lg: '24px',
                          }}
                          padding="18px"
                          color="#ffffff"
                          h={{ base: '20%', lg: 'auto' }}
                          minH={{ base: '20%', lg: '80px' }}
                        >
                          {item.title}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontSize={{ base: '17px', md: '13px', lg: '17px' }}
                          padding="0 18px"
                          lineHeight="1.35"
                          className="truncate-text"
                          fontFamily="FedraSansStd-book"
                          color="#28231e"
                          minH={'100px'}
                        >
                          <StructuredTextParser
                            str={render(item.description)}
                            region={region}
                            lang={lang}
                            className="truncate-text"
                          />
                        </Text>
                        {item?.secondCtaLabel && (
                          <Box>
                            <NextLink
                              href={refineUrlDomain(
                                item?.secondCtaLink,
                                region,
                                lang
                              )}
                              passHref
                              legacyBehavior>
                              <Link
                                mb="20px"
                                display="block"
                                fontWeight={500}
                                fontFamily="FedraSansStd-book"
                                fontSize={'18px'}
                                h={'auto'}
                                textAlign="center"
                                padding="10px 36px"
                                borderRadius="3px"
                                color="#cf4520"
                                textDecoration="underline"
                                _hover={{
                                  textDecoration: 'none',
                                }}
                                target={
                                  item?.secondCtaLink.indexOf('http') > -1 &&
                                    item?.secondCtaLink.indexOf(config.cdnPath) <
                                    0
                                    ? '_blank'
                                    : ''
                                }
                              >
                                {item?.secondCtaLabel}
                              </Link>
                            </NextLink>
                          </Box>
                        )}
                        {item?.buttonText && (
                          <Box>
                            <NextLink
                              href={refineUrlDomain(
                                item?.buttonLink,
                                region,
                                lang
                              )}
                              passHref
                              legacyBehavior>
                              <Link
                                width="230px"
                                display="block"
                                fontWeight={500}
                                fontFamily="FedraSansStd-book"
                                fontSize={'18px'}
                                bgColor="#cf4520"
                                h={'auto'}
                                textAlign="center"
                                padding="10px 20px"
                                borderRadius="3px"
                                color="#faf7f0"
                                textDecoration="none"
                                _hover={{
                                  bg: '#000054',
                                }}
                                margin="20px auto"
                                target={
                                  item?.buttonLink.indexOf('http') > -1 &&
                                    item?.buttonLink.indexOf(config.cdnPath) < 0
                                    ? '_blank'
                                    : ''
                                }
                              >
                                {item?.buttonText}
                              </Link>
                            </NextLink>
                          </Box>
                        )}
                      </Box>
                    </Link>
                  </NextLink>
                )}

                {item?.buttonText && (
                  <Box>
                    <Box
                      background={bgColors[index % bgColors.length]}
                      position={'relative'}
                    >
                      <Box>
                        <LazyLoadImageComponent
                          src={item?.thumbnail?.url}
                          alt={item?.thumbnail?.title}
                          title={item?.thumbnail?.title}
                          clipPath={clips[index % clips.length]}
                          // clipPath="polygon(0 0, 100% 0, 100% 100%, 0% 92%)"
                          h={{ sm: '220px', xl: '225px' }}
                          w="100%"
                        />
                      </Box>
                      <Text
                        fontFamily="FedraSansStd-A-medium"
                        fontSize={{
                          base: '24px',
                          md: '17px',
                          lg: '24px',
                        }}
                        padding="18px"
                        color="#ffffff"
                        h={{ base: '20%', lg: 'auto' }}
                        minH={{ base: '20%', lg: '80px' }}
                      >
                        {item.title}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontSize={{ base: '17px', md: '13px', lg: '17px' }}
                        padding="0 18px"
                        lineHeight="1.35"
                        className={pageName !== 'gurupurnima' ? "truncate-text" : ''}
                        fontFamily="FedraSansStd-book"
                        color="#28231e"
                        minH={'100px'}
                      >
                        <StructuredTextParser
                          str={render(item.description)}
                          region={region}
                          lang={lang}
                          className={pageName !== 'gurupurnima' ? "truncate-text" : ''}
                        />
                      </Text>
                      {item?.secondCtaLabel && (
                        <Box>
                          <NextLink
                            href={refineUrlDomain(
                              item?.secondCtaLink,
                              region,
                              lang
                            )}
                            passHref
                            legacyBehavior>
                            <Link
                              mb="20px"
                              display="block"
                              fontWeight={500}
                              fontFamily="FedraSansStd-book"
                              fontSize={'18px'}
                              h={'auto'}
                              textAlign="center"
                              padding="10px 36px"
                              borderRadius="3px"
                              color="#cf4520"
                              textDecoration="underline"
                              _hover={{
                                textDecoration: 'none',
                              }}
                              target={
                                item?.secondCtaLink.indexOf('http') > -1 &&
                                  item?.secondCtaLink.indexOf(config.cdnPath) <
                                  0
                                  ? '_blank'
                                  : ''
                              }
                            >
                              {item?.secondCtaLabel}
                            </Link>
                          </NextLink>
                        </Box>
                      )}
                      {item?.buttonText && (
                        <Box>
                          <NextLink
                            href={refineUrlDomain(
                              item?.buttonLink,
                              region,
                              lang
                            )}
                            passHref
                            legacyBehavior>
                            <Link
                              width="230px"
                              display="block"
                              fontWeight={500}
                              fontFamily="FedraSansStd-book"
                              fontSize={'18px'}
                              bgColor="#cf4520"
                              h={'auto'}
                              textAlign="center"
                              padding="10px 20px"
                              borderRadius="3px"
                              color="#faf7f0"
                              textDecoration="none"
                              _hover={{
                                bg: '#000054',
                              }}
                              margin="20px auto"
                              target={
                                item?.buttonLink.indexOf('http') > -1 &&
                                  item?.buttonLink.indexOf(config.cdnPath) < 0
                                  ? '_blank'
                                  : ''
                              }
                            >
                              {item?.buttonText}
                            </Link>
                          </NextLink>
                        </Box>
                      )}
                    </Box>
                  </Box>                  
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default CardProgram3ColumnGroup;
