/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import MediaArticleCard from '@components/Card/MediaArticleCard';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the TopicBasedWisdomContent component
 *
 * @param data.data
 * @param {object} data -  Related Stories Data
 * @param {object} titleObj - Title data
 * @param {object} lang - Current User Language
 * @param {boolean} isShareEnabled - Share Icon
 * @param data.titleObj
 * @param data.isShareEnabled
 * @param data.lang
 * @param data.isLeftMenu
 * @returns {ReactElement} TopicBasedWisdomContent component
 */
export default function TopicBasedWisdomContent({
  data,
  titleObj,
  isShareEnabled,
  lang,
  isLeftMenu,
}) {
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="40%"
        zIndex="10"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ base: '-10px' }}
        bgSize="13px"
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      />
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="40%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ base: '-10px' }}
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: isLeftMenu ? 2 : 3,
    slidesToScroll: isLeftMenu ? 2 : 3,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
          // className="bullet"
        ></Text>
      );
    },
    // dotsClass: "slick-dots meet-dots",
  };
  
  return data?.topic?.cards?.length > 0 ? (
    <Box
      as="section"
      my="15px"
      py="15px"
    >
      <Box float="left" width="100%" mt={{ base: '10px' }}>
        <Box
          float="left"
          width="100%"
          fontFamily="'FedraSansStd-A-medium', 'sans-serif'"
          fontSize={{ base: '24px', md: '30px' }}
          lineHeight="1.13"
          textAlign="center"
          color="#02026c"
        >
          {data?.title}
          {/* Related Content For {contentSection} Detail */}
        </Box>
        <Box float="left" width="250px" m="5px calc((100% - 250px) / 2) 0">
          <LazyLoadImageComponent
            alt="divider"
            title='divider'
            src={`${config.staticPath}/assets/images/divider2.svg`}
            float="left"
            width="100%"
          />
        </Box>
      </Box>

      <Box
        mt="50px"
        display="flex"
        maxWidth="1170px"
        w="full"
        flexWrap="wrap"
        justifyContent="center"
        mx="auto"
        gridGap={{ base: '15px', sm: '30px', lg: '30px' }}
      >
        <Box width="full" pt="40px">
          <Slider {...settings} m="0">
            {data?.topic?.cards?.length &&
              Array.isArray(data?.topic?.cards) &&
              data?.topic?.cards.map((post, i) => {
                return (
                  <MediaArticleCard
                    key={nanoid()}
                    {...post}
                    isShareEnabled={isShareEnabled}
                  />
                );
              })}
          </Slider>
        </Box>
      </Box>
    </Box>
  ) : null;
}
