/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import MidContainer from '@Layout/MidContainer';

/* Components */
/* Services */
/* Styles */
import classes from '@components/UI/Health/LargeBrownBullets.module.css';

/**
 * Renders the Large Brown Bullets Health component.
 * @param {Object} data - Content from api.
 * @returns {ReactElement} Large BrownBullets Health component.
 */
const LargeBrownBullets = ({ data }) => {
  return (
    <MidContainer>
      <Box className={classes.largeBrownBulletsHealth}>
        {StructuredTextContent(data)}
      </Box>
    </MidContainer>
  );
};

export default LargeBrownBullets;
